import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';

interface Props {
  imgUrl: string;
  live: boolean;
  soon?: boolean;
  tokenName: string;
  link?: string;
  tokenComment: string;
}
const GetStartedItem: React.FC<Props> = ({
  imgUrl,
  live,
  soon,
  tokenName,
  link,
  tokenComment,
}: any) => {
  return (
    <StyledContainer>
      <CoinBox>
        <img
          src={imgUrl}
          style={{ width: '28px', height: '28px' }}
          alt={imgUrl}
        />
      </CoinBox>
      <Box display={'flex'} flexDirection={'column'}>
        {live && <BadgeBox>LIVE</BadgeBox>}
        {!live && soon && <SoonBox>SOON</SoonBox>}
        {!live && !soon && <ComBox>{tokenComment}</ComBox>}
        <NameBox>
          {
            link !== undefined ?
              <a target="balank" href="https://app.saieve.io/">{tokenName}</a> :
              tokenName
          }
        </NameBox>
      </Box>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 14px;
  cursor: pointer;

  &:hover {
    transform: translateY(-10px);
  }

  transition: all 0.5s;
`;

const SoonBox = styled(Box)`
  width: 38px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #a47ee6;
  /* padding: 2px 4px;
  background: linear-gradient(225deg, #0E1DB8 1.56%, #9071EB 97.92%); */
  border-radius: 4px;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  /* color: #F9FAFB; */
`;

const BadgeBox = styled(Box)`
  margin-top: 10px;
  width: 38px;
  height: 14px;
  background: linear-gradient(225deg, #0e1db8 1.56%, #9071eb 97.92%);
  border-radius: 4px;
  padding: 2px 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #f9fafb;
`;

const CoinBox = styled(Box)`
  filter: drop-shadow(0px 15px 27px rgba(0, 0, 0, 0.15));
`;

const ComBox = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #022b69;
`;

const NameBox = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #7d91ae;
`;

export default GetStartedItem;
