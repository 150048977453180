import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";

const SecondPart: React.FC = () => {
  return (
    <StyledContainer>
      <WrapperBox>
        <Box position={"absolute"} right={"0px"} top={"0px"}>
          <svg
            width="61"
            height="34"
            viewBox="0 0 61 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="2.95162" cy="2.95162" r="2.95162" fill="#F9FAFB" />
            <circle cx="16.729" cy="2.95162" r="2.95162" fill="#F9FAFB" />
            <circle cx="30.5024" cy="2.95162" r="2.95162" fill="#F9FAFB" />
            <circle cx="44.2719" cy="2.95162" r="2.95162" fill="#F9FAFB" />
            <circle cx="58.0454" cy="2.95162" r="2.95162" fill="#F9FAFB" />
            <circle cx="2.95162" cy="16.7251" r="2.95162" fill="#F9FAFB" />
            <circle cx="16.7251" cy="16.7251" r="2.95162" fill="#F9FAFB" />
            <circle cx="30.4985" cy="16.7251" r="2.95162" fill="#F9FAFB" />
            <circle cx="44.2719" cy="16.7251" r="2.95162" fill="#F9FAFB" />
            <circle cx="58.0454" cy="16.7251" r="2.95162" fill="#F9FAFB" />
            <circle cx="2.95162" cy="30.4995" r="2.95162" fill="#F9FAFB" />
            <circle cx="16.7251" cy="30.4995" r="2.95162" fill="#F9FAFB" />
            <circle cx="30.4985" cy="30.4995" r="2.95162" fill="#F9FAFB" />
            <circle cx="44.2719" cy="30.4995" r="2.95162" fill="#F9FAFB" />
            <circle cx="58.0454" cy="30.4995" r="2.95162" fill="#F9FAFB" />
          </svg>
        </Box>
        <Box position={"absolute"} left={"0px"} bottom={"0px"}>
          <svg
            width="61"
            height="34"
            viewBox="0 0 61 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="2.95162" cy="2.95162" r="2.95162" fill="#F9FAFB" />
            <circle cx="16.729" cy="2.95162" r="2.95162" fill="#F9FAFB" />
            <circle cx="30.5024" cy="2.95162" r="2.95162" fill="#F9FAFB" />
            <circle cx="44.2719" cy="2.95162" r="2.95162" fill="#F9FAFB" />
            <circle cx="58.0454" cy="2.95162" r="2.95162" fill="#F9FAFB" />
            <circle cx="2.95162" cy="16.7251" r="2.95162" fill="#F9FAFB" />
            <circle cx="16.7251" cy="16.7251" r="2.95162" fill="#F9FAFB" />
            <circle cx="30.4985" cy="16.7251" r="2.95162" fill="#F9FAFB" />
            <circle cx="44.2719" cy="16.7251" r="2.95162" fill="#F9FAFB" />
            <circle cx="58.0454" cy="16.7251" r="2.95162" fill="#F9FAFB" />
            <circle cx="2.95162" cy="30.4995" r="2.95162" fill="#F9FAFB" />
            <circle cx="16.7251" cy="30.4995" r="2.95162" fill="#F9FAFB" />
            <circle cx="30.4985" cy="30.4995" r="2.95162" fill="#F9FAFB" />
            <circle cx="44.2719" cy="30.4995" r="2.95162" fill="#F9FAFB" />
            <circle cx="58.0454" cy="30.4995" r="2.95162" fill="#F9FAFB" />
          </svg>
        </Box>
      </WrapperBox>
    </StyledContainer>
  );
};

const WrapperBox = styled(Box)`
  position: relative;
  padding: 100px 120px;
`;

const StyledContainer = styled(Box)`
  background: linear-gradient(304.2deg, #0420b6 -1.51%, #304ce3 100%);
  background: url(.png);
  mix-blend-mode: overlay;
`;

export default SecondPart;
