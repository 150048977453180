import React from 'react';
import styled from 'styled-components';
import {Button, Container as CTR, Grid} from '@mui/material';

function Main() {
  return (
    <div
      style={{
        background: '#E5E5E5',
        backgroundImage: 'url(./images/pcltoken/eclipse.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left top',
        padding: '80px 0'
      }}>
      <CTR fixed maxWidth={'lg'}>
        <MainContainer>

          <StyledH1>Membership benefits </StyledH1>
          <Container>
            <Container>
              <P>By holding PCL Tokens, Premium users show their confidence</P>
              <P>and trust in the PCL's long-term value. </P>
            </Container>
          </Container>
          <Container>
            <StyledGrid container spacing={2}
              style={{ position: 'relative', marginTop: '80px' }}
            >
              <Premium src="./images/pcltoken/Premium.svg" />
              <Grid item md={3}>
                <Service>
                  <IMG src="./images/pcltoken/Lite.svg" />
                  <h3 style={{ color: '#0E1DB8', marginBottom: 20 }}>LITE</h3>
                  <p>Access to lite service</p>
                </Service>
              </Grid>
              <Grid item md={3}>
                <Service>
                  <IMG src="./images/pcltoken/Bronze.svg" />
                  <h3 style={{ color: '#956F13', marginBottom: 20 }}>Bronze</h3>
                  <p>Access to bronze services</p>
                </Service>
              </Grid>
              <Grid item md={3}>
                <Service>
                  <IMG src="./images/pcltoken/Silver.svg" />
                  <h3
                    style={{
                      color:
                        'linear-gradient(92.61deg, rgba(0, 11, 115, 0.1) 0%, rgba(0, 15, 146, 0.15) 100%)'
                      , marginBottom: 20
                    }}
                  >
                    Silver
                  </h3>
                  <p>Access to Silver services</p>
                </Service>
              </Grid>
              <Grid item md={3}>
                <Service>
                  <IMG src="./images/pcltoken/Gold.svg" />
                  <h3 style={{ color: '#EAC874', marginBottom: 20 }}>Gold</h3>
                  <p>Access to gold services</p>
                </Service>
              </Grid>
            </StyledGrid>
          </Container>

          <LearnMore>
            <Button
              variant="outlined"
              style={{
                padding: '10px',
                color: '#0220BF',
                fontWeight: 'bold',
                border: 'solid 2px #0420B6',
                height: '50px',
                width: 'fit-content',
              }}
            >
              <a href="http://app.saieve.io" target="_blank">
                {' '}
                Become a gold member
              </a>
            </Button>
            <a
              href="https://peculium.io/blog/peculium-tokenomics-update-new-tier-based-membership-program"
              target="_blank"
              style={{ color: '#0220BF', marginLeft: '3vw', marginTop: '12px' }}
            >
              Learn more →
            </a>
            <Fees />
          </LearnMore>
        </MainContainer>
      </CTR>
    </div>
  );
}

const Fees = () => {
  return (
    <GlassBg>
      <p style={{ fontWeight: 'bold', color: '#0220BF', margin: '4px' }}>
        Free fees
      </p>
      <p style={{ color: '#7D91AE', margin: '4px' }}>
        0% commission of management and performance fees
      </p>
      <p style={{ fontWeight: 'bold', color: '#0220BF', margin: '4px' }}>
        Rebates trading fees
      </p>
      <p style={{ color: '#7D91AE', margin: '4px' }}>
        The more PCL you own the less exchange fees you pay
      </p>
    </GlassBg>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 1215px) {
    padding: 0px;
    align-items: center;
  }
`;

const MainContainer = styled(Container)``;

const StyledGrid = styled(Grid)`
  @media(max-width: 600px){
    justify-content: center
  }
`;

const IMG = styled.img`
  width: 185px;
  height: 185px;
`;

const LearnMore = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5vh;
  @media (max-width: 1215px) {
    flex-direction: column-reverse;
    margin-bottom: 25px;
  }
`;

const Premium = styled.img`
  position: absolute;
  transform: translateY(-150%) translateX(65%);

  @media (max-width: 1215px) {
    display: none;
  }
`;
const Service = styled.div`
  min-width: 265px;
  color: #7d91ae;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: pointer;
  border-radius: 15px;
  padding: 0 15px 40px 15px;
  position: relative;
  text-align: center;
  img {
   margin-top: -70px
  }
  @media (max-width: 899px) {
    margin-bottom: 60px
  }
`;

const GlassBg = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  transform: translateY(80px) translateX(150px);
  bottom: 18vh;
  align-content: space-between;
  left: 20vw;
  background: rgba(234, 240, 240, 0);
  border-radius: 8px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.3px);
  -webkit-backdrop-filter: blur(5.3px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 18px;
  width: 25vw;
  height: fit-content;
  @media (max-width: 1215px) {
    transform: translateY(100px) translateX(-50px);
    width: 80%;
  }
  @media(max-width: 600px){
    left: 0;
    width: 95%;
    margin-left: 15px;
    margin-right: 15px;
    transform: translateY(100px) translateX(0);
  }
`;

const StyledH1 = styled.h1`
  color: #022b69;
`;

const P = styled.p`
  margin: 0.2em;
  color: #7d91ae;
`;

export default Main;
