import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

function TokenMenu() {
  return (
    <Container>
      <Items>
        <Item>
          <img src="./images/pcltoken/utico.svg" />
          <div style={{ marginLeft: '15px' }}>
            <p style={{ color: '#022B69', marginBottom: '0px', fontSize: '16px', lineHeight: '24px' }}>
              <Link to="/pcl">Utilities</Link>
            </p>
            <p style={{ marginTop: '0px', color: '#7D91AE', fontSize: '13px', lineHeight: '16px' }}>
              Discover PCL benefits
            </p>
          </div>
        </Item>

        <Item>
          <img src="./images/pcltoken/pclav.svg" />
          <div style={{ marginLeft: '15px' }}>
            <p style={{ color: '#022B69', marginBottom: '0px', fontSize: '16px', lineHeight: '24px' }}>
              <Link to="/wheretobuy">PCL availability</Link>
            </p>
            <p style={{ marginTop: '0px', color: '#7D91AE', fontSize: '13px', lineHeight: '16px' }}>
              Where to buy PCL
            </p>
          </div>
        </Item>
      </Items>

      <div
        style={{
          width: '2px',
          alignSelf: 'center',
          height: '90%',
          background: '#022B69',
          opacity: '.1',
        }}
      ></div>

      <Items>
        <Item>
          <img src="./images/pcltoken/met.svg"/>
          <div style={{ marginLeft: '15px' }}>
            <p style={{ color: '#022B69', marginBottom: '0px', fontSize: '16px', lineHeight: '24px' }}>
              <Link to="/metrics">Metrics</Link>
            </p>
            <p style={{ marginTop: '0px', color: '#7D91AE', fontSize: '13px', lineHeight: '16px' }}>
              Metrics explorer
            </p>
          </div>
        </Item>

        <Item>
          <img src="./images/pcltoken/gift.svg" />
          <div style={{ marginLeft: '15px' }}>
            <p style={{ color: '#022B69', marginBottom: '0px', fontSize: '16px', lineHeight: '24px' }}>
              <Link to="/roadmap">Road map</Link>
            </p>
            <p style={{ marginTop: '0px', color: '#7D91AE', fontSize: '13px', lineHeight: '16px' }}>
              Get our token roadmap
            </p>
          </div>
        </Item>
      </Items>
      <div
        style={{
          width: '2px',
          alignSelf: 'center',
          height: '90%',
          background: '#022B69',
          opacity: '.1',
        }}
      ></div>
      <div>
        <p
          style={{
            color: '#022B69',
            marginBottom: '1px',
            paddingBottom: '4px',
            fontSize: '16px', lineHeight: '24px'
          }}
        >
          Tier-based Membership Program
        </p>
        <p
          style={{ color: '#7D91AE', marginTop: '0px', paddingBottom: '20px', fontSize: '13px', lineHeight: '16px' }}
        >
          Your loyalty is valued
        </p>
        <SubButton href="https://app.saieve.io/">SUBSCRIBE</SubButton>
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: space-around;
  width: 100%;
  padding: 25px;
  border-radius: 4px;
  p {
    font-size: 18px;
  }
  box-shadow: -2px 7px 13px -3px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: -2px 7px 13px -3px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: -2px 7px 13px -3px rgba(0, 0, 0, 0.7);
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  img {
    margin-top: -10px
  }
`;

const SubButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(304.2deg, #0420b6 -1.51%, #304ce3 100%);
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
  border: none;
  width: fit-content;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  text-transform: uppercase;
  color: #f9fafb;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export default TokenMenu;
