import React from "react";
import styled from "styled-components";

interface Props {
  desc: string;
  image: string;
  name: string;
  linkedin: string;
}

export default function ({ desc, name, image, linkedin }: Props) {
  return (
    <Container>
      <img style={{ height: "200px" }} src={image} />
      <h2 style={{ marginTop: "10px", color: "#022B69", fontSize: '20px', lineHeight: '30px' }}>{name}</h2>

      <Info>
        <p style={{ color: "#7D91AE", fontSize: '20px', lineHeight: '30px' }}>{desc}</p>
        <a href={linkedin} target="_blank">
          <img src="./images/about/in.svg" />
        </a>
      </Info>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const Info = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 19px;
`;
