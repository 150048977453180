import React from 'react';
import styled from 'styled-components';
import { Box, Container } from '@mui/material';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <StyledContainer>
      <Container fixed maxWidth={'lg'}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          flexWrap={'wrap'}
          gap={'30px'}
        >
          <Box>
            <MenuTitle>Products</MenuTitle>
            <a href="https://app.saieve.io">
              <MenuItem>SAIEVE Wealth App</MenuItem>
            </a>
            <Link to="/products">
              <MenuItem>Earn</MenuItem>
            </Link>
            <Link to="/invest">
              <MenuItem>Invest</MenuItem>
            </Link>
          </Box>
          <Box>
            <Link to="/pcl">
              <MenuTitle>PCL Token</MenuTitle>
            </Link>
            <Link to="/pcl">
              <MenuItem>Utilities</MenuItem>
            </Link>
            <Link to="/metrics">
              <MenuItem>Metrics</MenuItem>
            </Link>
            <Link to="/wheretobuy">
              <MenuItem>PCL availability</MenuItem>
            </Link>
            <Link to="/roadmap">
              <MenuItem>PCL Roadmap</MenuItem>
            </Link>
          </Box>
          {/*<Box>
            <MenuTitle>Community</MenuTitle>
            <Link to="/referral">
              <MenuItem>Referrals</MenuItem>
            </Link>
            <Link to="/community">
              <MenuItem>Ownership</MenuItem>
            </Link>
            <Link to="/angels">
              <MenuItem>Angels</MenuItem>
            </Link>
            <Link to="/hodlers">
              <MenuItem>Top 100 holders</MenuItem>
            </Link>
          </Box>*/}
          <Box>
            <MenuTitle>Tools & Resources</MenuTitle>
            {/*<MenuItem>Strategies</MenuItem>
            <MenuItem>Calculators</MenuItem>*/}
            <a href="https://peculium.io/documents">
              <MenuItem>Docs</MenuItem>
            </a>
            <a href="https://peculium.io/legal-notice">
              <MenuItem>Legal Notice</MenuItem>
            </a>
            <a href="https://peculium.io/privacy-legacy">
              <MenuItem>Privacy Policy</MenuItem>
            </a>
            <a href="https://peculium.io/terms-conditions">
              <MenuItem>Pricing Conditions</MenuItem>
            </a>
            <a href="https://peculium.io/peculium-webinar">
              <MenuItem>Videos</MenuItem>
            </a>
            <a href="https://peculium.io/blog">
              <MenuItem>Blog</MenuItem>
            </a>
          </Box>
          <Box>
            <Link to="/about">
              <MenuTitle>About us</MenuTitle>
            </Link>
            <Link to="/team">
              <MenuItem>Team</MenuItem>
            </Link>
            <Link to="/careers">
              <MenuItem>Careers</MenuItem>
            </Link>
            <Link to="/philosophy">
              <MenuItem>Philosophy</MenuItem>
            </Link>
            <Link to="/technology">
              <MenuItem>Technology</MenuItem>
            </Link>
            {/*<MenuItem>Partners</MenuItem>
            <MenuItem>Media Center</MenuItem>*/}
          </Box>
        </Box>
        <StyledBox
          display={'flex'}
          justifyContent={'space-between'}
          marginTop="50px"
          alignItems={'center'}
          flexWrap={'wrap'}
        >
          <LogoTitle>PECULIUM</LogoTitle>
          <VersionCom>© 2022 Peculium. All Rights Reserved</VersionCom>
          <Box display={'flex'} columnGap={'24px'}>
            <LinkItem href="https://www.facebook.com/PeculiumICO" target="_blank">
              <img src="./images/fa_facebook.png" alt="facebook" />
            </LinkItem>
            <LinkItem href="https://www.instagram.com/peculium_crypto" target="_blank">
              <img src="./images/akar-icons_instagram-fill.png" alt="instagram" />
            </LinkItem>
            <LinkItem href="https://twitter.com/_Peculium" target="_blank">
              <img src="./images/akar-icons_twitter-fill.png" alt="twitter" />
            </LinkItem>
          </Box>
        </StyledBox>
      </Container>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  background: linear-gradient(90deg, #0220bf 0%, #5e76f7 100%);
  padding: 30px 0;
  z-index: 1;
`;

const StyledBox = styled(Box)`
  @media(max-width: 600px){
    flex-flow: column;
    justify-content: center !important;
    gap: 15px
  }
`;

const MenuTitle = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #f9fafb;
  margin-bottom: 24px;
`;

const MenuItem = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: #f9fafb;
  margin-bottom: 6px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

const LogoTitle = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 36.1271px;
  line-height: 46px;
  color: #f9fafb;
`;

const VersionCom = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #f9fafb;
`;

const LinkItem = styled.a`
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;

export default Footer;
