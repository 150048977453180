import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import FirstPart from './FirstPart';
import SecondPart from './SecondPart';
import Supply from './Supply';
import Chart from './Chart';
import Burn from './Burn';

const Metrics: React.FC = () => {
  return (
    <StyledContainer>
      <FirstPart />
      <SecondPart />
      <Supply />
      <Chart />
      <Burn />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  padding-top: 200px;
`;

export default Metrics;
