import React from "react";
import SubHeader from "./SubHeader";

function index() {
  return (
    <>
      <SubHeader />
    </>
  );
}

export default index;
