import React from 'react';
import styled from 'styled-components';
import { Grid, Container as CTR } from '@mui/material';

function Main() {
  return (
    <CTR fixed maxWidth={'lg'}>
      <Container container flexDirection={'column'} gap={4}>
        <Grid item>
          <h1>8 reasons to join the PECULIUM Angels Program</h1>
        </Grid>
        <img
          style={{
            position: 'absolute',
            width: '20%',
            bottom: '-5%',
            right: '0px',
          }}
          src="./images/community/wing.svg"
        />
        <img
          style={{
            position: 'absolute',
            bottom: '-10%',
            right: '5%',
            width: '20%',
            opacity: '.4',
          }}
          src="./images/community/wing.svg"
        />
        <img
          style={{
            position: 'absolute',
            top: '2%',
            right: '2%',
            opacity: '.4',
          }}
          src="./images/community/Dots2.svg"
        />
        <Grid
          container
          item
          flexDirection={'row'}
          flexWrap={{ xs: 'wrap', md: 'nowrap' }}
        >
          <Grid item xs={12} md={4}>
            <Col>
              <Row>
                <Icon src="./images/community/ic1.svg" />
                <Col>
                  <b>
                    Participate in development and promotion innovative products
                  </b>
                  <p style={{ color: '#7D91AE' }}>
                    We rely on innovation to make investing better, simpler and
                    more productive for our clients. Our SAIΞVE app is a product
                    of institutional-grade quality and reliability.
                  </p>
                </Col>
              </Row>
            </Col>
          </Grid>

          <Grid xs={12} md={4}>
            <Col>
              <Row>
                <Icon src="./images/community/ic4.svg" />
                <Col>
                  <b>Additional access to the core team</b>
                  <p style={{ color: '#7D91AE' }}>
                    Get invitations to our internal meetings, annual employee
                    seminars and special events (after-works).
                  </p>
                </Col>
              </Row>
            </Col>
          </Grid>
          <Grid item xs={12} md={4}>
            <Col>
              <Row>
                <Icon src="./images/community/ic7.svg" />
                <Col>
                  <b>We value Inclusion and diversity</b>
                  <p style={{ color: '#7D91AE' }}>
                    PECULIUM is committed in continuing to make diversity,
                    equity, and inclusion part of everything we do—from how we
                    build our products to how we build our workforce. Our team
                    is represented by 13 nationalities.
                  </p>
                </Col>
              </Row>
            </Col>
          </Grid>
        </Grid>

        <Grid container item flexDirection={'row'}>
          <Grid xs={12} md={4}>
            <Col>
              <Row>
                <Icon src="./images/community/ic2.svg" />
                <Col>
                  <b>Get special privileges</b>
                  <p style={{ color: '#7D91AE' }}>
                    Receive early news regarding upcoming features and products
                    and company updates. Benefit from early feature releases
                    ahead of the general public. Get exclusive admin moderator
                    roles.
                  </p>
                </Col>
              </Row>
            </Col>
          </Grid>
          <Grid xs={12} md={4}>
            <Col>
              <Row>
                <Icon src="./images/community/ic5.svg" />
                <Col>
                  <b>Be part of a loyal community</b>
                  <p style={{ color: '#7D91AE' }}>
                    It starts and ends here - our entire platform and our
                    approach to customer service has been designed from the
                    ground up to put the interests of our community first.
                  </p>
                </Col>
              </Row>
            </Col>
          </Grid>
          <Grid xs={12} md={4}>
            <Col>
              <Row>
                <Icon src="./images/community/ic8.svg" />
                <Col>
                  <b>Make an impact</b>
                  <p style={{ color: '#7D91AE' }}>
                    Inclusion unlocks our ability to innovate and to be
                    creative. It brings different lived experiences and
                    perspectives to a problem, so that we’re solving them in new
                    ways. And we’re able to better entertain our current and
                    future members and make an impact in the world. When we get
                    that right, magic is possible.
                  </p>
                </Col>
              </Row>
            </Col>
          </Grid>
        </Grid>

        <Grid container item flexDirection={'row'}>
          <Grid xs={12} md={4}>
            <Col>
              <Row>
                <Icon src="./images/community/ic3.svg" />
                <Col>
                  <b>Make some extra cash</b>
                  <p style={{ color: '#7D91AE' }}>
                    As an PECULIUM Angel, you'll have the opportunity to
                    participate in missions and earn exclusive rewards in PCL!
                    Currently Angels are earning PCLs for tasks they accomplish.
                  </p>
                </Col>
              </Row>
            </Col>
          </Grid>
          <Grid xs={12} md={4}>
            <Col>
              <Row>
                <Icon src="./images/community/ic6.svg" />
                <Col>
                  <b>Trust through transparency</b>
                  <p style={{ color: '#7D91AE' }}>
                    Trust fuelled by transparency underlies everything we do,
                    both internally and externally, across teams and products.
                  </p>
                </Col>
              </Row>
            </Col>
          </Grid>
        </Grid>
      </Container>
    </CTR>
  );
}

const Container = styled(Grid)`
  background: #304ce3;
  width: 80vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: auto;
  border-radius: 15px;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 100px;
  position: relative;
  justify-content: space-between;
  padding: 80px;
  color: #f9fafb;
  border-radius: 8px;
  @media (max-width: 800px) {
    width: 100%;
    padding: 15px;
    padding-top: 55px;
  }
`;

const Icon = styled.img`
  align-self: flex-start;
  width: 35px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: 50px;
  gap: 10px;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;
`;
export default Main;
