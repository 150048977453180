import React from 'react';
import Jobs from './Jobs';
import Main from './Main';
import styled from "styled-components";

function index() {
  return (
    <Section>
      <Main />
      <Jobs />
    </Section>
  );
}

const Section = styled.section`
  padding: 80px 0;
  background-image: url('./images/pcltoken/eclipse.svg');
  background-position: left center;
  background-repeat: no-repeat;
`;

export default index;
