import React from 'react';
import styled from 'styled-components';
import { Box, Container } from '@mui/material';
import './lcarousel.scss';

const slideWidth = 30;

const _items = [
  {
    player: {
      title: 'Efren Reyes',
      desc: 'Known as "The Magician", Efren Reyes is well regarded by many professionals as the greatest all around player of all time.',
      image: 'images/home/accuracy.png',
    },
  },
  {
    player: {
      title: "Ronnie O'Sullivan",
      desc: "Ronald Antonio O'Sullivan is a six-time world champion and is the most successful player in the history of snooker.",
      image: 'images/home/swipelux.png',
    },
  },
  {
    player: {
      title: 'Shane Van Boening',
      desc: 'The "South Dakota Kid" is hearing-impaired and uses a hearing aid, but it has not limited his ability.',
      image: 'images/home/bpifinance.png',
    },
  },
  {
    player: {
      title: 'Mike Sigel',
      desc: 'Mike Sigel or "Captain Hook" as many like to call him is an American professional pool player with over 108 tournament wins.',
      image: 'images/home/accuracy.png',
    },
  },
  {
    player: {
      title: 'Willie Mosconi',
      desc: 'Nicknamed "Mr. Pocket Billiards," Willie Mosconi was among the first Billiard Congress of America Hall of Fame inductees.',
      image: 'images/home/swipelux.png',
    },
  },
  {
    player: {
      title: 'Willie Mosconi',
      desc: 'Nicknamed "Mr. Pocket Billiards," Willie Mosconi was among the first Billiard Congress of America Hall of Fame inductees.',
      image: 'images/home/colead.png',
    },
  },
  {
    player: {
      title: 'Willie Mosconi',
      desc: 'Nicknamed "Mr. Pocket Billiards," Willie Mosconi was among the first Billiard Congress of America Hall of Fame inductees.',
      image: 'images/home/fblocks.jpeg',
    },
  },
  {
    player: {
      title: 'Willie Mosconi',
      desc: 'Nicknamed "Mr. Pocket Billiards," Willie Mosconi was among the first Billiard Congress of America Hall of Fame inductees.',
      image: 'images/home/chainlink.png',
    },
  },
  {
    player: {
      title: 'Willie Mosconi',
      desc: 'Nicknamed "Mr. Pocket Billiards," Willie Mosconi was among the first Billiard Congress of America Hall of Fame inductees.',
      image: 'images/home/cryptoo.svg',
    },
  },
  {
    player: {
      title: 'Willie Mosconi',
      desc: 'Nicknamed "Mr. Pocket Billiards," Willie Mosconi was among the first Billiard Congress of America Hall of Fame inductees.',
      image: 'images/home/0kx.png',
    },
  },
  {
    player: {
      title: 'Willie Mosconi',
      desc: 'Nicknamed "Mr. Pocket Billiards," Willie Mosconi was among the first Billiard Congress of America Hall of Fame inductees.',
      image: 'images/home/alliance.png',
    },
  },
  {
    player: {
      title: 'Willie Mosconi',
      desc: 'Nicknamed "Mr. Pocket Billiards," Willie Mosconi was among the first Billiard Congress of America Hall of Fame inductees.',
      image: 'images/home/wortoon.svg',
    },
  },
  {
    player: {
      title: 'Willie Mosconi',
      desc: 'Nicknamed "Mr. Pocket Billiards," Willie Mosconi was among the first Billiard Congress of America Hall of Fame inductees.',
      image: 'images/home/orwl.png',
    },
  },
];

const length = _items.length;
_items.push(..._items);

const sleep = (ms = 0) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const createItem = (position: any, idx: any) => {
  const item = {
    styles: {
      transform: `translateX(${position * slideWidth}rem)`,
      filter: '',
      opacity: 1,
    },
    player: _items[idx].player,
  };

  switch (position) {
    case length - 1:
    case length + 1:
      item.styles = { ...item.styles, filter: 'grayscale(1)' };
      break;
    case length:
      break;
    default:
      item.styles = { ...item.styles, opacity: 0 };
      break;
  }

  return item;
};

const CarouselSlideItem = ({ pos, idx, activeIdx }: any) => {
  const item = createItem(pos, idx);

  return (
    <li className="lcarousel__slide-item" style={item.styles}>
      <div className="lcarousel__slide-item-img-link">
        <img src={item.player.image} alt={item.player.title} />
      </div>
      {/* <div className="lcarousel-slide-item__body">
                <h4>{item.player.title}</h4>
                <p>{item.player.desc}</p>
            </div> */}
    </li>
  );
};

const keys = Array.from(Array(_items.length).keys());

const Carousel = () => {
  const [items, setItems] = React.useState(keys);
  const [isTicking, setIsTicking] = React.useState(false);
  const [activeIdx, setActiveIdx] = React.useState(0);
  const bigLength = items.length;

  const prevClick = (jump = 1) => {
    if (!isTicking) {
      setIsTicking(true);
      setItems((prev) => {
        return prev.map((_, i) => prev[(i + jump) % bigLength]);
      });
    }
  };

  const nextClick = (jump = 1) => {
    if (!isTicking) {
      setIsTicking(true);
      setItems((prev) => {
        return prev.map((_, i) => prev[(i - jump + bigLength) % bigLength]);
      });
    }
  };

  const handleDotClick = (idx: number) => {
    if (idx < activeIdx) prevClick(activeIdx - idx);
    if (idx > activeIdx) nextClick(idx - activeIdx);
  };

  React.useEffect(() => {
    if (isTicking) sleep(300).then(() => setIsTicking(false));
  }, [isTicking]);

  React.useEffect(() => {
    setActiveIdx((length - (items[0] % length)) % length) // prettier-ignore
  }, [items]);

  return (
    <div className="lcarousel__wrap">
      <div className="lcarousel__inner">
        <div className="lcarousel__container">
          <ul className="lcarousel__slide-list">
            {items.map((pos, i) => (
              <CarouselSlideItem
                key={i}
                idx={i}
                pos={pos}
                activeIdx={activeIdx}
              />
            ))}
          </ul>
        </div>
        {/* <div className="lcarousel__dots">
            {items.slice(0, length).map((pos, i) => (
              <button key={i} onClick={() => handleDotClick(i)} className={i === activeIdx ? "dot active" : "dot"} />
            ))}
          </div> */}
        <Container fixed maxWidth={'xl'}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-around'}
            gap={48}
          >
            <button
              style={{ position: 'absolute', left: '0px', top: '0px' }}
              className="lcarousel__btn lcarousel__btn--prev"
              onClick={() => prevClick()}
            >
              {'<'}
            </button>
            <button
              style={{ position: 'absolute', right: '0px', top: '0px' }}
              className="lcarousel__btn lcarousel__btn--next"
              onClick={() => nextClick()}
            >
              {'>'}
            </button>
          </Box>
        </Container>
      </div>
    </div>
  );
};

const PartnerPart: React.FC = () => {
  return (
    <StyledContainer>
      <Container fixed maxWidth={'xl'}>
        <Title>Our Partners</Title>
        <Carousel />

        <BackedBox>
          <BackedTitle>Backed by</BackedTitle>

          <BackedContent>
            <BackedItem>
              <a href="https://www.gemny.com/" target="_blank">
                <img src="./images/home/gem.png" alt="gem" />
              </a>
            </BackedItem>
            <BackedItem>
              <a href="https://www.criptoniteam.com/" target="_blank">
                <img src="./images/home/criptonteam.png" alt="criptonteam" />
              </a>
            </BackedItem>
            <BackedItem>
              <a href="https://www.trendigm.com/" target="_blank">
                <img src="./images/home/trendigm.png" alt="trenedigm" />
              </a>
            </BackedItem>
            <BackedItem>
              <a href="https://www.chatilarais.com/" target="_blank">
                <img src="./images/home/chatilarais.svg" alt="chatila" />
              </a>
            </BackedItem>
          </BackedContent>
        </BackedBox>
      </Container>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  padding: 0 12px;
  padding-top: 250px;

  padding-bottom: 300px;

  height: 100vh;
  margin-bottom: 220px;

  @media (max-width: 1215px) {
    height: fit-content;
    margin-bottom: 0px;
  }
`;

const Title = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 150%;
  text-align: center;
  color: #022b69;
  @media (max-width: 800px) {
    margin-top: 0px;
  }
`;

const BackedBox = styled(Box)`
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  box-shadow: 0px 4px 59px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 106px;
  padding-right: 100px;
  height: 304px;
  padding-top: 37px;
  margin-bottom: 97px;
  @media (max-width: 800px) {
    margin-top: 135px;
    padding: 0px;
    padding-top: 50px;
    padding-bottom: 50px;
    height: fit-content;
  }
`;

const BackedTitle = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  color: #022b69;
`;

const BackedContent = styled(Box)`
  margin-top: 70px;
  display: flex;
  justify-content: space-around;
  @media (max-width: 1215px) {
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
  }
`;

const BackedItem = styled(Box)`
  cursor: pointer;
  width: 100px;
  height: 70px;
  &:hover {
    opacity: 0.7;
  }
  img {
    height: 80px;
    width: 100px;
    object-fit: contain;
  }
`;

export default PartnerPart;
