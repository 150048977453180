import React from 'react';
import styled from 'styled-components';
import { Box, Button, Container } from '@mui/material';

const IntroPart: React.FC = () => {
  return (
    <StyledContainer>
      <img
        style={{ position: 'absolute', left: '-30%', top: '20%' }}
        src="./images/home/reds.png"
      />
      <Container fixed maxWidth={'lg'}>
        <StyledBox display={'flex'}>
          <TextPart>
            <Title>
              Crypto Investing isn't just <br />
              about money—it's about
              <br />
              <Deco>your future</Deco>
            </Title>
            <Desc>
              Let us help you create the future you want for yourself and your
              <br />
              loved ones. Whether you're new to investing or an experienced
              <br />
              trader, we're here to help you on your way.
            </Desc>
            <a href="http://app.saieve.io/" target="_blank">
              <StartButton>
                <h3>GET STARTED</h3>
              </StartButton>
            </a>
          </TextPart>
          <IntroImg>
            <img
              style={{ width: '370px', height: '446px' }}
              src="./images/home/smartGirl.png"
              alt="smartGirl"
            />
          </IntroImg>
        </StyledBox>
      </Container>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: relative;
  @media (max-width: 1215px) {
    padding: 0px;
    height: fit-content;
  }
`;

const StyledBox = styled(Box)`
  align-items: center;
  @media (max-width: 1215px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const TextPart = styled(Box)`
  padding-top: 250px;
  padding-bottom: 250px;
  display: flex;
  width: 100vw;
  flex-direction: column;
  grid-row-gap: 30px;
  @media (max-width: 800px) {
    padding-top: 75px;
    padding-bottom: 75px;
  }
`;

const Title = styled.span`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 150%;

  /* or 72px */

  /* Primary/Darkblue 1 */

  color: #022b69;
  @media (max-width: 480px) {
    font-size: 35px;
  }
`;

const Desc = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #7d91ae;
`;

const Deco = styled.span`
  background: #0220bf;
  border-radius: 4px;
  color: white;
  padding: 10px;
  height: 20px;
`;

const StartButton = styled(Button)`
  background: linear-gradient(304.2deg, #0420b6 -1.51%, #304ce3 100%);
  border-radius: 6px;
  font-family: 'Arial';
  font-size: 33px;
  line-height: 24px;
  font-weight: 700;
  color: #f9fafb !important;
  width: 180px;
  height: 48px;
`;

const IntroImg = styled(Box)`
  @media (max-width: 800px) {
    padding-top: 45px;
    padding-bottom: 75px;
  }
`;

export default IntroPart;
