import React, { useState } from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
interface Props {
  menuText: string;
  route?: string;
  selected?: boolean;
  setSelected: any;
  hasSubMenu: boolean;
  external: boolean;
  link?: string;
}

const MenuItem: React.FC<Props> = ({
  menuText,
  selected,
  setSelected,
  route,
  hasSubMenu,
  external,
  link,
}: any) => {
  return (
    <StyledContainer selected={selected} onMouseOver={setSelected}>
      {
        external ? <StyledExternalLink
          target="_blank"
          href={link}
          style={{ width: 'max-content' }}
          selected={selected === true}
        >
          <span>
            {menuText}{' '}
          </span>
        </StyledExternalLink> :
          <StyledLink
            to={route}
            style={{ width: 'max-content' }}
            selected={selected === true}
          >
            <span>
              {menuText}{' '}
              {
                hasSubMenu &&
                <>
                  {selected ? (
                    <ArrowDropUp style={{ marginBottom: '-8px' }} />
                  ) : (
                    <ArrowDropDown style={{ marginBottom: '-8px' }} />
                  )}
                </>
              }
            </span>
          </StyledLink>
      }
    </StyledContainer>
  );
};

const StyledLink = styled(Link)<{ selected: boolean }>`
  width: max-content;
  ::after {
    content: '';
    width: 50%;
    height: 2px;
    background: blue;
    display: ${({ selected }) => (selected ? 'block' : 'none')};
  }
`;
const StyledExternalLink = styled.a<{ selected: boolean }>`
  width: max-content;
  ::after {
    content: '';
    width: 50%;
    height: 2px;
    background: blue;
    display: ${({ selected }) => (selected ? 'block' : 'none')};
  }
`;

const StyledContainer = styled(Box)<{ selected: boolean }>`
  display: flex;
  align-items: center;
  grid-column-gap: 8px;
  span {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgb(125, 145, 174);
    padding-bottom: 0.2em;
  }
  cursor: pointer;
  transition: all 0.6s;
`;

export default MenuItem;
