import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Container as CTR, Grid, Box } from '@mui/material';

function Main() {
  return (
    <div>
      <CTR fixed maxWidth={'lg'}>
        <Box
          height={{ xs: 'fit-content', md: '100vh' }}
          display={'flex'}
          alignItems="center"
        >
          <Container>
            <Col>
              <Glass>
                <img
                  src="./images/about/Checkwhite.svg"
                  style={{ justifySelf: 'center' }}
                />
                Personalization
              </Glass>
              <Glass>
                <img src="./images/about/Checkwhite.svg" /> Automation
              </Glass>
            </Col>
            <Col>
              <Glass>
                {' '}
                <img src="./images/about/Checkwhite.svg" /> Diversification
              </Glass>
              <Glass>
                <img src="./images/about/Checkwhite.svg" /> Low fees
              </Glass>
            </Col>
            <Col>
              <Glass>
                <img src="./images/about/Checkwhite.svg" /> Manage taxes
              </Glass>
              <Glass>
                <img src="./images/about/Checkwhite.svg" /> Cruise control
              </Glass>
            </Col>
          </Container>
        </Box>

        <Grid
          container
          flexDirection={'column'}
          rowGap={8}
        >
          <Grid
            container
            item
            flexDirection={'row'}
            justifyContent={'space-between'}
            rowGap={4}
          >
            <Card img="./images/about/img8.png"/>
            <Card style={{ backgroundColor: '#A680E6' }}>
              <Col style={{ color: 'white' }}>
                <h2 style={{ alignSelf: 'center', textAlign: 'center' }}>
                  Personalization
                </h2>
                <p
                  style={{
                    alignSelf: 'center',
                    marginLeft: '9px',
                    marginRight: '9px',
                    fontSize: '16px',
                  }}
                >
                  We want you to reach your financial goals. So, we start by
                  learning about you. What do you want to achieve? How soon do
                  you want to achieve it? The right investment depends on how
                  much you need to earn and how much time you have to earn it.
                  Not sure how much you need to reach your goal? Or how much you
                  can even invest? We can help with that, too. We explore how
                  things like your income, where you live, and your tax bracket
                  affect what your goal looks like and how fast you can reach
                  it. The result is clear, achievable goals that fit your life
                  and help you get the most from your accounts.
                </p>
              </Col>
            </Card>
            <Card img="./images/about/img9.png"/>
          </Grid>
          <Grid
            container
            item
            flexDirection={'row'}
            justifyContent={'space-between'}
            rowGap={4}
          >
            <Card style={{ backgroundColor: '#4493FF' }}>
              <Col style={{ color: 'white' }}>
                <h2 style={{ alignSelf: 'center', textAlign: 'center' }}>
                  Automation and discipline
                </h2>
                <p
                  style={{
                    alignSelf: 'center',
                    marginLeft: '9px',
                    marginRight: '9px',
                    fontSize: '16px'
                  }}
                >
                  With automatic deposits and decisions based on algorithms,
                  it’s easy to make steady progress toward your goals. It’s also
                  easier to avoid common investor mistakes. Your portfolio will
                  likely experience short-term losses here and there. It’s part
                  of investing. And your reactions to these losses can have a
                  huge impact on your long-term success. You can’t let emotions
                  get in the way of your goals. By automating your portfolio and
                  staying disciplined about investing, you’re better equipped to
                  ride out the short-term losses and enjoy long-term gains.
                </p>
              </Col>
            </Card>
            <Card img="./images/about/img3.png"/>
            <Card style={{ backgroundColor: '#09CFDC' }}>
              <Col style={{ color: 'white' }}>
                <h2 style={{ alignSelf: 'center', textAlign: 'center' }}>
                  Diversification
                </h2>
                <p
                  style={{
                    alignSelf: 'center',
                    marginLeft: '9px',
                    marginRight: '9px',
                    fontSize: '16px'
                  }}
                >
                  We’re not here to help you pick the perfect stock. Investing
                  in a single financial asset leaves you vulnerable to that
                  asset’s risks. With a single stock, you could lose everything
                  if a company goes bankrupt, performs poorly, or gets some bad
                  press. Instead, we help you build a well-diversified
                  portfolio. Diversification spreads your investments across
                  assets, asset classes, and even markets, lowering your overall
                  risk. We automatically diversify your portfolio based on your
                  goals and desires.
                </p>
              </Col>
            </Card>
          </Grid>
          <Grid
            container
            item
            flexDirection={'row'}
            justifyContent={'space-between'}
            rowGap={4}
          >
            <Card img="./images/about/img4.png"/>
            <Card style={{ backgroundColor: '#A47EE6' }}>
              <Col style={{ color: 'white' }}>
                <h2 style={{ alignSelf: 'center', textAlign: 'center' }}>
                  Lower fees and costs
                </h2>
                <p
                  style={{
                    alignSelf: 'center',
                    marginLeft: '9px',
                    marginRight: '9px',
                    fontSize: '16px'
                  }}
                >
                  A robo-advisor can save you a lot of money in fees and ongoing
                  costs. We remove human advisors from the equation—unless you
                  want them. When you have Assets Under Management (AUM) with a
                  robo-advisor, the fees are typically a fraction of what you’d
                  pay for a financial advisor. Our fees are just 0.25% annually.
                  With a financial advisor, you’re looking at more like 1% to
                  2%. And since everything is based on algorithms, you won’t pay
                  extra for rebalancing and making other changes to your
                  portfolio.
                </p>
              </Col>
            </Card>
            <Card
              img="./images/about/Values.png"
              style={{ backgroundSize: 'cover' }}
            />
          </Grid>
          <Grid
            container
            item
            flexDirection={'row'}
            justifyContent={'space-between'}
            rowGap={4}
          >
            <Card style={{ backgroundColor: '#09CFDC' }}>
              <Col style={{ color: 'white' }}>
                <h2 style={{ alignSelf: 'center', textAlign: 'center' }}>
                  Manage taxes
                </h2>
                <p
                  style={{
                    alignSelf: 'center',
                    marginLeft: '9px',
                    marginRight: '9px',
                    fontSize: '16px'
                  }}
                >
                  Taxes are one of the trickiest parts of investing. Sell for a
                  profit at the wrong time, and you’ll be on the hook for
                  short-term capital gains taxes. Sell the right asset for a
                  loss at the right time, and you can offset taxes through
                  tax-loss harvesting. Managing taxes wisely helps keep your
                  gains consistent and maximize what you get to keep. With a
                  robo-advisor like SAIEVE, this can happen automatically if you
                  elect this feature. The algorithm optimizes your portfolio to
                  try and keep you from being hit hard by taxes. A financial
                  advisor can do this too, but will usually charge an additional
                  fee.
                </p>
              </Col>
            </Card>
            <Card img="./images/about/img6.png"></Card>
            <Card style={{ backgroundColor: '#4493FF' }}>
              <Col style={{ color: 'white' }}>
                <h2 style={{ alignSelf: 'center', textAlign: 'center' }}>
                  Put your financial goals
                  <br /> on cruise control
                </h2>
                <p
                  style={{
                    alignSelf: 'center',
                    marginLeft: '9px',
                    marginRight: '9px',
                    fontSize: '16px'
                  }}
                >
                  Investing doesn’t have to be difficult. And reaching your
                  financial goals doesn’t have to take all your time and
                  attention. With a robo-advisor and a smart strategy, you can
                  create your plan and let the algorithms do the rest. Instead,
                  we help you build a well-diversified portfolio.
                  Diversification spreads your investments across assets, asset
                  classes, and even markets, lowering your overall risk. We
                  automatically diversify your portfolio based on your goals and
                  desires.
                </p>
              </Col>
            </Card>
          </Grid>
        </Grid>
        <Box display={'flex'} justifyContent={'center'}>
          <Btn>
            <Link to="/technology">OUR TECHNOLOGY</Link>{' '}
          </Btn>
        </Box>
      </CTR>
    </div>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  position: relative;
  width: 100%;
  margin: auto;
  margin-bottom: 50px;
  margin-top: 20px;
  height: fit-content;
  padding: 50px;
  border-radius: 8px;
  overflow: hidden;
  background : url('./images/about/squares.png') #304ce3;
  @media (max-width: 1215px) {
    flex-direction: column-reverse;
    padding-left: 25px;
  }
`;

const Glass = styled.div`
  /* From https://css.glass */
  padding: 25px;
  background-image: url('./images/about/bgglass.svg');
  background-repeat: no-repeat;
  gap: 7px;
  backdrop-filter: blur(5px);
  font-size: 22px;
  font-weight: 500;
  display: flex;
  width: 100%;
  color: white;
  :hover {
    transform: translateY(-13px);
  }
  transition: 0.5s;
  cursor: pointer;
  @media (max-width: 800px) {
    font-size: 16px;
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
`;

const Card = styled.div.attrs((p: { img: string }) => ({ img: p.img || '' }))`
  width: 350px;
  height: 430px;
  border-radius: 9px;
  padding: 20px;
  background: url(${(p) => p.img || ''});
  padding-top: 30px;
  padding-left: 34px;
  padding-right: 35px;
  padding-bottom: 35px;
  background-repeat: no-repeat;
  font-size: 16px;
  background-size: '100% 100%';
  cursor: pointer;
`;

export const Btn = styled.button`
  background: linear-gradient(304.2deg, #0420b6 -1.51%, #304ce3 100%);
  border-radius: 7px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: white;
  width: fit-content;
  border: none;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #f9fafb;
  cursor: pointer;
  margin-left: 25px;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  &:hover {
    opacity: 0.7;
  }
`;

export default Main;
