import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Btn } from '../philosophy/Main';
import { Container } from "@mui/material";
export default function () {
  return (
    <>
      <Section>
        <Container fixed  maxWidth={'lg'} style={{ position: 'relative' }}>
          <TextPart>
            <h1 style={{ fontSize: '48px', lineHeight: '72px' }}>Our world is changing </h1>
            <p style={{ textAlign: 'center', fontSize: '18px', lineHeight: '26px' }}>
              And the digitalisation of the economy is changing the way <br />
              financial services are accessed, delivered, and <br/>experienced.
            </p>
          </TextPart>
        </Container>
      </Section>
      <Container fixed  maxWidth={'lg'}>
        <TechContainer>
          <Col>
            <h1 style={{ color: '#022B69' }}>Our Technology</h1>
            <h2 style={{ color: '#022B69', fontSize: '20px', marginTop: '15px' }}>
              <img src="./images/about/Check.svg" /> Data science approach
            </h2>
            <p style={{ color: '#7D91AE', fontSize: '16px', lineHeight: '24px' }}>
              Our trading and risk analytics engine is based on deep data
              agent-based algorithms scanning in real-time multiple
              quantitative primary data sources. <br />
              These algorithms analyse the dynamic behaviour of market
              participants – i.e. buyers and sellers – and cluster them based on common feature
              sets. <br />
              Noise classification, cluster identification, and behavioural
              finance theory are part of our unique core capabilities
            </p>
            <h2 style={{ color: '#022B69', fontSize: '20px', marginTop: '15px' }}>
              <img src="./images/about/Check.svg" /> Underlying Analytics
              technology
            </h2>
            <p style={{ color: '#7D91AE', fontSize: '16px', lineHeight: '24px' }}>
              The technology underlying our SAIEVE App is based on decades of
              knowledge and experience gained from academia and tier 1 investment
              banking software development. Our analytics engine has been
              extensively tested in different sectors and economic cycles and has
              shown consistent performance and superior insights. In addition,
              exhaustive market research confirms the highly innovative nature of
              this technology and strong technical barriers to entry.
            </p>
            <h2 style={{ color: '#022B69', fontSize: '20px', marginTop: '15px' }}>
              <img src="./images/about/Check.svg" /> Our Coverage
            </h2>
            <p style={{ color: '#7D91AE', fontSize: '16px', lineHeight: '24px' }}>
              Our software is continuously monitoring and analysing data flows on
              the world’s major crypto exchanges. Product coverage includes most
              global spot and futures markets.
            </p>
          </Col>
          <img
            style={{ width: '100%', height: '100%' }}
            src="./images/about/Techim.png"
          />
        </TechContainer>
        <div style={{ textAlign: 'center' }}>
          <Btn>
            <Link to="/team"> OUR TEAM</Link>
          </Btn>
        </div>

      </Container>
    </>
  );
}

const Section = styled.div`
  height: 100vh;
  background-image: url('./images/about/bg_tech.png');
  background-size: cover;
  display: flex;
  padding-top: 60px
`;

const TechContainer = styled.div`
  height: fit-content;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  max-width: fit-content;
  gap: 90px;
  padding: 75px 0 50px 0;
  @media (max-width: 1215px) {
    flex-direction: column;
    padding: 10px;
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const TextPart = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
`;
