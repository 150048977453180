import React, { useEffect } from 'react';
import './index.css';
export default function () {
  useEffect(
    () =>
      (function () {
        'use strict';

        // define variables
        var items = document.querySelectorAll('.timeline li');
        console.log(items);

        // check if an element is in viewport
        // http://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
        function isElementInViewport(el) {
          var rect = el.getBoundingClientRect();
          return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <=
              (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <=
              (window.innerWidth || document.documentElement.clientWidth)
          );
        }

        function callbackFunc() {
          for (var i = 0; i < items.length; i++) {
            if (isElementInViewport(items[i])) {
              items[i].classList.add('in-view');
            }
          }
        }

        // listen for events
        window.addEventListener('load', callbackFunc);
        window.addEventListener('resize', callbackFunc);
        window.addEventListener('scroll', callbackFunc);
      })(),
    []
  );

  return (
    <>
      <section className="timeline">
        <ul>
          <li>
            <div>Tier-2 exchange listing</div>
          </li>
          <li>
            <div>New Membership program</div>
          </li>
          <li>
            <div>Pancakeswap Listing </div>
          </li>
          <li>
            <div>Apeswap Listing </div>
          </li>
          <li>
            <div>Liquidity mining </div>
          </li>

          <li>
            <div>Staking (BSC) </div>
          </li>
          <li>
            <div>Boosted staking </div>
          </li>
          <li>
            <div>Tier-1 exchange listing </div>
          </li>
          <li>
            <div>Angels and afifliates program </div>
          </li>
          <li>
            <div>PCL back to ETH </div>
          </li>
          <li>
            <div>Staking (ERC-20) </div>
          </li>
          <li>
            <div>Uniswap listing </div>
          </li>
          <li>
            <div>Sushiswap Listing </div>
          </li>
          <li>
            <div>PCL on Polygon </div>
          </li>
          <li>
            <div>Staking Polygon</div>
          </li>
          <li>
            <div>Buyback program </div>
          </li>
          <li>
            <div>Smart HODL PCL </div>
          </li>
          <li>
            <div>Governance rights </div>
          </li>
          <li>
            <div>Launchpad</div>
          </li>
        </ul>
      </section>
    </>
  );
}
