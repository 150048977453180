import React from 'react';
import styled from 'styled-components';
import { Box, Container } from '@mui/material';

const LastPart: React.FC = () => {
  return (
    <StyledContainer>
      <Container fixed maxWidth={'xl'}>
        <Box display={'flex'} justifyContent={'center'}>
          <Title>
            OVER <TDeco>8,000</TDeco> HAPPY INVESTORS WHO
            <br /> CHOOSE TO EARN MORE AND WORRY LESS
          </Title>
        </Box>
        <ProgressBox>
          <ProgItem>
            <ProgressCont>{20}</ProgressCont>
            <ProgressTitle>{'countries'}</ProgressTitle>
          </ProgItem>
          <ProgItem>
            <ProgressCont>{40}</ProgressCont>
            <ProgressTitle>{'supported cryptocurrencies'}</ProgressTitle>
          </ProgItem>
          <ProgItem>
            <ProgressCont>{'$0.5M'}</ProgressCont>
            <ProgressTitle>{'earnings since 2019'}</ProgressTitle>
          </ProgItem>
          <ProgItem>
            <ProgressCont>{'$3M'}</ProgressCont>
            <ProgressTitle>{'assets under management'}</ProgressTitle>
          </ProgItem>
          <ProgItem>
            <ProgressCont>{'15M'}</ProgressCont>
            <ProgressTitle>{'transactions'}</ProgressTitle>
          </ProgItem>
          <ProgItem>
            <ProgressCont>{'$100M'}</ProgressCont>
            <ProgressTitle>{'trading volume'}</ProgressTitle>
          </ProgItem>
        </ProgressBox>

        <WrapperBox2>
          <Title1>
            Our software makes countless smart decisions.
            <br />
            <Title2>You just need to make one.</Title2>
          </Title1>
          <a href="http://app.saieve.io/" target="_blank">
            <StartButton>GET STARTED</StartButton>
          </a>
        </WrapperBox2>
        <img
          style={{
            width: '500px',
            height: '500px',
            position: 'absolute',
            bottom: '-10%',
            left: '0%',
          }}
          src="./images/home/sb.png"
        />
      </Container>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  position: relative;
  height: fit-content;
  padding-bottom: 157px;
  @media (max-width: 1215px) {
    padding: 0px;
    height: fit-content;
    margin-bottom: 25px;
  }
  @media (max-width: 800px) {
    margin-top: 135px;
    margin-bottom: 135px;
  }
`;

const Title = styled.span`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 150%;
  text-align: center;
  color: #022b69;
`;

const TDeco = styled.span`
  background: #0220bf;
  border-radius: 4px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  text-align: center;
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;

  padding-left: 15px;
  padding-right: 15px;
`;

const ProgressBox = styled(Box)`
  margin-top: 72px;
  background: linear-gradient(304.2deg, #0420b6 -1.51%, #304ce3 100%);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 60px 60px;
  @media (max-width: 1215px) {
    flex-direction: column;
    gap: 25px;
    align-items: center;
  }
  @media (max-width: 800px) {
    margin-top: 135px;
  }
`;

const ProgItem = styled(Box)`
  max-width: 150px;
`;

const ProgressCont = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  text-align: center;
  color: #f9fafb;
`;

const ProgressTitle = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: #f9fafb;
`;

const WrapperBox2 = styled(Box)`
  margin-top: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 800px) {
    margin-top: 135px;
  }
`;

const Title1 = styled.span`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 150%;
  text-align: center;
  color: #022b69;
`;

const Title2 = styled.span`
  background: #0220bf;
  border-radius: 4px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 150%;
  text-align: center;
  color: white;
  padding: 5px 10px;
`;

const StartButton = styled(Box)`
  margin-top: 50px;
  background: #0220bf;

  border-radius: 6px;
  cursor: pointer;
  padding: 12px 20px;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #f9fafb !important;

  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 0.7;
  }

  width: fit-content;
`;

// const StartButton = styled(Button)`
//   background: linear-gradient(304.2deg, #0420b6 -1.51%, #304ce3 100%);
//   border-radius: 6px;
//   font-family: "Arial";
//   font-style: normal;
//   font-weight: 700;
//   font-size: 20px;
//   line-height: 24px;
//   color: #f9fafb !important;
//   width: 180px;
//   height: 48px;
// `;

export default LastPart;
