import React from 'react';
import { WithBackground } from './SubHeader';
import styled from 'styled-components';
import {Button, Container, Grid} from '@mui/material';
function SubMain() {
  return (
    <Section>
      <Container fixed maxWidth={'lg'}>
        <StyledGrid container spacing={2}>
          <StyledSubGrid item md={7}>
            <img
              src="./images/pcltoken/BurnLogo.svg"
            />
            <Glassbg
              style={{
                position: 'absolute',
                bottom: '70%',
                right: '10%',
                backdropFilter: 'blur(8px)',
                fontSize: '17px',
              }}
            >
              <img src="./images/metrics/IconBurn.svg" />
              PCL BURNED
              <b style={{ fontSize: '30px' }}>2,88M</b>
            </Glassbg>
          </StyledSubGrid>
          <StyledSubGrid item md={5}>
            <StyledTextBlock>
              <CustomBg>Burn</CustomBg>

              <div style={{ color: '#022B69', lineHeight: '24px', padding: '15px 0' }}>
                <p style={{ fontSize: '16px' }}>
                  Benefit from a decrease in token supply when we use <br/>
                  25% of the revenue from the PECULIUM Ecosystem <br/>
                  burn PCL tokens, creating scarcity and value for the long term.
                </p>
              </div>
              <a
                href="https://help.peculium.io/knowledge/details/16/what-is-the-burning-mechanism-.html"
                target="_blank"
                style={{ color: '#022B69', fontWeight: 'bold' }}
              >
                Learn more →
              </a>
            </StyledTextBlock>
          </StyledSubGrid>
        </StyledGrid>

        <StyledGrid container spacing={2}>
          <StyledSubGrid item md={6}>
            <StyledTextBlock>
              <CustomBg>Staking</CustomBg>

              <div style={{ color: '#022B69', lineHeight: '24px', padding: '15px 0' }}>
                <p style={{ fontSize: '16px' }}>
                  By staking PCL, users lock their tokens for a pre-defined period <br/>
                  reducing the avilable supply and increasing scarcity <br/>
                  while enjoying Premium benefits.
                </p>
              </div>
              <div style={{ display: 'flex', gap: '20px', flexDirection: 'row' }}>
                <Button
                  variant="outlined"
                  style={{
                    padding: '10px',
                    color: '#022B69',
                    fontWeight: 'bold',
                    border: 'solid 2px #022B69',
                    height: '50px',
                    width: 'fit-content',
                  }}
                >
                  Stake
                </Button>
                <a
                  href="https://peculium.medium.com/how-to-participate-in-the-pcl-token-liquidity-mining-campaign-on-pancakeswap-bfc5fa6f433d"
                  target="_blank"
                  style={{
                    color: '#022B69',
                    fontWeight: 'bold',
                    alignSelf: 'center',
                  }}
                >
                  Learn more →
                </a>
              </div>
            </StyledTextBlock>
          </StyledSubGrid>
          <StyledSubGrid item md={6} >
            <img src="./images/pcltoken/Staking.svg" />
            <Glassbg
              style={{
                position: 'absolute',
                bottom: '60%',
                left: '10%',
                width: '180px',
                backdropFilter: 'blur(8px)',
                fontSize: '17px',
              }}
            >
              <img src="./images/pcltoken/Stakei.svg" />
              PCL Staked
              <b style={{ fontSize: '30px' }}>8,60M</b>
            </Glassbg>
            <Glassbg
              style={{
                position: 'absolute',
                top: '85%',
                right: '0%',
                backdropFilter: 'blur(8px)',
                fontSize: '17px',
              }}
            >
              <img src="./images/metrics/IconPCL.svg" />
              <b style={{ fontSize: '30px' }}>100% APY</b>
            </Glassbg>
          </StyledSubGrid>
        </StyledGrid>
      </Container>
    </Section>
  );
}

const Section = styled.section`
  padding: 80px 0;
  background-image: url('./images/pcltoken/eclipse.svg');
  background-position: left center;
  background-repeat: no-repeat;
`;

const StyledGrid = styled(Grid)`
  position: relative;
  margin-bottom: 30px
`;
const StyledSubGrid = styled(Grid)`
  position: relative;
`;

const StyledTextBlock = styled.div`
  @media (min-width: 900px) {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%)
  }
`;

const Glassbg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #022b69;
  padding: 25px;
  font-size: 16px;
  background: rgba(255, 255, 255, 0.06);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.6px);
  -webkit-backdrop-filter: blur(4.6px);
  border: 1px solid rgba(255, 255, 255, 1);
`;

export const CustomBg = styled(WithBackground)`
  border-radius: 4px;
  padding: 6px 8px;
  width: fit-content;
`;

const Staked = styled.img`
  position: relative;
  bottom: 80%;
  left: 13%;
  width: 40%;
`;

const APY = styled.img`
  position: relative;
  width: 40%;
  bottom: 40%;
  backdrop-filter: blur(4px);
  left: 70%;
  margin-bottom: -30%;
`;

export default SubMain;
