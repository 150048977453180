import {
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const GenerateBurn = (d: string, tx: string, a: string) => {
  return {
    date: d,
    transaction: tx,
    amount: a,
  };
};

function Burn() {
  const rows = [
    GenerateBurn(
      '04/29/2022',
      '0x84ecc874390357731623d5189be17b6e13ba4d3ec7d8e77082dfb0550da65d59',
      '8 843'
    ),
    GenerateBurn(
      '02/01/2022',
      '0x4e51e31c23a02f38d440bb724f0054f7d50adad68852c0f400ba0fc9135b4015',
      '31 290'
    ),
    GenerateBurn(
      '12/20/2021',
      '0xd33e0348b18ae6e911e2e38659f7f40c2b8d876d1d10d4fef72201093f8cce23',
      '15 000'
    ),
    GenerateBurn(
      '11/10/2021',
      '0x615729816ba2dab470d6112a7f6dd17be42e25d6fce0963a0c6495b07b628b1c',
      '218 000'
    ),
    GenerateBurn(
      '09/06/2021',
      '0x55c819c67a08ccb6e298b51c71554958a4dcb1d6d36018b03c8c4e9e68810b1b',
      '588 071'
    ),
    GenerateBurn(
      '08/04/2020',
      '0x02984810b080ad6fdbcdb897949f7fb4cefbb5c14ddedda6823107b231514782',
      '230 200'
    ),
    GenerateBurn(
      '12/01/2020',
      '0xed596f1cf4b5a82f1cd5db7b45e8d7df43c813e72e24c6485b748f523d44e570',
      '640 000'
    ),
    GenerateBurn(
      '10/09/2019',
      '0xe1eced7c56094a5e8e0c4ec3f12f687d9e85e4c2fb9607264c3ec5444268ab34',
      '957 047'
    ),
  ];
  return (
    <Container>
      <StyledGlassContainer style={{ zIndex: '2' }}>
        <h1 style={{ color: '#022B69' }}>PCL Burn Schedule</h1>
        <p style={{ color: '#7D91AE' }}>
          25% of the revenues generated from fees will be burned on a monthly
          basis.
        </p>
        <img
          style={{
            position: 'relative',
            left: '80%',
            overflow: 'visible',
            zIndex: 4,
            transform: 'translateY(-300px)',
          }}
          src="./images/metrics/Elements.svg"
        />
        <ResponsiveTable aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Date</b>
              </TableCell>
              <TableCell>
                <b>Transaction</b>
              </TableCell>
              <TableCell align="right">
                <b>Amount</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow key={row.transaction}>
                <TableCell>
                  {window.screen.width <= 800 ? row.date : row.date}
                </TableCell>
                <TableCell>
                  <a
                    style={{ color: 'blue', borderBottom: 'solid 1px blue' }}
                    href={
                      i <= 2
                        ? `https://bscscan.com/tx/${row.transaction}`
                        : `https://etherscan.io/tx/${row.transaction}`
                    }
                    target="_blank"
                  >
                    {window.screen.width <= 800
                      ? row.transaction.slice(1, 15) + '...'
                      : row.transaction}
                  </a>
                </TableCell>
                <TableCell style={{ textAlign: 'right' }}>
                  {row.amount}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </ResponsiveTable>
      </StyledGlassContainer>
    </Container>
  );
}

const Container = styled.div`
  background-image: url('./images/metrics/BGelm.png');
  background-repeat: no-repeat;
  margin-top: 75px;
  background-position: -17% 40%;
  height: 800px;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 200px;
  padding-bottom: 400px;
  @media (max-width: 800px) {
    padding: 5px;
    overflow-x: scroll;
    margin-top: 80px;
  }
`;

const StyledGlassContainer = styled.div`
  /* From https://css.glass */
  background: rgba(195, 193, 193, 0.2);
  border-radius: 16px;
  padding: 75px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(195, 193, 193, 0.3);
  @media (max-width: 800px) {
    padding: 15px;
    width: fit-content;
  }
`;

const ResponsiveTable = styled(Table)`
  margin-top: -30%;
  @media (max-width: 800px) {
    margin-top: -80%;
  }
`;

export default Burn;
