import React from 'react';
import styled from 'styled-components';
import { Container as CTR, Box } from '@mui/material';
function SubMain() {
  return (
    <div>
      <CTR fixed maxWidth={'lg'}>
        <Container>
          <img
            style={{ width: '600px', height: '600px' }}
            src="./images/community/ima.svg"
          />
          <Col>
            <h1 style={{ color: '#022B69' }}>Peculium</h1>
            <div style={{ display: 'flex', gap: '4px', flexDirection: 'row' }}>
              <h1
                style={{
                  color: 'white',
                  padding: '0px 10px 5px 10px',
                  backgroundColor: '#0220BF',
                  borderRadius: '5px',
                }}
              >
                Angels
              </h1>{' '}
              <h1 style={{ color: '#022B69' }}>Program</h1>
            </div>
            <p style={{ color: '#7D91AE' }}>
              The PECULIUM Angels program grants loyal PCL holders
              <br /> the title of “Angel” along with exclusive perks. How to
              become
              <br />a PECULIUM Angel.
            </p>
            <p
              style={{
                color: '#7D91AE',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <img src="./images/about/Check.svg" />
              Holding PCL
            </p>
            <p
              style={{
                color: '#7D91AE',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <img src="./images/about/Check.svg" />
              Have a passion for PECULIUM
            </p>
            <p
              style={{
                color: '#7D91AE',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <img src="./images/about/Check.svg" />
              Willing to contribute to the PECULIUM ecosystem
            </p>
            <button
              style={{
                background:
                  'linear-gradient(304.2deg, #0420B6 -1.51%, #304CE3 100%)',
                width: 'fit-content',
                border: 'none',
                borderRadius: '5px',
                fontWeight: '600',
                color: 'white',
                padding: '14px 10px 14px 10px',
              }}
            >
              Apply now
            </button>
          </Col>
        </Container>
      </CTR>
      <div
        style={{
          width: '100vw',
          height: 'fit-content',
          backgroundColor: '#304CE3',
          marginTop: '30px',
          marginBottom: '30px',
          paddingTop: '30px',
          paddingBottom: '30px',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '4px',
            color: 'white',
            flexDirection: 'row',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          <h1>Featured PECULIUM</h1>
          <h1
            style={{
              color: 'white',
              padding: '0px 10px 5px 10px',
              backgroundColor: '#0220BF',
              borderRadius: '2px',
            }}
          >
            Angels
          </h1>{' '}
        </div>
        <div
          style={{
            display: 'flex',
            padding: '60px',
            gap: '10px',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
          }}
        >
          <img src="./images/community/c1.svg" />
          <img src="./images/community/c2.svg" />
          <img src="./images/community/c3.svg" />
        </div>
      </div>
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h1 style={{ color: '#022B69' }}>Join PECULIUM Family</h1>
        <p style={{ color: '#7D91AE' }}>We look forward to welcoming yu</p>
        <p style={{ color: '#7D91AE' }}>to our family as a true partner</p>
        <div
          style={{
            width: '70%',
            height: '100%',
            backgroundImage: "url('./images/community/bg1.png')",
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            backgroundPosition: '100%',
            flexDirection: 'row',
            paddingTop: '30px',
            paddingBottom: '30px',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              backgroundImage: "url('./images/community/bg2.svg')",
              backgroundRepeat: 'no-repeat',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundSize: 'contain',
              backgroundPosition: '50% 100%',
              width: '100%',
            }}
          >
            <iframe
              src="https://www.youtube.com/embed/AyOKv55xJe4"
              style={{ width: '50%', height: '50%' }}
            />

            <img
              src="./images/community/sred.svg"
              style={{ position: 'absolute', left: '0px' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const Container = styled.div`
  width: 100vw;
  height: fit-content;
  background-image: url('./images/community/bge.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 80px;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
`;

export default SubMain;
