import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Container } from '@mui/material';
import Accordin from '../../components/Accordin';

const FAQs: React.FC = () => {
  const [activeItem, setActive] = useState(-1);
  return (
    <StyledContainer>
      <Container fixed maxWidth={'lg'}>
        <WrapperBox>
          <Box>
            <svg
              width="90"
              height="90"
              viewBox="0 0 90 90"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.3">
                <ellipse
                  cx="57.8525"
                  cy="5.625"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 57.8525 5.625)"
                  fill="#022B69"
                />
                <ellipse
                  cx="57.8525"
                  cy="31.875"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 57.8525 31.875)"
                  fill="#022B69"
                />
                <ellipse
                  cx="57.8525"
                  cy="58.125"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 57.8525 58.125)"
                  fill="#022B69"
                />
                <ellipse
                  cx="57.8525"
                  cy="84.375"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 57.8525 84.375)"
                  fill="#022B69"
                />
                <ellipse
                  cx="84.4013"
                  cy="5.625"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 84.4013 5.625)"
                  fill="#022B69"
                />
                <ellipse
                  cx="84.4013"
                  cy="31.875"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 84.4013 31.875)"
                  fill="#022B69"
                />
                <ellipse
                  cx="84.4013"
                  cy="58.125"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 84.4013 58.125)"
                  fill="#022B69"
                />
                <ellipse
                  cx="84.4013"
                  cy="84.375"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 84.4013 84.375)"
                  fill="#022B69"
                />
                <ellipse
                  cx="31.7256"
                  cy="5.625"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 31.7256 5.625)"
                  fill="#022B69"
                />
                <ellipse
                  cx="31.7256"
                  cy="31.875"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 31.7256 31.875)"
                  fill="#022B69"
                />
                <ellipse
                  cx="31.7256"
                  cy="58.125"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 31.7256 58.125)"
                  fill="#022B69"
                />
                <ellipse
                  cx="31.7256"
                  cy="84.375"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 31.7256 84.375)"
                  fill="#022B69"
                />
                <ellipse
                  cx="5.5986"
                  cy="5.625"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 5.5986 5.625)"
                  fill="#022B69"
                />
                <ellipse
                  cx="5.5986"
                  cy="31.875"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 5.5986 31.875)"
                  fill="#022B69"
                />
                <ellipse
                  cx="5.5986"
                  cy="58.125"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 5.5986 58.125)"
                  fill="#022B69"
                />
                <ellipse
                  cx="5.5986"
                  cy="84.375"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 5.5986 84.375)"
                  fill="#022B69"
                />
              </g>
            </svg>
          </Box>
          <Box position={'absolute'} left={'0px'} bottom={'0px'}>
            <svg
              width="90"
              height="90"
              viewBox="0 0 90 90"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.3">
                <ellipse
                  cx="57.8525"
                  cy="5.625"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 57.8525 5.625)"
                  fill="#022B69"
                />
                <ellipse
                  cx="57.8525"
                  cy="31.875"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 57.8525 31.875)"
                  fill="#022B69"
                />
                <ellipse
                  cx="57.8525"
                  cy="58.125"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 57.8525 58.125)"
                  fill="#022B69"
                />
                <ellipse
                  cx="57.8525"
                  cy="84.375"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 57.8525 84.375)"
                  fill="#022B69"
                />
                <ellipse
                  cx="84.4013"
                  cy="5.625"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 84.4013 5.625)"
                  fill="#022B69"
                />
                <ellipse
                  cx="84.4013"
                  cy="31.875"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 84.4013 31.875)"
                  fill="#022B69"
                />
                <ellipse
                  cx="84.4013"
                  cy="58.125"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 84.4013 58.125)"
                  fill="#022B69"
                />
                <ellipse
                  cx="84.4013"
                  cy="84.375"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 84.4013 84.375)"
                  fill="#022B69"
                />
                <ellipse
                  cx="31.7256"
                  cy="5.625"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 31.7256 5.625)"
                  fill="#022B69"
                />
                <ellipse
                  cx="31.7256"
                  cy="31.875"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 31.7256 31.875)"
                  fill="#022B69"
                />
                <ellipse
                  cx="31.7256"
                  cy="58.125"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 31.7256 58.125)"
                  fill="#022B69"
                />
                <ellipse
                  cx="31.7256"
                  cy="84.375"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 31.7256 84.375)"
                  fill="#022B69"
                />
                <ellipse
                  cx="5.5986"
                  cy="5.625"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 5.5986 5.625)"
                  fill="#022B69"
                />
                <ellipse
                  cx="5.5986"
                  cy="31.875"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 5.5986 31.875)"
                  fill="#022B69"
                />
                <ellipse
                  cx="5.5986"
                  cy="58.125"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 5.5986 58.125)"
                  fill="#022B69"
                />
                <ellipse
                  cx="5.5986"
                  cy="84.375"
                  rx="5.625"
                  ry="5.59867"
                  transform="rotate(90 5.5986 84.375)"
                  fill="#022B69"
                />
              </g>
            </svg>
          </Box>
          <Title>FAQs</Title>
          <ItemGroup>
            <Accordin
              title={'What are SAIEVE Baskets ?'}
              active={activeItem === 1}
              closeState={() => setActive(-1)}
              changeState={() => setActive(1)}
            >
              {
                'Baskets are modern investment products that help you build a low-cost, long-term & diversified portfolio easily. Created by professionals, each basket is a ready-made portfolio of cryptos that reflects a theme, strategy or objective.'
              }
            </Accordin>
            <Accordin
              title={'Which cryptocurrencies are supported ?'}
              active={activeItem === 2}
              closeState={() => setActive(-1)}
              changeState={() => setActive(2)}
            >
              {
                'We currently support over 130 cryptocurrencies via Binance and Huobi.'
              }
            </Accordin>
            <Accordin
              title={'Who manages the baskets ?'}
              active={activeItem === 3}
              closeState={() => setActive(-1)}
              changeState={() => setActive(3)}
            >
              {
                'Baskets are created & managed by expert professionals who eat, sleep & breathe the crypto market. Depending on the type of basket it can be managed by PECULIUM or third parties(Hedge funds, Influencers, successful traders etc ). The constituents in every basket pass stringent proprietary filters so that you don’t have to worry about making individual choices.'
              }
            </Accordin>
            <Accordin
              title={'What is Rebalancing ?'}
              active={activeItem === 4}
              closeState={() => setActive(-1)}
              changeState={() => setActive(4)}
            >
              {
                'Rebalancing is the process of reviewing the underlying crypto of a Basket to ensure that it continues to reflect the underlying original idea. It takes into account fundamental factors, token updates, etc. to objectively narrow down on the right crypto & their weightage. Rebalancing is done by the research team/creator of the Basket.'
              }
            </Accordin>
            <Accordin
              title={'Can I see my past performance?'}
              active={activeItem === 5}
              closeState={() => setActive(-1)}
              changeState={() => setActive(5)}
            >
              {
                'All orders placed for basket in the individual batches (buy, invest more, manage, rebalance, exit) can be found on the Dashboard.'
              }
            </Accordin>
          </ItemGroup>
        </WrapperBox>
      </Container>
    </StyledContainer>
  );
};

const WrapperBox = styled(Box)`
  position: relative;
`;

const Title = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 150%;
  color: #022b69;
  display: flex;
  justify-content: center;
  margin-bottom: 45px;
`;

const ItemGroup = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 170px;
`;

const StyledContainer = styled(Box)`
  margin-top: 135px;
`;

export default FAQs;
