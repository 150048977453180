import React, { useEffect, useRef } from 'react';
import 'chart.js/auto';

import styled from 'styled-components';
import { Doughnut } from 'react-chartjs-2';
import { Container } from '@mui/material';

export default function () {
  const chart: any = useRef(null);

  const data = {
    labels: ['Circulating supply', 'Membership', 'Staked', 'Burned'],
    datasets: [
      {
        label: 'PCL Token Distribution',
        data: [134506415, 77396315, 8599590, 2888441],

        backgroundColor: [
          'rgba(14, 29, 184, 1)',
          'rgba(144, 113, 235, 1)',
          'rgba(62, 144, 255, 1)',
          '#000',
        ],
        hoverOffset: 10,
      },
    ],
  };
  useEffect(() => {
    console.log(chart.current!.setActiveElements);
  }, [chart]);

  function toggleLabelOn(idx: any) {
    //Set active element
    chart.current.setActiveElements([
      {
        datasetIndex: 0,
        index: idx,
      },
    ]);

    //Set active tooltip
    chart.current.tooltip.setActiveElements([
      {
        datasetIndex: 0,
        index: idx,
      },
    ]);

    chart.current.update();
  }

  //Clear graph active state (not used in this example but useful to have in conjunction with toggleLabelOn())
  function toggleLabelOff(idx: any) {
    chart.current.setActiveElements([]);
    chart.current.tooltip.setActiveElements([]);
    chart.current.update();
  }
  return (
    <div>
      <Container maxWidth={'lg'}>
        <StyledHeading>PCL Circulating supply distribution</StyledHeading>
        <CharContainer>
          <StyledContainer>
            <Circulation
              onMouseEnter={() => toggleLabelOn(0)}
              onMouseLeave={() => toggleLabelOff(0)}
            >
              <div style={{ display: 'flex', gap: '2px' }}>
                <StyledIco src="./images/metrics/IconRem.svg" />
                <p style={{ alignSelf: 'center' }}>Remaining Circ. Supply</p>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignSelf: 'center',
                }}
              >
                <StyledSupp>134 506 415</StyledSupp>
              </div>
            </Circulation>

            <Divider />

            <Circulation
              onMouseEnter={() => toggleLabelOn(1)}
              onMouseLeave={() => toggleLabelOff(1)}
            >
              <div style={{ display: 'flex', gap: '2px' }}>
                <StyledIco src="./images/metrics/IconMem.svg" />

                <p style={{ alignSelf: 'center' }}>PCL held in Membership</p>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignSelf: 'flex-end',
                }}
              >
                <StyledSupp>77 396 315</StyledSupp>

                <p
                  style={{
                    fontSize: '11px',
                    color: '#304CE3',
                    marginTop: '1px',
                    marginLeft: '0px',
                    textAlign: 'right'
                  }}
                >
                  (57,41% of Circ.Supply)
                </p>
              </div>
            </Circulation>

            <Divider />

            <Circulation
              onMouseEnter={() => toggleLabelOn(2)}
              onMouseLeave={() => toggleLabelOff(2)}
            >
              <div style={{ display: 'flex', gap: '2px' }}>
                <StyledIco src="./images/metrics/IconPCL.svg" />
                <p style={{ alignSelf: 'center' }}>PCL staked</p>
              </div>

              <div
                style={{
                  display: 'flex',
                  alignSelf: 'flex-end',
                  flexDirection: 'column',
                }}
              >
                <StyledSupp>8 599 590</StyledSupp>
                <p
                  style={{
                    fontSize: '12px',
                    color: '#304CE3',
                    marginTop: '1px',
                    marginLeft: '0px',
                    textAlign: 'right'
                  }}
                >
                  (6.39% of Circulating supply)
                </p>
              </div>
            </Circulation>

            <Divider />

            <Circulation
              onMouseEnter={() => toggleLabelOn(3)}
              onMouseLeave={() => toggleLabelOff(3)}
            >
              <div style={{ display: 'flex', gap: '2px' }}>
                <StyledIco src="./images/metrics/IconBurn.svg" />
                <p style={{ alignSelf: 'center' }}>Circulating supply burned</p>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignSelf: 'center',
                  flexDirection: 'column',
                }}
              >
                <StyledSupp>2 888 441</StyledSupp>
              </div>
            </Circulation>

            <Divider />
          </StyledContainer>
          <ChartPCL>
            <StyledChart data={data} options={{ cutout: '90%' }} ref={chart} />
          </ChartPCL>
        </CharContainer>
      </Container>
    </div>
  );
}

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
  margin-top: 45px;
  flex: 1;
`;

const StyledChart = styled(Doughnut)`
  background-image: url('./images/coins/CoinPCL.svg');
  background-position: 50% 380%;
  background-size: 96%;
  background-repeat: no-repeat;
  @media (max-width: 480px) {
    background-position: 50% 450%;
  }
`;

const StyledIco = styled.img`
  margin-right: 5px;
  width: 31px;
  height: 51px;
`;

const ChartPCL = styled.div`
  width: 40%;
  height: 70%;
  @media (max-width: 1215px) {
    width: 90%;
    height: 90%;
  }
`;

const StyledHeading = styled.h1`
  color: #022b69;
  padding-bottom: 25px;
`;
const StyledSupp = styled.p`
  font-weight: bold;
  align-self: flex-end;
  color: #0420b6;
`;

const Circulation = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  width: 70%;
  :hover {
    transform: translateY(-10px);
  }
  transition: 0.4s;
  justify-content: space-between;
  @media (max-width: 1215px) {
    width: 100%;
  }
`;

const CharContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: 1215px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const Divider = styled.hr`
  border: 0;
  display: block;
  margin-left: 0px;
  margin-right: 0px;
  width: 70%;
  float: left;
  background: rgba(125, 145, 174, 1);
  height: 1px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;
