import React from 'react';
import styled from 'styled-components';
import { Container as CTR, Box } from '@mui/material';

function SubHeader() {
  return (
    <div
      style={{
        backgroundImage: "url('./images/about/BGphilo.png')",
        backgroundRepeat: 'no-repeat',
      }}
    >
      <CTR fixed maxWidth={'lg'}>
        <Container>
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={2}
            width={{ xs: '100%', md: '40%' }}
          >
            <h1 style={{ color: '#FFF' }}>Investment</h1>
            <Heading>Philosophy</Heading>
            <p style={{ color: 'white', lineHeight: '26px', fontSize: '18px' }}>
              With the SAIEVE platform at its core, PECULIUM offers diversified
              portfolios—curated and managed by our leading-edge technology—that
              cover the spectrum of available digital assets. It’s crypto on
              autopilot, automatically rebalancing baskets and shifting to the
              currencies that our sophisticated algorithms recommend.
              <br />
              <br />
              PECULIUM provides an opportunity for anyone to invest in digital
              assets. For as little as $200, you can invest in crypto in less
              than five minutes. There’s no prior knowledge required, either,
              since our education-focused approach takes the inscrutability out
              of crypto. We want you to understand exactly what you’re investing
              in and why. Because then you’ll also understand exactly why we are
              the company to guide you.
            </p>
          </Box>
          <div style={{ position: 'relative' }}>
            <img
              style={{
                alignSelf: 'flex-start',
                justifySelf: 'flex-end',
                marginBottom: '50px',
              }}
              src="./images/about/Philo.svg"
            />
            <img
              style={{ position: 'absolute', top: '100px', left: '100px' }}
              src="./images/about/eclipse2.png"
            />
            <img
              style={{ position: 'absolute', top: '100px', left: '-420px' }}
              src="./images/about/blue.png"
            />
          </div>
        </Container>
      </CTR>
    </div>
  );
}

const Container = styled.div`
  display: flex;
  width: 100vw;
  flex-direction: row;
  background-size: 65%;
  background-repeat: no-repeat;
  justify-content: flex-start;
  height: fit-content;
  gap: 135px;
  padding-top: 125px;
  padding-left: 25px;
  font-size: 18px;
  background-size: 100% 100%;
  margin-bottom: 20px;
  @media (max-width: 1215px) {
    flex-direction: column;
    background-size: 220%;
    gap: 0px;
    padding: 20px;
    padding-top: 50px;
    height: fit-content;
    align-items: center;
    background-size: 100% 100%;
  }
`;

const Heading = styled.h1`
  color: #fff;
  background-color: #0220bf;
  width: fit-content;
  padding: 4px;
  margin-top: -12px;
  border-radius: 3px;
`;

export default SubHeader;
