import React from 'react';
import styled from 'styled-components';

function Supply() {
  return (
    <Container>
      <Supp>
        <StyledH1>220,502,320</StyledH1>
        <Styleddesc>Total Token Supply</Styleddesc>
      </Supp>
      <Supp>
        <StyledH1>134,506,415</StyledH1>
        <Styleddesc>Circulating Supply (61% of Total Supply)</Styleddesc>
      </Supp>
      <Supp>
        <StyledH1>85,995,905</StyledH1>
        <Styleddesc>Locked Supply (39% of Total Supply)</Styleddesc>
      </Supp>
    </Container>
  );
}

const Container = styled.div`
  background: linear-gradient(304.2deg, #0420b6 -1.51%, #304ce3 100%);
  display: flex;
  padding: 60px 60px;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: -100px;
  margin-bottom: 100px;
  @media (max-width: 800px) {
    padding: 10px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
`;

const StyledH1 = styled.h1`
  align-self: center;
`;

const Styleddesc = styled.p`
  text-align: center;
  margin-top: 0px;
`;

const Supp = styled.div`
  display: flex;
  color: white;
  display: flex;
  flex-direction: column;
`;

export default Supply;
