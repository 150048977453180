import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';

const Volatility: React.FC = () => {
  return (
    <StyledContainer>
      <Box position={'relative'}>
        <img src="./images/LaptopGirl.png" alt="laptopgirl" />
        <EarnChart>
          <img src="./images/earnChart.png" alt="EarnChart" />
          <EarnValue>$389,58</EarnValue>
          <EarnCom>Earned today</EarnCom>
        </EarnChart>
      </Box>
      <Box>
        <TitleBox>Beat Volatility & Earn Without the Risk</TitleBox>
        <ComBox>
          The SAIΞVE Smart holding strategy takes the complexity out of
          investing in Bitcoin and the top altcoins. It aims to deliver
          additional yield by utilizing hedging mechanisms and quantitative
          data, offering both downside protection and management of coins price
          volatility.{' '}
        </ComBox>
        <br />
        <ComBox>
          Our Smart holding strategy is suitable for medium-term or long-term
          investors.
        </ComBox>
      </Box>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  margin-top: 150px;
  padding: 0px;
  display: flex;
  column-gap: 110px;
  gap: 140px;

  @media (max-width: 1215px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const EarnChart = styled(Box)`
  position: absolute;
  left: 372px;
  bottom: 42px;
  width: 129px;
  height: 146px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 54.3813px rgba(0, 23, 143, 0.2);
  backdrop-filter: blur(8px);
  border-radius: 10px;
  /* padding: 24px 16px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const EarnValue = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  text-align: center;
  color: #022b69;
`;

const EarnCom = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #7d91ae;
`;

const TitleBox = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 150%;
  color: #022b69;
`;

const ComBox = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #7d91ae;
`;

export default Volatility;
