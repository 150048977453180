import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export default function TokenMenu() {
  return (
    <Container>
      <Items>
        <Item>
          <img src="./images/about/i1.svg" />
          <div style={{ marginLeft: '15px' }}>
            <p style={{ color: '#022B69', marginBottom: '0px', fontSize: '16px', lineHeight: '24px' }}>
              <Link to="/about">Our story</Link>
            </p>
            <p style={{ marginTop: '0px', color: '#7D91AE', fontSize: '13px', lineHeight: '16px' }}>Who we are</p>
          </div>
        </Item>

        <Item>
          <img src="./images/about/i2.svg" />
          <div style={{ marginLeft: '15px' }}>
            <p style={{ color: '#022B69', marginBottom: '0px', fontSize: '16px', lineHeight: '24px' }}>
              <Link to="/team">Leadership</Link>
            </p>
            <p style={{ marginTop: '0px', color: '#7D91AE', fontSize: '13px', lineHeight: '16px' }}>Meet the team</p>
          </div>
        </Item>
      </Items>
      <div
        style={{
          width: '2px',
          alignSelf: 'center',
          height: '90%',
          background: '#022B69',
          opacity: '.1',
        }}
      ></div>
      <Items>
        <Item>
          <img src="./images/about/i3.svg" />
          <div style={{ marginLeft: '15px' }}>
            <p style={{ color: '#022B69', marginBottom: '0px', fontSize: '16px', lineHeight: '24px' }}>
              <Link to="/philosophy">Investment philosophy</Link>
            </p>
            <p style={{ marginTop: '0px', color: '#7D91AE', fontSize: '13px', lineHeight: '16px' }}>
              Six principles approach
            </p>
          </div>
        </Item>

        <Item>
          <img src="./images/about/i4.svg" />
          <div style={{ marginLeft: '15px' }}>
            <p style={{ color: '#022B69', marginBottom: '0px', fontSize: '16px', lineHeight: '24px' }}>
              <Link to="/careers">Join us</Link>
            </p>
            <p style={{ marginTop: '0px', color: '#7D91AE', fontSize: '13px', lineHeight: '16px' }}>Work with us</p>
          </div>
        </Item>
      </Items>
      <div
        style={{
          width: '2px',
          alignSelf: 'center',
          height: '90%',
          background: '#022B69',
          opacity: '.1',
        }}
      ></div>
      <Items>
        <Item>
          <img src="./images/about/i5.svg" />
          <div style={{ marginLeft: '15px' }}>
            <p style={{ color: '#022B69', marginBottom: '0px', fontSize: '16px', lineHeight: '24px' }}>
              <Link to="/technology">Our Technology</Link>
            </p>
            <p style={{ marginTop: '0px', color: '#7D91AE', fontSize: '13px', lineHeight: '16px' }}>
              Tier-1 investment banking
            </p>
          </div>
        </Item>
      </Items>
      {/**
       * <Item>
          <img src="./images/about/i6.svg" />
          <div style={{ marginLeft: '15px' }}>
            <p style={{ color: '#022B69', marginBottom: '0px' }}>Partners</p>
            <p style={{ marginTop: '0px', color: '#7D91AE' }}>Build together</p>
          </div>
        </Item>
        <div
        style={{
          width: '2px',
          alignSelf: 'center',
          height: '90%',
          background: '#022B69',
          opacity: '.1',
        }}
      ></div>
      <Items>
        <Item>
          <img src="./images/about/i7.svg" />
          <div style={{ marginLeft: '15px' }}>
            <p style={{ color: '#022B69', marginBottom: '0px' }}>
              Media Center
            </p>
            <p style={{ marginTop: '0px', color: '#7D91AE' }}>
              See what others say about PECULIUM
            </p>
          </div>
        </Item>
      </Items>
       */}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: space-around;
  width: 100%;
  padding: 25px;

  border-radius: 4px;
  box-shadow: -2px 7px 13px -3px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: -2px 7px 13px -3px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: -2px 7px 13px -3px rgba(0, 0, 0, 0.7);
  p {
    font-size: 18px;
  }
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  img {
    margin-top: -10px
  }
`;
