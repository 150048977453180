import React from 'react';
import styled from 'styled-components';
import { Box, Container } from '@mui/material';

import IntroPart from './IntroPart';
import DiagramPart from './DiagramPart';
import Diversification from './Diversification';
import SmartHolding from './SmartHolding';
import Volatility from './Volatility';
import GetStarted from './GetStarted';

/* <Gradient1 />
      <Gradient2 />
      <Gradient3 />
      <Gradient4 />
      <Gradient5 />
      <Gradient6 />
      <Gradient7 />
      <Gradient8 /> */

const Products: React.FC = () => {
  return (
    <>
      <IntroPart />
      <Section>
        <Container fixed maxWidth={'lg'}>
          <Box display={'flex'} justifyContent={'center'} marginTop={'135px'}>
            <DisCoverBox>
            <span>
              Discover <b>SAIΞVE Earn</b> product suite.
            </span>
              <span>A new way to Earn Yields on your Idle Crypto Assets.</span>
            </DisCoverBox>
          </Box>

          <DiagramPart/>
          <Diversification />
          <SmartHolding />
          <Volatility />
          <GetStarted />
        </Container>
      </Section>

    </>
  );
};

const Section = styled.div`
  background-image: url("./images/bg_earn.svg");
  background-size: cover;
`;

const DisCoverBox = styled(Box)`
  /* position: absolute;
  left: 30%;
  bottom: -150px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  color: #7d91ae;
  /* grid-row-gap: 15px; */
  background: url('./images/assetBox.png') #e4efff;
  background-size: cover;
  border-radius: 10px;
  padding: 40px 105px;
  /* transform: translate(-20%, -50%); */
  @media(max-width: 480px) {
    font-size: 20px;
    line-height: 30px;
    padding: 40px ;
  }
`;


export default Products;
