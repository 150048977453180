import React from 'react';
import styled from 'styled-components';
import { Box, Container } from '@mui/material';
import './carousel.scss';

const slideWidth = 30;

const _items = [
  {
    player: {
      title: 'Efren Reyes',
      desc: 'Known as "The Magician", Efren Reyes is well regarded by many professionals as the greatest all around player of all time.',
      image: 'images/home/Img 1.png',
    },
  },
  {
    player: {
      title: "Ronnie O'Sullivan",
      desc: "Ronald Antonio O'Sullivan is a six-time world champion and is the most successful player in the history of snooker.",
      image: 'images/home/Img 2.png',
    },
  },
  {
    player: {
      title: 'Shane Van Boening',
      desc: 'The "South Dakota Kid" is hearing-impaired and uses a hearing aid, but it has not limited his ability.',
      image: 'images/home/Img 3.png',
    },
  },
  {
    player: {
      title: 'Mike Sigel',
      desc: 'Mike Sigel or "Captain Hook" as many like to call him is an American professional pool player with over 108 tournament wins.',
      image: 'images/home/Img 4.png',
    },
  },
  {
    player: {
      title: 'Willie Mosconi',
      desc: 'Nicknamed "Mr. Pocket Billiards," Willie Mosconi was among the first Billiard Congress of America Hall of Fame inductees.',
      image: 'images/home/Img 3.png',
    },
  },
];

const length = _items.length;
_items.push(..._items);

const sleep = (ms = 0) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const createItem = (position: any, idx: any) => {
  const item = {
    styles: {
      transform: `translateX(${position * slideWidth}rem)`,
      filter: '',
      opacity: 1,
    },
    player: _items[idx].player,
  };

  switch (position) {
    case length - 1:
    case length + 1:
      item.styles = { ...item.styles, filter: 'grayscale(1)' };
      break;
    case length:
      break;
    default:
      item.styles = { ...item.styles, opacity: 0 };
      break;
  }

  return item;
};

const CarouselSlideItem = ({ pos, idx, activeIdx }: any) => {
  const item = createItem(pos, idx);

  return (
    <li className="carousel__slide-item" style={item.styles}>
      <div className="carousel__slide-item-img-link">
        <img src={item.player.image} alt={item.player.title} />
      </div>
      {/* <div className="carousel-slide-item__body">
                <h4>{item.player.title}</h4>
                <p>{item.player.desc}</p>
            </div> */}
    </li>
  );
};

const keys = Array.from(Array(_items.length).keys());

const Carousel = () => {
  const [items, setItems] = React.useState(keys);
  const [isTicking, setIsTicking] = React.useState(false);
  const [activeIdx, setActiveIdx] = React.useState(0);
  const bigLength = items.length;

  const prevClick = (jump = 1) => {
    if (!isTicking) {
      setIsTicking(true);
      setItems((prev) => {
        return prev.map((_, i) => prev[(i + jump) % bigLength]);
      });
    }
  };

  const nextClick = (jump = 1) => {
    if (!isTicking) {
      setIsTicking(true);
      setItems((prev) => {
        return prev.map((_, i) => prev[(i - jump + bigLength) % bigLength]);
      });
    }
  };

  const handleDotClick = (idx: number) => {
    if (idx < activeIdx) prevClick(activeIdx - idx);
    if (idx > activeIdx) nextClick(idx - activeIdx);
  };

  React.useEffect(() => {
    if (isTicking) sleep(300).then(() => setIsTicking(false));
  }, [isTicking]);

  React.useEffect(() => {
    setActiveIdx((length - (items[0] % length)) % length) // prettier-ignore
  }, [items]);

  return (
    <div className="carousel__wrap" style={{ marginTop: '10px' }}>
      <div className="carousel__inner">
        <div className="carousel__container">
          <ul className="carousel__slide-list">
            {items.map((pos, i) => (
              <CarouselSlideItem
                key={i}
                idx={i}
                pos={pos}
                activeIdx={activeIdx}
              />
            ))}
          </ul>
        </div>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          flexWrap={'wrap'}
          alignItems={'center'}
          style={{ marginTop: '-140px' }}
        >
          <div className="carousel__dots">
            {items.slice(0, length).map((pos, i) => (
              <button
                key={i}
                onClick={() => handleDotClick(i)}
                className={i === activeIdx ? 'dot active' : 'dot'}
              />
            ))}
          </div>
          <Box display={'flex'} columnGap={'30px'}>
            <button
              className="carousel__btn carousel__btn--prev carousel__btn-arrow carousel__btn-arrow--left"
              onClick={() => prevClick()}
            >
              {'<'}
            </button>
            <button
              className="carousel__btn carousel__btn--next carousel__btn-arrow carousel__btn-arrow--right"
              onClick={() => nextClick()}
            >
              {'>'}
            </button>
          </Box>
        </Box>
      </div>
    </div>
  );
};

const SecondPart: React.FC = () => {
  return (
    <StyledContainer>
      <Container fixed maxWidth={'xl'}>
        <WrapperBox>
          <TextBox>
            <BigText>
              We know that managing your own investments may not be high on your
              to-do list.
            </BigText>
            <SmallText>
              PECULIUM lets you access professional investment management on
              your terms so you can spend more time focusing on what matters to
              you.
            </SmallText>
          </TextBox>
          <Carousel />
        </WrapperBox>
      </Container>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  background: linear-gradient(304.2deg, #0420b6 -1.51%, #304ce3 100%);
  padding-top: 20px;
  height: 100vh;
  padding-bottom: 90px;

  @media (max-width: 1215px) {
    height: fit-content;
  }

  @media (max-width: 800px) {
    padding-top: 135px;
    padding-bottom: 0px;
  }
`;

const WrapperBox = styled(Box)`
  padding: 0px;
  height: fit-content;

  @media (max-width: 1215px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const TextBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  grid-column-gap: 40px;
  gap: 70px;
  padding-right: 120px;

  @media (max-width: 1215px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-right: 0px;
  }
`;

const BigText = styled.span`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: #f9fafb;
`;

const SmallText = styled.span`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #f9fafb;
`;

export default SecondPart;
