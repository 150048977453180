import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';

const DiagramPart: React.FC = () => {
  return (
    <StyledContainer>
      <Gradient />
      <img src="./images/satellites.svg" alt="satellites" />
      <BelieveBox>
        <TitleBox>
          BELIΞVE
          <BadgeBox>LIVE</BadgeBox>
        </TitleBox>
        <DescBox>Stablecoins diversifier and yield optimizer</DescBox>
        <ComBox>
          Our BELIEVE proprietary algorithm will turn your deposit into a robust
          actively managed portfolio of carefully selected Digital Assets that
          responds to market fluctuations.
        </ComBox>
        <Box
          display={'flex'}
          columnGap={'15px'}
          justifyContent={{ xs: 'start' }}
        >
          <FuncButton>Medium risk</FuncButton>
          <FuncButton>Stable earnings</FuncButton>
        </Box>
      </BelieveBox>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  position: relative;
  /* padding-left: 120px;
  padding-right: 120px;
  padding-top: 205px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column-gap: 180px;
  margin-top: 50px;

  @media (max-width: 1215px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 135px;
    gap: 135px;
  }
`;

const Gradient = styled(Box)`
  position: absolute;
  width: 273px;
  height: 273px;
  left: 293px;
  top: 88px;

  background: #ffa2b8;
  opacity: 0.3;
  filter: blur(200px);
`;

const BelieveBox = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-row-gap: 15px;
`;

const BadgeBox = styled(Box)`
  margin-top: 10px;
  background: linear-gradient(225deg, #0e1db8 1.56%, #9071eb 97.92%);
  border-radius: 4px;
  padding: 2px 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #f9fafb;
`;

const TitleBox = styled(Box)`
  display: flex;
  align-items: flex-start;
  grid-column-gap: 5px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 150%;
  color: #022b69;
  @media (max-width: 800px) {
    justify-content: center;
  }
`;

const DescBox = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: #303030;
`;

const ComBox = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #7d91ae;
`;

const FuncButton = styled(Box)`
  width: fit-content;
  height: 36px;
  padding: 8px 12px;
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(27, 175, 158, 0.1);
  border-radius: 4px;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #31b59b;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export default DiagramPart;
