import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Grid, Container as CTR } from '@mui/material';

export default function () {
  return (
    <CTR fixed maxWidth={'lg'}>
      <Grid
        container
        direction={'row'}
        justifyContent={'center'}
        flexWrap={{ xs: 'wrap', md: 'nowrap' }}
        marginTop={12}
      >
        <Grid item container alignSelf={'flex-end'} direction={'column'}>
          <Heading>Our Story</Heading>
          <br />
          <P style={{ marginBottom: '15px', fontSize: '18px', lineHeight: '26px' }}>
            On the heels of 2016-2017’s crypto bull market, a small group of
            entrepreneurs and engineers came together to solve a problem in
            cryptocurrency. As we saw it, crypto was a wild and untamed
            space.
            <br /> There was no industry data or news aggregator—no crypto
            version of a
            Bloomberg Terminal. There was no clean and consistent way of
            settling trades, no institutional-grade custody for digital asset
            storage, and no unified (and timely) path for liquidity. Instead, there was
            opportunity. <br />
            With the right software, many of the issues we saw plaguing the industry
            could be solved. We knew we were the team to build that software, so that’s
            exactly what we did.
          </P>
          <P style={{ marginBottom: '15px', fontSize: '18px', lineHeight: '26px' }}>
            After going live with it in April 2019, PECULIUM quickly became on
            one of the most robust management platforms, operating a successful
            18 month live campaign with our community, but because of regulations, access
            to these sophisticated tools remained out of reach for retail
            investors.
          </P>
          <P style={{ marginBottom: '15px', fontSize: '18px', lineHeight: '26px' }}>
            That didn’t sit right with us. We believe cryptocurrencies should be
            accessible to everyone. The industry provides a unique opportunity
            to invest in early-stage technology, and it has the potential to
            compound capital at rates unavailable in other asset classes. That shouldn’t
            be reserved for the wealthy. So in 2020, we had a second iteration of our software and we called it SAIEVE.
          </P>
        </Grid>
        <Grid item alignSelf={'flex-end'}>
          <img
            src="./images/about/Story.png"
            style={{
              borderRadius: '9px',
              position: 'relative',
              left: '8vw',
              zIndex: 1,
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction={'row'}
        spacing={12}
        justifyContent={'space-between'}
        flexWrap={{ xs: 'wrap', md: 'nowrap' }}
        alignItems={'center'}
        marginBottom={12}
        style={{
          paddingTop: '100px'
        }}
      >
        <Grid item xs={12} md={5}>
          <div
            style={{
              alignSelf: 'flex-start',
              position: 'relative',
              height: '50%',
              transform: 'translateX(-4vw)',
            }}
          >
            <img src="./images/about/Image.png" />

            <img
              style={{
                position: 'absolute',
                left: '-100%',
                top: '-100%',
                borderRadius: '50%',
                filter: 'blur(25px)',
              }}
              src="./images/about/eclipse2.png"
            />
          </div>
        </Grid>

        <Grid
          container
          item
          direction={'column'}
          flexWrap={'nowrap'}
          rowGap={2}
          xs={12}
          md={7}
        >
          <Heading>Our Values</Heading>
          <P style={{ color: '#022B69', fontWeight: 'bold', fontSize: '28px' }}>
            The bedrock of our culture
          </P>
          <Grid container item direction={'row'} wrap={'wrap'} rowGap={2} spacing={2}>
            <Grid item xs={12} md={6}>
              <Col>
                <P style={{ color: '#022B69', fontWeight: 'bold', marginBottom: '15px', fontSize: '20px', lineHeight: '26px' }}>
                  <img
                    src="./images/about/Check.svg"
                    style={{ transform: 'translateY(4px)' }}
                  />{' '}
                  Community first
                </P>
                <P style={{ fontSize: '16px', lineHeight: '20px' }}>
                  It starts and ends here our entire platform and our
                  approach to customer service has been designed from the ground up to put the interests of our community first
                </P>
              </Col>
            </Grid>
            <Grid item xs={12} md={6}>
              <Col>
                <P style={{ color: '#022B69', fontWeight: 'bold', marginBottom: '15px', fontSize: '20px', lineHeight: '26px' }}>
                  <img
                    src="./images/about/Check.svg"
                    style={{ transform: 'translateY(4px)' }}
                  />{' '}
                  Trust through transparency
                </P>
                <P style={{ fontSize: '16px', lineHeight: '20px' }}>
                  Trust fuelled by transparency underlies everything we do, both internally and externally, across teams and products.
                </P>
              </Col>
            </Grid>
          </Grid>

          <Grid container item direction={'row'} wrap={'wrap'}>
            <Grid item xs={12} md={6}>
              <Col>
                <P style={{ color: '#022B69', fontWeight: 'bold', marginBottom: '15px', fontSize: '20px', lineHeight: '26px' }}>
                  <img
                    src="./images/about/Check.svg"
                    style={{ transform: 'translateY(4px)' }}
                  />{' '}
                  Innovation fuelled
                  <br /> by imagination
                </P>
                <P style={{ fontSize: '16px', lineHeight: '20px' }}>
                  We rely on innovation to make investing better, simpler and more productive for our clients
                </P>
              </Col>
            </Grid>
            <Grid item xs={12} md={6}>
              <Col>
                <P
                  style={{
                    color: '#022B69',
                    fontWeight: 'bold',
                    marginBottom: '15px', fontSize: '20px', lineHeight: '26px'
                  }}
                >
                  <img
                    src="./images/about/Check.svg"
                    style={{ transform: 'translateY(4px)' }}
                  />{' '}
                  Simplify complexity
                </P>
                <P style={{ fontSize: '16px', lineHeight: '20px' }}>
                  Inspired by Leonardo da Vinci's
                  famous quote -- "Simplicity is the
                  ultimate sophistication" -- we strive
                  to make the complicated simple by
                  technology
                  with an intuitive user experience.
                </P>
              </Col>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        direction={'row'}
        flexWrap={{ xs: 'wrap', md: 'nowrap' }}
        alignItems={'center'}
      >
        <Grid item xs={12} md={6}>
          <div>
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '7px',
                }}
              >
                <Heading style={{ height: 'fit-content', alignSelf: 'center' }}>
                  Your gateway
                </Heading>
              </div>
              <P
                style={{
                  color: '#022B69',
                  fontWeight: 'bold',
                  fontSize: '35px',
                  marginBottom: '20px'
                }}
              >
                to crypto investment products
              </P>
            </div>
            <P style={{ marginBottom: '15px', fontSize: '18px', lineHeight: '26px' }}>
              We know the digital asset market is new, complex, and one of the
              most difficult investment ecosystems to access. Our team at
              PECULIUM seek to take the 'guess work' out of manager selection
              and capital allocation by providing a tailored selection of
              investment products that have passed our own internal verification
              process.
              <br />
              We specialize in the creation and distribution of in-house and
              asset management investment strategies that are geared towards
              cryptocurrencies and digital assets. Through extensive due
              diligence and research we select our strategies that have proven
              longevity throughout volatile market cycles whilst also continuing
              to maintain the highest degree of operational safety pertaining to
              security, compliance and asset storage.
            </P>
            <Btn>
              <Link to="/philosophy">OUR INVESTMENT PHILOSOPHY</Link>
            </Btn>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <img src="./images/about/Invest.svg" style={{ width: '100%' }} />
        </Grid>
      </Grid>
    </CTR>
  );
}

const Heading = styled.h1`
  color: #fff;
  background-color: #0220bf;
  width: fit-content;
  padding: 4px;
  border-radius: 3px;
`;

const P = styled.p`
  color: #7d91ae;
  text-justify: newspaper;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Btn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(304.2deg, #0420b6 -1.51%, #304ce3 100%);
  border-radius: 4px;
  padding-left: 5px;
  padding-top: 9px;
  padding-bottom: 9px;
  margin-top: 15px;
  padding-right: 5px;
  border: none;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  text-transform: uppercase;
  color: #f9fafb;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;
