import React from 'react';
import {Box, Container, Grid} from '@mui/material';
import styled from 'styled-components';

export default function () {
  return (
    <StyledContainer>
      <Container fixed maxWidth={'lg'}>
        <StyledGrid container spacing={2}>
          <Grid item md={5}
            style={{ textAlign: 'right', marginTop: 30 }}
          >
            <img
              src="./images/pcltoken/Stake.svg"
            />
          </Grid>
          <Grid item md={2}/>
          <Grid item md={5}>
            <img
              src="./images/pcltoken/Membership.svg"
            />
          </Grid>
        </StyledGrid>
        <StyledGrid container spacing={2}>
          <Grid item md={4}>
            <StyledP>Get the most</StyledP>
            <StyledP>
              out of <WithBackground>PCL</WithBackground>
            </StyledP>
          </Grid>
          <Grid item md={5}/>
          <Grid item md={3}>
            <p style={{ color: 'white', fontSize: 15, lineHeight: '20px' }}>
              The PCL Token is the key to unlocking <br/>
              the full potential of PECULIUM. <br/>
              Use PCL to pay services, pay fees with <br/>
              discounts, receive trading fee <br/>
              cash backs and much more.
            </p>
          </Grid>
        </StyledGrid>
        <StyledGrid container spacing={2}>
          <Grid item md={5}
            style={{ textAlign: 'right', marginTop: 30 }}
          >
            <img
              src="./images/pcltoken/Burn.svg"
            />
          </Grid>
          <Grid item md={2}/>
          <Grid item md={5}>
            <img
              src="./images/pcltoken/Cashback.svg"
            />
          </Grid>
        </StyledGrid>
      </Container>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-width: 100%;
  background-image: url(./images/pcltoken/CoinPCL.svg), linear-gradient(304.2deg,#0420b6 -1.51%,#304ce3 100%);
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: 120px;
`;

const StyledGrid = styled(Grid)`
  margin-bottom: 30px;
`;

const StyledP = styled.p`
  font-size: 32px;
  color: white;
  font-weight: bold;
  line-height: 50px;
`;

export const WithBackground = styled.span`
  background-color: #0220bf;
  font-size: 32px;
  color: white;
  font-weight: bold;
  padding: 0.3em;
`;
