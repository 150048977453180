import React from 'react';
import styled from 'styled-components';
import { CustomBg } from '../PCLToken/SubMain';
import { Container as CTR, Grid } from '@mui/material';

function Main() {
  return (
    <div style={{ paddingTop: '60px'}}>
      <CTR fixed maxWidth={'lg'}>
        <Grid container spacing={4}>
          <Grid item md={6}>
            <h1 style={{
              color: '#022B69',
              fontWeight: '700',
              fontSize: '48px',
              lineHeight: '62px',
            }}>
              <CustomBg>Careers</CustomBg> <br/>
              at PECULIUM
            </h1>
            <p
              style={{
                color: '#7D91AE',
                fontSize: '20px',
                lineHeight: '30px',
                marginBottom: '20px'
              }}
            >
              PECULIUM believes Digital Assets governed by open source networks
              are enabling a new paradigm for monetary systems and mechanisms to
              store and transfer value. By unlocking a new mechanism to store
              and transfer value,cryptocurrencies have the opportunity to create
              an open foundation of strong assurances in wealth and monetary
              integrity.
              <br />
              <br />
              Since 2017, we have been taking steps into the unbeaten paths to
              realize our mission and bring many people's imaginations of
              blockchain to life.
              <br />
              <br />
              The revolution has already started.
            </p>
            <Btn>Join us</Btn>
          </Grid>
          <Grid item md={6}>
            <Grid container spacing={4} style={{ marginBottom: '20px', justifyContent: 'center'}}>
              <Grid item md={6}>
                <Card img="./images/about/imgcareer.png" />
              </Grid>

              <Grid item md={6}>
                <Card style={{ backgroundColor: 'rgb(154, 112, 227)' }}>
                  <h4 style={{ fontSize: '18px' }}>Software is our superpower</h4>

                  <p style={{ fontSize: '16px', lineHeight: '24px' }}>
                    We 're a technology company above anything else.
                    <br />
                    We focus on automation, data <br />
                    and human-centered
                    <br /> design to make managing your financial life easy and
                    delightful.
                  </p>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={4} style={{ marginBottom: '20px'}}>
              <Grid item md={6}>
                <Card style={{ backgroundColor: '#0067F5' }}>
                  <h4 style={{ fontSize: '18px' }}>Don't have a background in finance? No problem.</h4>
                  <p style={{ fontSize: '16px', lineHeight: '24px' }}>
                    Diversity is key to solving big consumer problems. If you're
                    a big thinker who cares about improving the financial lives
                    of others, we want you.
                  </p>
                </Card>
              </Grid>
              <Grid item md={6}>
                <Card img="./images/about/imgcareer2.png" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          flexDirection={'row'}
          flexWrap={{ xs: 'wrap', md: 'nowrap' }}
          justifyContent={'center'}
          style={{ color: '#7D91AE', marginTop: '90px' }}
        >
          <Grid item>
            <IMGcareer src="./images/about/imgcareer3.svg" />
          </Grid>
          <Grid item container flexDirection={'column'} rowGap={12}>
            <Grid
              container
              item
              flexDirection={'row'}
              columnGap={4}
              flexWrap={{ xs: 'wrap', md: 'nowrap' }}
            >
              <Grid container item flexDirection={'column'}>
                <img
                  src="./images/about/c1.png"
                  style={{ width: '35px', alignSelf: 'center' }}
                />
                <h4 style={{ fontSize: '16px', fontWeight: 'bold', margin: '10px 0', lineHeight: '24px' }}>
                  Lunch and snacks on the house
                </h4>
                <p style={{fontSize: '16px', lineHeight: '24px' }}>
                  Enjoy lunches, snacks, coffee and stipends
                  <br /> to get out to lunch with coworkers.
                </p>
              </Grid>
              <Grid container item flexDirection={'column'}>
                <img
                  src="./images/about/c2.png"
                  style={{ width: '35px', alignSelf: 'center' }}
                />
                <h4 style={{ fontSize: '16px', fontWeight: 'bold', margin: '10px 0', lineHeight: '24px' }}>
                  Home or the office?
                  You do you.
                </h4>
                <p style={{fontSize: '16px', lineHeight: '24px' }}>
                  We want you to succeed whichever
                  way works best for you.
                </p>
              </Grid>
              <Grid container item flexDirection={'column'}>
                <img
                  src="./images/about/c3.png"
                  style={{ width: '35px', alignSelf: 'center' }}
                />
                <h4 style={{ fontSize: '16px', fontWeight: 'bold', margin: '10px 0', lineHeight: '24px' }}>We reward fairly</h4>
                <p style={{fontSize: '16px', lineHeight: '24px' }}>
                  Competitive Salary and generous equity
                   and PCL tokens packages
                </p>
              </Grid>
            </Grid>

            <Grid
              container
              item
              flexDirection={'row'}
              columnGap={4}
              flexWrap={{ xs: 'wrap', md: 'nowrap' }}
            >
              <Grid container item flexDirection={'column'}>
                <img
                  src="./images/about/c4.png"
                  style={{ width: '35px', alignSelf: 'center' }}
                />
                <h4 style={{ fontSize: '16px', fontWeight: 'bold', margin: '10px 0', lineHeight: '24px' }}>All the tools you need</h4>
                <p style={{fontSize: '16px', lineHeight: '24px' }}>
                  Top of range equopment; MacBook pro,
                  Keyboard, Laptop stand, monitor, iPhones, and more.
                </p>
              </Grid>
              <Grid container item flexDirection={'column'}>
                <img
                  src="./images/about/c5.png"
                  style={{ width: '35px', alignSelf: 'center' }}
                />
                <h4 style={{ fontSize: '16px', fontWeight: 'bold', margin: '10px 0', lineHeight: '24px' }}>Work/life bakance</h4>
                <p style={{fontSize: '16px', lineHeight: '24px' }}>
                  We have flexible working hours, a discretionary time off
                  policy and offer 16 weeks of paid
                  parental leave (both parents). We work hard, but we rejuvenate
                  too.
                </p>
              </Grid>
              <Grid container item flexDirection={'column'}>
                <img
                  src="./images/about/c6.png"
                  style={{ width: '35px', alignSelf: 'center' }}
                />
                <h4 style={{ fontSize: '16px', fontWeight: 'bold', margin: '10px 0', lineHeight: '24px' }}>Your health matters</h4>
                <p style={{fontSize: '16px', lineHeight: '24px' }}>
                  We provide comprehensive medical,dental and vision
                  coverage for you and your family, including HSA
                  contributions for
                  high deductible plans.
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CTR>
    </div>
  );
}

const Card = styled.div.attrs((p: { img: string }) => ({ img: p.img || '' }))`
  width: 282px;
  height: 283px;
  border-radius: 6px;
  padding: 25px;
  background: url(${(p) => p.img || ''});
  background-size: '100% 100%';
  background-repeat: no-repeat;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 10%;
`;

const Btn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0220bf;
  border-radius: 4px;
  padding: 8px;
  border: none;
  width: fit-content;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #f9fafb;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

const IMGcareer = styled.img`
  border-radius: 20px;
  position: relative;
  height: 80%;
  @media (max-width: 1215px) {
    width: 70%;
  }
`;

export default Main;
