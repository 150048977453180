import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

interface Props {
  title: string;
  active: boolean;
  changeState: any;
  children?: any;
  closeState: any;
}

const Accordin: React.FC<Props> = ({
  title,
  active,
  children,
  closeState,
  changeState,
}: any) => {
  return (
    <StyledContainer>
      <Title
        onClick={() => {
          active ? closeState() : changeState();
        }}
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.6006 23.6777H19.3594C19.3535 23.3555 19.3506 23.1592 19.3506 23.0889C19.3506 22.3623 19.4707 21.7646 19.7109 21.2959C19.9512 20.8271 20.4316 20.2998 21.1523 19.7139C21.873 19.1279 22.3037 18.7441 22.4443 18.5625C22.6611 18.2754 22.7695 17.959 22.7695 17.6133C22.7695 17.1328 22.5762 16.7227 22.1895 16.3828C21.8086 16.0371 21.293 15.8643 20.6426 15.8643C20.0156 15.8643 19.4912 16.043 19.0693 16.4004C18.6475 16.7578 18.3574 17.3027 18.1992 18.0352L15.9316 17.7539C15.9961 16.7051 16.4414 15.8145 17.2676 15.082C18.0996 14.3496 19.1895 13.9834 20.5371 13.9834C21.9551 13.9834 23.083 14.3555 23.9209 15.0996C24.7588 15.8379 25.1777 16.6992 25.1777 17.6836C25.1777 18.2285 25.0225 18.7441 24.7119 19.2305C24.4072 19.7168 23.751 20.3789 22.7432 21.2168C22.2217 21.6504 21.8965 21.999 21.7676 22.2627C21.6445 22.5264 21.5889 22.998 21.6006 23.6777ZM19.3594 27V24.5303H21.8291V27H19.3594Z"
            fill="url(#paint0_linear_1015_7222)"
          />
          <circle
            cx="20"
            cy="20"
            r="19"
            stroke="url(#paint1_linear_1015_7222)"
            stroke-width="2"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1015_7222"
              x1="15"
              y1="7"
              x2="24.4074"
              y2="7.14271"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#000B73" stop-opacity="0.1" />
              <stop offset="1" stop-color="#000F92" stop-opacity="0.15" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_1015_7222"
              x1="0"
              y1="0"
              x2="41.734"
              y2="1.89936"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#000B73" stop-opacity="0.1" />
              <stop offset="1" stop-color="#000F92" stop-opacity="0.15" />
            </linearGradient>
          </defs>
        </svg>
        {title}
      </Title>
      {active && <Desc>{children}</Desc>}
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  width: 690px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const Title = styled(Box)`
  display: flex;
  align-items: center;
  background: #f9fafb;
  height: 60px;
  padding: 10px 15px;
  border-radius: 6px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #022b69;
  cursor: pointer;
  grid-column-gap: 15px;
`;

const Desc = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #7d91ae;
  padding-top: 18px;
  padding-bottom: 30px;
  padding-left: 50px;
`;

Accordin.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Accordin;
