import React from 'react';
import SubHeader from './SubHeader';
import Main from './Main';
import SubMain from './SubMain';

function index() {
  return (
    <>
      <SubHeader />
      <Main />
      <SubMain />
    </>
  );
}

export default index;
