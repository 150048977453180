import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";

const ThirdPart: React.FC = () => {
  return <StyledContainer></StyledContainer>;
};

const StyledContainer = styled(Box)``;

export default ThirdPart;
