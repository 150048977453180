import React from 'react';
import SubHeader from './SubHeader';
import Main from './Main';

export default function () {
  return (
    <>
      <SubHeader />
      <Main />
    </>
  );
}
