import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';

const Diversification: React.FC = () => {
  return (
    <StyledContainer>
      <EachItem>
        <Box display={'flex'} justifyContent={'center'}>
          <TitleBox>
            <span>Smart Diversification</span>
          </TitleBox>
        </Box>
        <ContentBox>
          <WrapperBox>
            <SubTitle>Gain diversified exposure</SubTitle>
            <SubContent>To a well-curated basket of top</SubContent>
            <SubContent>performing assets</SubContent>
            <DivideBox />
            <SubTitle>Expected annual yield: 23.8% in Stablecoins</SubTitle>
            <SubContent>True Passive income</SubContent>
            <DivideBox />
            <SubTitle>Save time for best opportunities</SubTitle>
            <SubContent>
              Our algorithm finds growth opportunities in real-time and makes
              short-term trades that increase your Stablecoins value
            </SubContent>
          </WrapperBox>
        </ContentBox>
      </EachItem>
      <EachItem position={'relative'}>
        <Box display={'flex'} justifyContent={'center'}>
          <TitleBox>
            <span>Quantitative approach</span>
          </TitleBox>
        </Box>
        <ContentBox>
          <WrapperBox>
            <SubTitle>Almost rocket science</SubTitle>
            <SubContent>
              Our quantitative models are always learning and adapting to new
              market data
            </SubContent>
            <DivideBox />
            <SubTitle>Pinpoint optimal trades</SubTitle>
            <SubContent>While minimising losses the most up-to-date</SubContent>
            <SubContent>info right away</SubContent>
            <DivideBox />
            <SubTitle>Save time</SubTitle>
            <SubContent>
              Our software spends hundreds of hours monitoring and screening the
              top coins, day by day, so you’d only need to spend minutes
            </SubContent>
          </WrapperBox>
        </ContentBox>
        <Gradient1 />
        <Gradient2 />
        <Gradient3 />
      </EachItem>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  gap: 26px;
  @media (max-width: 1215px) {
    flex-direction: column;
    align-items: center;
    margin-top: 135px;
    gap: 135px;
  }
`;

const EachItem = styled(Box)`
  width: 588px;
  @media (max-width: 800px) {
    width: 350px;
  }
`;

const TitleBox = styled(Box)`
  /* position: absolute;
  top: -80px; */

  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  width: 407px;
  height: 125px;
  background: radial-gradient(
      100% 286.23% at 0% 3.93%,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0.2) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  border: 2px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(20px);
  border-radius: 10px;
  opacity: 0.9;
  /* transform: rotate(90deg); */

  span {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 150%;
    /* identical to box height, or 45px */

    text-align: center;

    /* Gradient/2 */

    background: linear-gradient(304.2deg, #0420b6 -1.51%, #304ce3 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
`;

const WrapperBox = styled(Box)`
  padding: 122px 85px 0px 85px;
  text-align: center;
  @media (max-width: 800px) {
    padding: 25px;
    padding-top: 55px;
    text-align: center;
  }
`;

const ContentBox = styled(Box)`
  /* position: relative; */
  background-image: url('./images/DivItemBack.png');
  height: 630px;
  width: 100%;
  margin-top: -50px;
  background: linear-gradient(304.2deg, #0420b6 -1.51%, #304ce3 100%);
  box-shadow: 0px 46px 130px rgba(60, 87, 221, 0.4);
  border-radius: 10px;
`;

const SubTitle = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: #f9fafb;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

const DivideBox = styled(Box)`
  margin-top: 30px;
  margin-bottom: 30px;
  opacity: 0.4;
  /* Secondary/Grey 1 */
  border: 1px solid #7d91ae;
  width: 100%;
  height: 1px;
`;

const SubContent = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #7d91ae;
`;

const Gradient1 = styled(Box)`
  position: absolute;
  width: 450px;
  height: 450px;
  left: -174px;
  top: 526px;

  background: #09cfdc;
  opacity: 0.2;
  filter: blur(400px);
`;

const Gradient2 = styled(Box)`
  position: absolute;
  width: 446px;
  height: 446px;
  left: 395px;
  top: 259px;

  background: #ffa2b8;
  opacity: 0.2;
  filter: blur(400px);
`;

const Gradient3 = styled(Box)`
  position: absolute;
  width: 455px;
  height: 455px;
  left: -182px;
  top: -314px;

  /* Gradient / 5 */

  background: linear-gradient(225deg, #ffd44f 0%, #ffa607 100%);
  opacity: 0.2;
  filter: blur(400px);
`;

export default Diversification;
