import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import styled from "styled-components";

function SubHeader() {
  const data = [
    { holder: "PECULIUM", pcl: 33476098 },
    { holder: "THE PHILOSOPHER", pcl: 5864398 },
    { holder: "AM", pcl: 2943578 },
    { holder: "AI", pcl: 243578 },
    { holder: "AI", pcl: 199768 },
  ];
  const price = 0.01540901;
  return (
    <>
      <Header style={{ flexWrap: "wrap" }}>
        <Col>
          <h1
            style={{
              backgroundColor: "#0220BF",
              padding: "4px 6px",
              borderRadius: "5px",
              color: "white",
            }}
          >
            Top 100
          </h1>
          <h1 style={{ color: "#0220BF" }}>HODLers</h1>
        </Col>

        <Col>
          <h3 style={{ color: "#022B69" }}>The Top 100 HODLers of PCL Token</h3>
          <p>
            See yourself on the list?
            <br /> Submit your name and avatar to be featured in the
            <br /> Super community dashboard.
          </p>
          <button
            style={{
              border: "none",
              backgroundColor: "#0220BF",
              width: "fit-content",
              fontSize: "18px",
              padding: "8px 16px",
              borderRadius: "5px",
              color: "white",
              fontWeight: "300",
            }}
          >
            SEND
          </button>
        </Col>
        <img
          src="./images/community/star.svg"
          style={{ position: "absolute", right: "10px" }}
        />
        <img
          src="./images/community/sred2.svg"
          style={{ position: "absolute", right: "0px", bottom: "0px" }}
        />
        <img
          src="./images/community/star.svg"
          style={{
            position: "absolute",
            right: "50%",
            opacity: 0.4,
            width: "30%",
            bottom: "0%",
          }}
        />
      </Header>
      <Holders>
        <h1>Top 100 HODLers Preview</h1>

        <StyledTable>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ color: "white", fontSize: "16px" }}>
                  Rank
                </TableCell>
                <TableCell style={{ color: "white", fontSize: "16px" }}>
                  Hodlers
                </TableCell>
                <TableCell style={{ color: "white", fontSize: "16px" }}>
                  PCL Token
                </TableCell>
                <TableCell style={{ color: "white", fontSize: "16px" }}>
                  PCL in USD
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((d, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell style={{ color: "white", fontSize: "16px" }}>
                    {i}
                  </TableCell>
                  <TableCell style={{ color: "white", fontSize: "16px" }}>
                    {d.holder}
                  </TableCell>
                  <TableCell style={{ color: "white", fontSize: "16px" }}>
                    {d.pcl}
                  </TableCell>
                  <TableCell style={{ color: "white", fontSize: "16px" }}>
                    ${(d.pcl * price).toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <img
            src="./images/community/star.svg"
            style={{ position: "absolute", left: "0px", bottom: "-80%" }}
          />
        </StyledTable>
      </Holders>
    </>
  );
}

const Header = styled.div`
  width: 100vw;
  height: 50vh;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  padding-top: 10%;
  justify-content: space-evenly;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
=`;

const Holders = styled.div`
  width: 100%;
  background: linear-gradient(304.2deg, #0420b6 -1.51%, #304ce3 100%);
  height: 70vh;
  z-index: 1;
  padding-top: 20px;
  padding-bottom: 5%;
  padding-left: 5%;
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
`;

const StyledTable = styled.div`
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  width: 90%;
`;

export default SubHeader;
