import React from 'react';
import styled from 'styled-components';
import { Container } from '@mui/material';

function SubHeader() {
  return (
    <div
      style={{
        backgroundImage: "url('./images/about/union.png')",
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div style={{ position: 'relative', width: '0px', height: '0px' }}>
        <img
          style={{ position: 'absolute', top: '-30vh' }}
          src="./images/about/eclipse.svg"
        />
        <img
          style={{ position: 'absolute', top: '60vh', left: '40px' }}
          src="./images/about/blocks.svg"
        />
      </div>
      <Container fixed maxWidth={'lg'}>
        <Images>
          <Image
            src="./images/about/a.png"
            style={{ position: 'absolute', right: '10%', bottom: '2%' }}
          />
          <Image
            src="./images/about/b.png"
            style={{ position: 'absolute', left: '9%', bottom: '0' }}
          />
          <Image
            src="./images/about/c.png"
            style={{ position: 'absolute', left: '10%', bottom: '42%' }}
          />
          <Image
            src="./images/about/d.png"
            style={{ position: 'absolute', right: '45%', bottom: '38%' }}
          />
          <Image
            src="./images/about/b.svg"
            style={{ position: 'absolute', left: '25%', bottom: '25%' }}
          />
          <Image
            src="./images/about/a.svg"
            style={{ position: 'absolute', left: '30%', bottom: '-10%' }}
          />
          <Image
            src="./images/about/a.svg"
            style={{ position: 'absolute', left: '30%', top: '40%' }}
          />
          <Image
            src="./images/about/a.svg"
            style={{ position: 'absolute', right: '30%', top: '20%' }}
          />
          <Image
            src="./images/about/c.svg"
            style={{ position: 'absolute', right: '20%', bottom: '40%' }}
          />
          <Image
            src="./images/about/e.png"
            style={{ position: 'absolute', right: '30%', bottom: '33%' }}
          />
          <Image
            src="./images/about/f.png"
            style={{ position: 'absolute', right: '12%', top: '35%' }}
          />

          <div style={{ position: 'relative', width: '0px', height: '0px' }}>
            <img
              style={{ position: 'absolute', top: '-30vh', left: '80vw' }}
              src="./images/about/eclipse2.png"
            />
          </div>

          <TextPart>
            <h1 style={{ color: '#022B69' }}>It started with a vision </h1>
            <p style={{ fontSize: '18px', lineHeight: '26px' }}>
              To leverage the latest advances in technology and data <br/>
              science to help retail investors build wealth.
            </p>
          </TextPart>
        </Images>
      </Container>
    </div>
  );
}

const Image = styled.img`
  border-radius: 50%;
`;

const Images = styled.div`
  margin-top: 10px;
  height: 90vh;
  margin-left: auto;
  margin-bottom: 90px;
  position: relative
`;

const TextPart = styled.div`
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
`;
export default SubHeader;
