import React from "react";
import styled from "styled-components";
import { Grid, Container as GlobalContainer } from "@mui/material";
import Member from "./Member";
export default function () {
  return (
    <Section>
      <Container>
        <Text>
          <h1 style={{ color: "#022B69", fontSize: '48px', lineHeight: '72px' }}>An experienced team</h1>
          <p style={{ color: "#7D91AE", fontSize: "18px", lineHeight: '26px' }}>
            Combining vision with execution
          </p>
        </Text>

      </Container>
      <GlobalContainer fixed maxWidth={'lg'}>
        <h2 style={{ color: "#022B69", fontSize: '48px', lineHeight: '72px', marginBottom: '20px'}}>Our Team</h2>
        <Grid container spacing={4}>
          <Grid item xs={6} md={3}>
            <Member
              image="./images/about/rachid.svg"
              linkedin="https://www.linkedin.com/in/rashid-oukhai-18888337/"
              name="Rashid Oukhai"
              desc="Founder & CEO"
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Member
              image="./images/about/bilel.svg"
              linkedin="https://www.linkedin.com/in/bilel-ben-tanfous/"
              name="Bilel Ben Tanfous"
              desc="COO"
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Member
              image="./images/about/marouane.svg"
              linkedin="https://www.linkedin.com/in/marouane-b-044055a5/"
              name="Marouane Benalla"
              desc="CTO"
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Member
              image="./images/about/florian.svg"
              linkedin="https://www.linkedin.com/in/florian-rais-677a633a/"
              name="Florian Rais"
              desc="Banking Advisor"
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Member
              image="./images/about/mustapha.svg"
              linkedin="https://www.linkedin.com/in/mustapha-nasraoui-551ba415/"
              name="Mustapha Nasraoui"
              desc="Full stack devops"
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Member
              image="./images/about/marco.svg"
              linkedin="https://www.linkedin.com/in/marcopetri81/"
              name="Marco Petri"
              desc="Communication Specialist, Europe & US"
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Member
              image="./images/about/aymen.svg"
              linkedin="https://www.linkedin.com/in/aymen-slaimi-35738180/"
              desc="Communication Specialist, Middle East"
              name="Aymen Slaimi"
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Member
              image="./images/about/vince.png"
              linkedin="https://www.linkedin.com/in/vincent-dangel-036bab19b/"
              desc="Analyst"
              name="Vincent DANGEL"
            />
          </Grid>
        </Grid>
      </GlobalContainer>
    </Section>
  );
}

const Section = styled.div`
  background-image: url("./images/about/bg_team.svg");
  background-size: cover;
  padding: 0 0 75px 0;
`;

const Container = styled.div`
  height: 100vh;
  position: relative
`;
const Text = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;
