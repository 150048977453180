import React, { useState } from 'react';
import styled from 'styled-components';

function Main() {
  const [question, setQ] = useState(0);

  const handlQues = (e: any) => {
    setQ((c) => (e.target.id == c ? '0' : e.target.id));
  };
  return (
    <>
      <Container>
        <div
          style={{ display: 'flex', flexDirection: 'column', color: 'white' }}
        >
          <h1>Ready to take part?</h1>
          <p>
            it has never been so rewarding to have your
            <br />
            friends join SAIEVE.
            <br />
            Share the love and earn 30$ in PCL per referral !
          </p>
          <button
            style={{
              backgroundColor: 'white',
              width: 'fit-content',
              color: '#304CE3',
              fontWeight: 'bold',
              fontSize: '14px',
              padding: '10px 10px',

              border: 'none',
              borderRadius: '5px',
            }}
          >
            Start Referring
          </button>
        </div>
        <img src="./images/community/coins.png" />
        <img
          style={{
            position: 'absolute',
            filter: 'blur(2px)',
            bottom: '-10%',
            right: '0%',
            height: '255px',
            width: '233px',
          }}
          src="./images/community/pcl.png"
        />
        <img
          style={{
            position: 'absolute',
            filter: 'blur(2px)',
            top: '-20%',
            left: '-90px',
            height: '255px',
            width: '233px',
          }}
          src="./images/community/silver.png"
        />
        <img
          style={{
            position: 'absolute',
            top: '40px',
            right: '40px',
          }}
          src="./images/community/Dots2.svg"
        />
        <img
          style={{
            position: 'absolute',
            top: '150px',
            left: '40px',
          }}
          src="./images/community/Dots2.svg"
        />
      </Container>
      <div
        style={{
          width: '70vw',
          height: 'fit-content',
          padding: '25px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
          color: '#022B69',
          gap: '20px',
          paddingBottom: '20%',
        }}
      >
        <h1>FAQs</h1>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
            border: '1px solid #000F9226',
            cursor: 'pointer',
            padding: '0px 5px 0px 5px',
            width: '100%',
          }}
          id="1"
          onClick={handlQues}
        >
          <img src="./images/community/q.svg" />
          <h4>What are SAIEVE Baskets?</h4>
        </div>
        {question == 1 && (
          <span style={{ color: '#7D91AE' }}>
            Baskets are modern investment products that help you build a
            low-cost, long-term & diversified portfolio easily. Created by
            professionals, each basket is a ready-made portfolio of cryptos that
            reflects a theme, strategy or objective&.
          </span>
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
            border: '1px solid #000F9226',
            cursor: 'pointer',
            padding: '0px 5px 0px 5px',
            width: '100%',
          }}
          id="2"
          onClick={handlQues}
        >
          <img src="./images/community/q.svg" />
          <h4>What are SAIEVE Baskets?</h4>
        </div>
        {question == 2 && <span style={{ color: '#7D91AE' }}>response 2</span>}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
            border: '1px solid #000F9226',
            cursor: 'pointer',
            padding: '0px 5px 0px 5px',
            width: '100%',
          }}
          id="3"
          onClick={handlQues}
        >
          <img src="./images/community/q.svg" />
          <h4>What are SAIEVE Baskets?</h4>
        </div>
        {question == 3 && <span style={{ color: '#7D91AE' }}>response 3</span>}
      </div>
    </>
  );
}

const Container = styled.div`
  width: 100vw;
  height: fit-content;
  background: linear-gradient(304.2deg, #0420b6 -1.51%, #304ce3 100%);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;
  justify-content: space-around;
  flex-wrap: wrap;
  position: relative;
`;

export default Main;
