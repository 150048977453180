import React from 'react';
import styled from 'styled-components';
import { Box, Container } from '@mui/material';

import { Link } from 'react-router-dom';

const TradePart: React.FC = () => {
  return (
    <StyledContainer>
      <Container fixed maxWidth={'xl'}>
        <WrapperBox>
          <TextPart>
            <Title>More than just a tradable asset</Title>
            <Desc>PCL token is an investment in our community's future</Desc>
            <Link to="/pcl">
              <DiscButton>DISCOVER PCL UTILITIES</DiscButton>
            </Link>
          </TextPart>
          <Box>
            <img src="./images/home/Coins.png" alt="coins" />
          </Box>
        </WrapperBox>
      </Container>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  height: 100vh;

  @media (max-width: 1215px) {
    padding: 0px;
    height: fit-content;
    margin-top: 135px;
  }
`;

const WrapperBox = styled(Box)`
  background: linear-gradient(304.2deg, #001cb3 -1.51%, #324ee6 100%);
  box-shadow: 0px 0px 54.3813px rgba(0, 23, 143, 0.2);
  border-radius: 10px;
  padding: 44px;
  padding-top: 64px;
  padding-left: 64px;

  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 1215px) {
    flex-direction: column-reverse;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
`;

const TextPart = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-row-gap: 30px;
`;

const Title = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 64px;
  color: #f9fafb;
  @media (max-width: 800px) {
    font-size: 36px;
  }
`;

const Desc = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #f9fafb;
`;

const DiscButton = styled(Box)`
  background: #f9fafb;
  border-radius: 6px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: #0220bf;
  &:hover {
    opacity: 0.7;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: fit-content;
`;

export default TradePart;
