import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import styled from 'styled-components';
import TopbarMobile from './TopbarMobile';

import Topbar from './Topbar';
import Footer from './Footer';

interface Props {
  children?: any;
}

const Layout: React.FC<Props> = ({ children }: any) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const bodyRef = useRef<any>();

  const handResize = () => {
    setWindowSize(window.innerWidth);
  };

  window.addEventListener('resize', handResize);

  return (
    <StyledContainer>
      {windowSize >= 822 ? <Topbar /> : <TopbarMobile />}
      <Body component="main" {...{ ref: bodyRef }}>
        {children}
      </Body>
      <Footer />
    </StyledContainer>
  );
};

const Overlay = styled(Box)<{ showwallet: any }>`
  position: fixed;
  inset: 0px;
  z-index: 30;
  opacity: ${({ showwallet }) => (showwallet ? 1 : 0)};
  height: ${({ showwallet }) => (showwallet ? 'auto' : '0px')};
  transition: opacity 0.3s ease-in-out 0s;
  background-color: rgba(0, 0, 0, 0.15);
`;

const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: 100%;

  background-color: rgb(255, 255, 255);
  overflow-x: hidden;
`;

const Body = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
