import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";
import IntroPart from "./Intropart";
import SecondPart from "./SecondPart";
import ThirdPart from "./ThirdPart";
import ChartPart from "./ChartPart";
import InvestingPart from "./InvestingPart";
import LastPart from "./LastPart";
import BetterItemsPart from "./BetterItemsPart";
import PCLPart from "./PCLPart";
import BusinessModel from "./BusinessModel";
import TradePart from "./Tradepart";
import MissionPart from "./MissionPart";
import PartnerPart from "./PartnerPart";
import Portfolio from "./Portfolio";
import Profit from "./Profit";

const Homepage: React.FC = () => {
  return (
    <StyledContainer>
      <IntroPart />
      <SecondPart />
      <ThirdPart />
      <InvestingPart />
      <Portfolio />
      <Profit />
      <PCLPart />
      <BusinessModel />
      <TradePart />
      <MissionPart />
      <PartnerPart />
      <BetterItemsPart />
      <LastPart />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)``;

export default Homepage;
