import React from 'react';
import styled from 'styled-components';
import { Box, Grid } from '@mui/material';
import Tokens from '../../data/tokens.json';
import GetStartedItem from '../../components/GetStartedItem';
const GetStarted: React.FC = () => {
  return (
    <StyledContainer>
      <Wrapper>
        <Grid container spacing={2}>
          {Tokens.map((token) => (
            <Grid item xs={12} md={3}>
              <GetStartedItem {...token} />
            </Grid>
          ))}
        </Grid>
      </Wrapper>
      <Box marginTop={'50px'} display={'flex'} justifyContent={'center'}>
        <StartButton href="https://app.saieve.io">GET STARTED</StartButton>
      </Box>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  margin-top: 140px;
  padding: 0 120px;
  margin-bottom: 150px;

  @media (max-width: 1215px) {
    padding-left: 30px;
    padding-right: 30px;
    flex-direction: column;
    align-items: center;
  }
`;

const Wrapper = styled(Box)`
  padding: 45px 52px;
  background: #f9fafb;
  /* box-shadow: 0px 4px 59px rgba(0, 0, 0, 0.05); */
  box-shadow: 0px 4px 59px rgb(213 212 212);
  border-radius: 10px;
  /* display: flex;
  flex-wrap: wrap;
  column-gap: 90px; */
  @media (max-width: 1215px) {
    padding-left: 30px;
    padding-right: 30px;
    flex-direction: column;
    align-items: center;
  }
`;

const StartButton = styled.a`
  width: 166px;
  height: 48px;
  background: linear-gradient(304.2deg, #0420b6 -1.51%, #304ce3 100%);
  border-radius: 6px;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #f9fafb;

  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  display: flex;
  justify-content: center;
  align-items: center;
`;

export default GetStarted;
