import React from 'react';
import styled from 'styled-components';
import { Box, Container } from '@mui/material';
import IntroPart from './IntroPart';
import SecondPart from './SecondPart';
import ThirdPart from './ThirdPart';
import FourthPart from './FourthPart';
import FifthPart from './FifthPart';
import SixthPart from './SixthPart';
import FAQs from './FAQs';
import LastPart from './LastPart';

const Investing: React.FC = () => {
  return (
    <StyledContainer>
      <Container fixed maxWidth={'lg'}>
        <IntroPart />
      </Container>
      <SecondPart />
      <ThirdPart />
      <FourthPart />
      <FifthPart />
      <SixthPart />
      <FAQs />
      <LastPart />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  position: relative;
`;

const OneBlock = styled(Box)`
  background: linear-gradient(304.2deg, #0420b6 -1.51%, #304ce3 100%);
  box-shadow: 0px 0px 54.3813px rgba(0, 23, 143, 0.2);
  border-radius: 10px;
`;

export default Investing;
