import { Container, Box } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

function Profit() {
  return (
    <StyledDiv>
      <Container fixed maxWidth={'xl'}>
        <StyledRow>
          <IMGcontainer src="./images/home/imgProfit.png" />
          <Col>
            <h1>Our Investment Products</h1>
            <Box
              display={'flex'}
              gap={2}
              flexDirection={{ xs: 'column', md: 'row' }}
            >
              <h1>returned</h1>
              <h1
                style={{
                  background:
                    'linear-gradient(225deg, #0E1DB8 1.56%, #9071EB 97.92%)',
                  padding: '4px',
                }}
              >
                +95.4%
              </h1>
              <h1>on average</h1>
            </Box>
            <h1 style={{ marginTop: '-10px' }}> over the past year!</h1>
            <span style={{ fontSize: '20px', lineHeight: '150%' }}>
              We give you access to the investment opportunities that the 1%
              have been taking advantage of for years. We've built a range of
              investment strategies that have proven longevity throughout
              volatile market cycles.
            </span>
          </Col>
        </StyledRow>
      </Container>
    </StyledDiv>
  );
}

const StyledRow = styled.div`
  display: flex;

  flex-direction: row;
  color: #fff;
  gap: 80px;

  @media (max-width: 1215px) {
    padding: 0px;
    height: fit-content;
    flex-direction: column-reverse;
    align-items: center;
    gap: 50vh;
  }
`;

const StyledDiv = styled.div`
  background: linear-gradient(304.2deg, #0420b6 -1.51%, #304ce3 100%);
  padding-top: 150px;
  padding-bottom: 150px;
  font-size: 48px;

  @media (max-width: 1215px) {
    height: fit-content;
    font-size: 11px;
    padding-top: 135px;

    text-align: center;

    h1 {
      font-size: 36px;
    }
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  @media (max-width: 800px) {
    align-items: center;
    justify-items: center;
  }
`;

const IMGcontainer = styled.img`
  @media (max-width: 800px) {
    margin-top: -260px;
  }
`;

export default Profit;
