import React from 'react';
import SubHeader from './SubHeader';
import SubMain from './SubMain';
import Main from './Main';
import Levels from './Levels';
function index() {
  return (
    <>
      <SubHeader />
      <Main />
      <Levels />
      <SubMain />
    </>
  );
}

export default index;
