import React from 'react';
import styled from 'styled-components';

function index() {
  return (
    <>
      <Container>
        <Col>
          <Row>
            <Deco>Invite</Deco>{' '}
            <h1 style={{ color: '#022B69' }}> your friends &</h1>
          </Row>
          <h1 style={{ color: '#022B69' }}>Earn PCL rewards</h1>

          <p style={{ color: '#7D91AE' }}>
            Invite your friends to join the SAIEVE app. <br />
            You and your friend will receive $15 each in PCL.
          </p>
          <Row>
            <button
              style={{
                border: 'none',
                backgroundColor: '#0220BF',
                width: 'fit-content',
                fontSize: '18px',
                padding: '12px 16px',
                borderRadius: '5px',
                color: 'white',
                fontWeight: '700',
              }}
            >
              INVITE NOW
            </button>
          </Row>
        </Col>
        <img
          src="./images/community/sred2.svg"
          style={{ position: 'absolute', right: '0px' }}
        />
        <img
          src="./images/community/blue.svg"
          style={{ position: 'absolute', top: '2px' }}
        />
      </Container>
      <img
        src="./images/community/label.svg"
        style={{ marginTop: '25px', marginBottom: '25px' }}
      />
      <div
        style={{
          width: '100vw',
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          flexWrap: 'wrap',
          marginBottom: '150px',
        }}
      >
        <img
          src="./images/community/blue2.svg"
          style={{ position: 'absolute', left: '0px', top: '50%' }}
        />
        <img src="./images/community/refim.png" />
        <Col style={{ alignSelf: 'center', gap: '20px' }}>
          <h2 style={{ color: '#022B69' }}>How it works</h2>
          <Row>
            <img src="./images/community/1.svg" /> Sign in to SAIEVE app
          </Row>
          <Row>
            <img src="./images/community/2.svg" /> Press the Referral button on
            the top right
          </Row>
          <Row>
            <img src="./images/community/3.svg" /> Invite your friends to
            register for an <br /> account using your unique URL
          </Row>
          <p style={{ fontSize: '12px', color: '#7D91AE' }}>
            *You will find more information about our Referral
            <br />
            Program here
            <span style={{ color: '#0220bf' }}> Terms&Conditions</span>
          </p>
        </Col>
      </div>
    </>
  );
}

const Deco = styled.h1`
  background: #0220bf;
  border-radius: 4px;
  font-weight: bold;
  width: fit-content;
  color: white;
  padding: 4px 10px;
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 220px 120px 20px 120px;
  align-items: flex-start;
  position: relative;
  margin-bottom: 120px;
  @media (max-width: 800px) {
    padding: 10px;
    padding-top: 150px;
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
`;

export default index;
