import React from 'react';
import styled from 'styled-components';
import { Box, Grid, Container } from '@mui/material';

const BusinessModel: React.FC = () => {
  return (
    <StyledContainer>
      <Container fixed maxWidth={'xl'}>
        <WrapperBox1>
          <Title>
            PCL is a new business <br />
            model for <Deco>Money</Deco>, not
            <br /> just a new Business
          </Title>
          <Desc style={{ marginTop: '20px' }}>
            By Giving you ownership of PCL, <br /> we can change wealth
            management,
            <br />
            private banking and finance together.
          </Desc>
        </WrapperBox1>
        <WrapperBox2>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <ItemBox>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="36" height="36" rx="18" fill="#F9FAFB" />
                  <path
                    d="M10.4701 16.7887C9.68469 15.9768 8.38976 15.9553 7.57782 16.7407C6.76589 17.5262 6.74442 18.8211 7.52986 19.6331L10.4701 16.7887ZM28.4701 13.6946C29.2556 12.8827 29.2341 11.5878 28.4222 10.8023C27.6102 10.0169 26.3153 10.0384 25.5299 10.8503L28.4701 13.6946ZM15.7157 23.9373L14.2456 22.5151L15.7157 23.9373ZM7.52986 19.6331L13.0695 25.3595L16.0097 22.5151L10.4701 16.7887L7.52986 19.6331ZM17.1859 25.3595L28.4701 13.6946L25.5299 10.8503L14.2456 22.5151L17.1859 25.3595ZM13.0695 25.3595C14.195 26.5229 16.0603 26.5229 17.1859 25.3595L14.2456 22.5151C14.7279 22.0165 15.5274 22.0165 16.0097 22.5151L13.0695 25.3595Z"
                    fill="url(#paint0_linear_785_5098)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_785_5098"
                      x1="27.4576"
                      y1="24.5452"
                      x2="12.0614"
                      y2="9.20074"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#0420B6" />
                      <stop offset="1" stop-color="#304CE3" />
                    </linearGradient>
                  </defs>
                </svg>
                <span>No more conflict</span>
              </ItemBox>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <ItemBox>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="36" height="36" rx="18" fill="#F9FAFB" />
                  <path
                    d="M10.4701 16.7887C9.68469 15.9768 8.38976 15.9553 7.57782 16.7407C6.76589 17.5262 6.74442 18.8211 7.52986 19.6331L10.4701 16.7887ZM28.4701 13.6946C29.2556 12.8827 29.2341 11.5878 28.4222 10.8023C27.6102 10.0169 26.3153 10.0384 25.5299 10.8503L28.4701 13.6946ZM15.7157 23.9373L14.2456 22.5151L15.7157 23.9373ZM7.52986 19.6331L13.0695 25.3595L16.0097 22.5151L10.4701 16.7887L7.52986 19.6331ZM17.1859 25.3595L28.4701 13.6946L25.5299 10.8503L14.2456 22.5151L17.1859 25.3595ZM13.0695 25.3595C14.195 26.5229 16.0603 26.5229 17.1859 25.3595L14.2456 22.5151C14.7279 22.0165 15.5274 22.0165 16.0097 22.5151L13.0695 25.3595Z"
                    fill="url(#paint0_linear_785_5098)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_785_5098"
                      x1="27.4576"
                      y1="24.5452"
                      x2="12.0614"
                      y2="9.20074"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#0420B6" />
                      <stop offset="1" stop-color="#304CE3" />
                    </linearGradient>
                  </defs>
                </svg>
                <span>No More balancing the impossible</span>
              </ItemBox>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <ItemBox>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="36" height="36" rx="18" fill="#F9FAFB" />
                  <path
                    d="M10.4701 16.7887C9.68469 15.9768 8.38976 15.9553 7.57782 16.7407C6.76589 17.5262 6.74442 18.8211 7.52986 19.6331L10.4701 16.7887ZM28.4701 13.6946C29.2556 12.8827 29.2341 11.5878 28.4222 10.8023C27.6102 10.0169 26.3153 10.0384 25.5299 10.8503L28.4701 13.6946ZM15.7157 23.9373L14.2456 22.5151L15.7157 23.9373ZM7.52986 19.6331L13.0695 25.3595L16.0097 22.5151L10.4701 16.7887L7.52986 19.6331ZM17.1859 25.3595L28.4701 13.6946L25.5299 10.8503L14.2456 22.5151L17.1859 25.3595ZM13.0695 25.3595C14.195 26.5229 16.0603 26.5229 17.1859 25.3595L14.2456 22.5151C14.7279 22.0165 15.5274 22.0165 16.0097 22.5151L13.0695 25.3595Z"
                    fill="url(#paint0_linear_785_5098)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_785_5098"
                      x1="27.4576"
                      y1="24.5452"
                      x2="12.0614"
                      y2="9.20074"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#0420B6" />
                      <stop offset="1" stop-color="#304CE3" />
                    </linearGradient>
                  </defs>
                </svg>
                <span>Customer influenced</span>
              </ItemBox>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ItemBox>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="36" height="36" rx="18" fill="#F9FAFB" />
                  <path
                    d="M10.4701 16.7887C9.68469 15.9768 8.38976 15.9553 7.57782 16.7407C6.76589 17.5262 6.74442 18.8211 7.52986 19.6331L10.4701 16.7887ZM28.4701 13.6946C29.2556 12.8827 29.2341 11.5878 28.4222 10.8023C27.6102 10.0169 26.3153 10.0384 25.5299 10.8503L28.4701 13.6946ZM15.7157 23.9373L14.2456 22.5151L15.7157 23.9373ZM7.52986 19.6331L13.0695 25.3595L16.0097 22.5151L10.4701 16.7887L7.52986 19.6331ZM17.1859 25.3595L28.4701 13.6946L25.5299 10.8503L14.2456 22.5151L17.1859 25.3595ZM13.0695 25.3595C14.195 26.5229 16.0603 26.5229 17.1859 25.3595L14.2456 22.5151C14.7279 22.0165 15.5274 22.0165 16.0097 22.5151L13.0695 25.3595Z"
                    fill="url(#paint0_linear_785_5098)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_785_5098"
                      x1="27.4576"
                      y1="24.5452"
                      x2="12.0614"
                      y2="9.20074"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#0420B6" />
                      <stop offset="1" stop-color="#304CE3" />
                    </linearGradient>
                  </defs>
                </svg>
                <span>Customer Driven</span>
              </ItemBox>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ItemBox>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="36" height="36" rx="18" fill="#F9FAFB" />
                  <path
                    d="M10.4701 16.7887C9.68469 15.9768 8.38976 15.9553 7.57782 16.7407C6.76589 17.5262 6.74442 18.8211 7.52986 19.6331L10.4701 16.7887ZM28.4701 13.6946C29.2556 12.8827 29.2341 11.5878 28.4222 10.8023C27.6102 10.0169 26.3153 10.0384 25.5299 10.8503L28.4701 13.6946ZM15.7157 23.9373L14.2456 22.5151L15.7157 23.9373ZM7.52986 19.6331L13.0695 25.3595L16.0097 22.5151L10.4701 16.7887L7.52986 19.6331ZM17.1859 25.3595L28.4701 13.6946L25.5299 10.8503L14.2456 22.5151L17.1859 25.3595ZM13.0695 25.3595C14.195 26.5229 16.0603 26.5229 17.1859 25.3595L14.2456 22.5151C14.7279 22.0165 15.5274 22.0165 16.0097 22.5151L13.0695 25.3595Z"
                    fill="url(#paint0_linear_785_5098)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_785_5098"
                      x1="27.4576"
                      y1="24.5452"
                      x2="12.0614"
                      y2="9.20074"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#0420B6" />
                      <stop offset="1" stop-color="#304CE3" />
                    </linearGradient>
                  </defs>
                </svg>
                <span>Customer grown</span>
              </ItemBox>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ItemBox>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="36" height="36" rx="18" fill="#F9FAFB" />
                  <path
                    d="M10.4701 16.7887C9.68469 15.9768 8.38976 15.9553 7.57782 16.7407C6.76589 17.5262 6.74442 18.8211 7.52986 19.6331L10.4701 16.7887ZM28.4701 13.6946C29.2556 12.8827 29.2341 11.5878 28.4222 10.8023C27.6102 10.0169 26.3153 10.0384 25.5299 10.8503L28.4701 13.6946ZM15.7157 23.9373L14.2456 22.5151L15.7157 23.9373ZM7.52986 19.6331L13.0695 25.3595L16.0097 22.5151L10.4701 16.7887L7.52986 19.6331ZM17.1859 25.3595L28.4701 13.6946L25.5299 10.8503L14.2456 22.5151L17.1859 25.3595ZM13.0695 25.3595C14.195 26.5229 16.0603 26.5229 17.1859 25.3595L14.2456 22.5151C14.7279 22.0165 15.5274 22.0165 16.0097 22.5151L13.0695 25.3595Z"
                    fill="url(#paint0_linear_785_5098)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_785_5098"
                      x1="27.4576"
                      y1="24.5452"
                      x2="12.0614"
                      y2="9.20074"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#0420B6" />
                      <stop offset="1" stop-color="#304CE3" />
                    </linearGradient>
                  </defs>
                </svg>
                <span>Customer-centric</span>
              </ItemBox>
            </Grid>
          </Grid>
        </WrapperBox2>
      </Container>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  padding-top: 250px;
  height: 100vh;
  padding-bottom: 750px;
  @media (max-width: 1215px) {
    flex-direction: column;
    align-items: center;
    padding: 0px;
    height: fit-content;
  }
`;

const WrapperBox1 = styled(Box)`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1215px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0px;
  }
  @media (max-width: 800px) {
  }
`;

const WrapperBox2 = styled(Box)`
  margin-top: 70px;
  background: linear-gradient(304.2deg, #0420b6 -1.51%, #304ce3 100%);
  padding-left: 20px;
  padding-bottom: 20px;

  @media (max-width: 1215px) {
    flex-direction: column;
    align-items: center;
    margin-top: 135px;
  }
`;

const Title = styled.span`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 150%;
  color: #022b69;
`;

const Deco = styled.span`
  background: #0220bf;
  border-radius: 4px;
  color: white;
  padding: 10px;
`;

const Desc = styled.span`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  color: #7d91ae;
`;

const ItemBox = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 20px;

  span {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #f9fafb;
  }
`;

export default BusinessModel;
