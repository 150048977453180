import React, { useState } from 'react';
import styled from 'styled-components';

interface JobProp {
  title: string;
}

function Job({ title, children }: React.PropsWithChildren<JobProp>) {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <Container>
      <Row>
        <p style={{ color: '#022B69', fontSize: '16px', fontWeight: '600' }}>
          {title}
        </p>
        <a
          style={{
            alignSelf: 'center',
            cursor: 'pointer',
          }}
          onClick={() => setShowInfo(!showInfo)}
        >
          {showInfo ? '▲' : '▼'}
        </a>
      </Row>
      {showInfo && <div>{children}</div>}
    </Container>
  );
}

const Container = styled.div`
  -webkit-box-shadow: -2px 0px 18px -6px rgba(0, 0, 0, 0.75);
  width: 100%;
  padding: 30px;

  -moz-box-shadow: -2px 0px 18px -6px rgba(0, 0, 0, 0.75);
  box-shadow: -2px 0px 18px -6px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export default Job;
