import * as React from 'react';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

interface Props {
  message: string;
  type: 'error' | 'warning' | 'info' | 'success';
}
//@ts-ignore
export default function CustomizedSnackbars({ message, type }: Props) {
  return (
    <Snackbar
      open={true}
      autoHideDuration={3000}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        severity={type}
        sx={{ width: '100%' }}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
}
