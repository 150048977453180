import React from 'react';
import styled from 'styled-components';
import { Grid, Box, Container as CTR } from '@mui/material';

function SubHeader() {
  return (
    <>
      <Container>
        <div
          style={{
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <h1>Become</h1>

          <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
            <h1>a PECULIUM</h1>
            <Angel>
              Angel
              <img
                style={{ position: 'absolute', right: '80%', bottom: '40%' }}
                src="./images/community/1.png"
              />
              <img
                style={{ position: 'absolute', left: '90%', bottom: '10%' }}
                src="./images/community/2.png"
              />
              <img
                style={{ position: 'absolute', right: '10%', bottom: '100%' }}
                src="./images/community/3.png"
              />
            </Angel>
          </div>
          <p>
            Get exclusive access to PELUCIUM teams, news, promotion and missions
            of PECULIUM. Earn rewards for contributing to the PECULIUM Ecosystem
            as a PECULIUM Angel.
          </p>
          <Btn>Apply now</Btn>
        </div>
        <img
          style={{ width: '700px', height: '700px' }}
          src="./images/community/imgangel.svg"
        />
      </Container>
      <CTR fixed maxWidth={'lg'}>
        <Grid
          container
          flexDirection={'row'}
          flexWrap={{ xs: 'wrap', md: 'nowrap' }}
          style={{
            marginTop: '20%',
          }}
          alignItems={'center'}
        >
          <Grid item md={6} xs={12}>
            <div>
              <h1
                style={{
                  color: '#022B69',
                }}
              >
                So what do PECULIUM <br />
                Angels do?
              </h1>
              <p
                style={{
                  color: '#7D91AE',
                }}
              >
                Our Angels employ their valuable skills
                <br /> to help PECULIUM grow. Below are some examples
                <br /> of the accomplished missions.
              </p>
            </div>
          </Grid>

          <Grid
            container
            item
            flexDirection={'row'}
            gap={2}
            flexWrap={{ xs: 'wrap', md: 'nowrap' }}
            md={6}
            style={{ color: '#7D91AE', fontWeight: 'bold' }}
            xs={12}
            justifyContent={'space-around'}
          >
            <Grid
              item
              container
              flexDirection={'column'}
              alignItems={'center'}
              gap={4}
              xs={12}
              md={4}
            >
              <img
                src="./images/community/i1.png"
                style={{ width: '100px', height: 'min-content' }}
              />
              <p>Videos</p>
              <img
                src="./images/community/i2.png"
                style={{ width: '100px', height: 'min-content' }}
              />
              <p>Social media posts</p>
            </Grid>
            <Grid
              item
              container
              flexDirection={'column'}
              gap={4}
              alignItems={'center'}
              xs={12}
              md={4}
            >
              <img
                src="./images/community/i3.png"
                style={{ width: '100px', height: 'min-content' }}
              />
              <p>Competition watch</p>
              <img
                src="./images/community/i4.png"
                style={{ width: '100px', height: 'min-content' }}
              />
              <p>Tutorials</p>
            </Grid>
            <Grid
              item
              container
              gap={4}
              flexDirection={'column'}
              alignItems={'center'}
              xs={12}
              md={4}
            >
              <img
                src="./images/community/i5.png"
                style={{ width: '100px', height: 'min-content' }}
              />
              <p>websites and tools</p>
              <img
                src="./images/community/i6.png"
                style={{ width: '100px', height: 'min-content' }}
              />
              <p>Blogs and reviews</p>
            </Grid>
          </Grid>

          {/* tttttttttttt */}
        </Grid>
      </CTR>
    </>
  );
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  background-image: url('./images/community/BGangels.png');
  background-repeat: no-repeat;
  flex-direction: row;
  justify-content: center;
  background-size: 80% 100%;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 55px;
  padding: 50px;
  padding-top: 80px;
  @media (max-width: 1215px) {
    flex-wrap: wrap;
    background-size: 100% 100%;
  }
`;

const Angel = styled.div`
  background-color: #0220bf;
  width: fit-content;
  height: fit-content;
  font-size: 34px;
  font-weight: bold;
  padding: 0px 15px 0px 15px;
  align-self: center;
  border-radius: 4px;
  position: relative;
`;

const Btn = styled.button`
  background-color: #f5f9ff;
  padding: 15px;
  color: #0220bf;
  width: fit-content;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
`;

export default SubHeader;
