import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';

const SmartHolding: React.FC = () => {
  return (
    <StyledContainer>
      <DescBox>
        <TitleBox>
          SMART HOLDING
          <BadgeBox>soon</BadgeBox>
        </TitleBox>
        <SubTitle>Bull or Bear, Earn Anywhere</SubTitle>
        <ComBox>
          <b>Smart holding strategy</b> provides investors with exposure to the
          top 10 Coins, while limiting loss of capital through a dynamic cash
          hedging mechanism.
        </ComBox>
        <Box
          display="flex"
          columnGap={'16px'}
          marginTop={'32px'}
          justifyContent={{ xs: 'left' }}
        >
          <FuncButton>Low risk</FuncButton>
          <FuncButton>Stable earnings</FuncButton>
        </Box>
      </DescBox>
      <Box>
        <img src="./images/coins.svg" alt="Coins" />
      </Box>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  margin-top: 150px;
  padding: 0px;
  display: flex;
  column-gap: 28px;
  align-items: center;
  @media (max-width: 1215px) {
    flex-direction: column-reverse;
    align-items: center;
    gap: 135px;
    margin-top: 135px;
    text-align: center;
  }
`;

const DescBox = styled(Box)`
  padding: 0px;
`;

const TitleBox = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 150%;
  color: #022b69;

  display: flex;
  align-items: flex-start;
  column-gap: 4px;
  @media (max-width: 800px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const BadgeBox = styled(Box)`
  margin-top: -10px;
  padding: 3px 7px;
  background: linear-gradient(304.2deg, #9a70e3 -1.51%, #a680e6 100%);
  border-radius: 2px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #f9fafb;
`;

const SubTitle = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: #303030;
`;

const ComBox = styled(Box)`
  margin-top: 30px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #7d91ae;
`;

const FuncButton = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(27, 175, 158, 0.1);
  border-radius: 4px;
  padding: 8px 12px;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #31b59b;

  &:hover {
    opacity: 0.7;
  }

  cursor: pointer;
`;

export default SmartHolding;
