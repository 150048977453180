import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Container as CTR,
} from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import Job from './Job';

function Jobs() {
  return (
    <div>
      <CTR fixed maxWidth={'lg'}>
        <Container>
          {/*<Row>
            <h4 style={{ color: '#022B69' }}>Filter by</h4>
            <FormControl style={{ width: '20%' }} size="small">
              <InputLabel id="demo-select-small">Country</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                label="Age"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Morocco</MenuItem>
                <MenuItem value={20}>France</MenuItem>
                <MenuItem value={30}>other countries ...</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{ width: '20%' }} size="small">
              <InputLabel id="demo-select-small">Departement</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                label="Age"
              >
                <MenuItem value={10}>Engineering</MenuItem>
                <MenuItem value={20}>FINANCE</MenuItem>
                <MenuItem value={30}>Design</MenuItem>
                <MenuItem value={30}>Product</MenuItem>
                <MenuItem value={30}>Trading</MenuItem>
                <MenuItem value={30}>Marketing</MenuItem>
              </Select>
            </FormControl>
            <img
              style={{ marginLeft: '30%' }}
              src="./images/about/blocks.svg"
            />
          </Row>*/}
          <h2 style={{ color: '#022B69' }}>Current Openings</h2>
          <Job title="Customer Success Associate (Apprenticeship)">
            <H3>Job description:</H3>
            <Desc>
              PECULIUM is looking for a customer success manager that will respond to users’ inquiries via our ticketing system. This role is responsible for resolving all inquiries that our users might have in a professional and timely manner. In this role, you will report directly to your assigned team leader. Our ideal candidate will be highly organized, multi-tasker, detail-oriented, energetic, and self-motivated in a very fast-paced environment who has a can-do attitude and has previous experience in customer support.
            </Desc>
            <H3>Your mission:</H3>
            <Desc>
              <StyledUl>
                <li>Build and foster long-term relationships with our community members and customers
                  while driving engagement, retention, and growth.
                </li>
                <li>Ensure customer satisfaction & reply to day to day customers' requests: Be the main point of contact
                  of our customers to answer their questions and requests in a timely manner (phone, emails, chat).
                </li>
                <li>Gather customer product feedback to understand the problems and challenges of user and identify ways the business
                  could better address those needs with special emphasis on activation.
                </li>
                <li>Identify measurable goals and timelines to help customers onboarding and grow according to KYC/AML procedures
                  and work closely with the Compliance team to identify fraud issues
                </li>
                <li>Participate in developing and reshaping our (internal) customer support/processes tools.
                </li>
                <li>Make video stories and social media posts to evangelize customer success stories.
                </li>
                <li>Analyze current trends (market, product performance,...) & improve the customer support content
                  (FAQ, Tutorials, YouTube playlists) in order to always have the best answers for our customers
                </li>
                <li>Work with engineering and product teams to develop and share best practices with customers with specific use
                  cases. Proactively support your customers by driving business outcomes, identifying opportunities, and surfacing
                  feature requests and insights to our product and engineering teams.
                </li>
                <li>Monitor and identify adoption, renewals and growth trends for the team and customers to develop a customer success strategy.
                </li>
                <li>Provide operational oversight of the business to deliver on targets and KPIs.
                </li>
                <li>Provide excellent service to maintain a positive reputation for the business.
                </li>
              </StyledUl>
            </Desc>
            <H3>About You: Minimum qualifications</H3>
            <Desc>
              <StyledUl>
                <li>BSc/MSc BA in Business Administration or similar field student looking for a work-study program
                </li>
                <li>An understanding of blockchain technology and crypto assets</li>
                <li>1-2 years of industry experience in a client-facing role
                </li>
                <li>Eagerness to learn and help PECULIUM scale
                </li>
                <li>Experience with sales and/or conversion
                </li>
                <li>The ability to network and build relationships
                </li>
                <li>Excellent interpersonal and communication skills
                </li>
                <li>Excited to work in an early-stage company and have a high impact on the future of the company’s growth
                </li>
              </StyledUl>
            </Desc>
            <H3>Additional Information:</H3>
            <Desc>
              <StyledUl>
                <li>Contract Type: Work-study program - Alternance (12 months).</li>
                <li>Start Date: ASAP</li>
                <li>Location: Paris, France , can be remote role for a candidate in EU.</li>
              </StyledUl>
              <Desc>
                PECULIUM is committed to continuing to make diversity, equity, and inclusion part of everything we do—from how
                we build our products to how we build our workforce. Our team is represented by 13 nationalities.
              </Desc>
            </Desc>
            <StyledLink
              href="mailto:careers@peculium.io?subject=Customer Success Associate (Apprenticeship)"
            >
              Apply now
            </StyledLink>
          </Job>
          <Job title="SEO specialist (Apprenticeship)">
            <H3>Job description:</H3>
            <Desc>
              PECULIUM is looking for SEO specialist, who will participate in building our strategy and executing a
              roadmap to optimize our acquisition programs. You will work with a dedicated cross-functional team
              including design, content, marketing, and engineering to rapidly develop experimentation, optimizations,
              and new content across our website, blog, Google and app store pages.
            </Desc>
            <Desc>
              This includes utilizing SEO to build new programs to further grow and optimize our acquisition efforts.
              Overall, you will focus on driving increased organic traffic. You'll balance tactical execution with
              informing our Marketing team's overall strategy and approach, working across all of PECULIUM's offerings.
            </Desc>
            <Desc>
              The Marketing team at PECULIUM holistically measures and improves the flow of clients into and within the
              product. We drive traffic to PECULIUM, improve the conversion rate of visitors to clients, and drive
              increased engagement. Our approach is defined by addressing low-hanging fruit opportunities that we have
              high conviction will deliver results, and testing high-impact hypotheses to drive learnings and growth.
            </Desc>
            <H3>Responsibilities</H3>
            <Desc>
              <StyledUl>
                <li>
                  Partcipate in building a strategy to optimize our acquisition programs including the following: lead generation, content marketing, search engine optimization and landing page experimentation.
                </li>
                <li>
                  Deliver feature roadmap through sprint cycles, breaking down the product roadmap into executable stories and requirements for engineering stakeholders.
                </li>
                <li>
                  Analyze PECULIUM's SEO and landing page performance, identify opportunities, and experiment with solutions to drive improvements.
                </li>
                <li>
                  Analyse Research SEO keywords of the industry and optimize PECULIUM search engine
                </li>
                <li>
                  Support the strategy of funnel optimization including consumer research, product development, and design.
                </li>
              </StyledUl>
            </Desc>
            <H3>About You</H3>
            <Desc>
              <StyledUl>
                <li>
                  Familiar with the crypto industry is a big bonus
                </li>
                <li>
                  Understanding of search engine algorithms and ranking methods
                </li>
                <li>
                  Ability to conduct competitive analysis and market watch trends
                </li>
                <li>
                  Direct experience driving customer acquisition: Lead Generation, Content Marketing, Landing Page Experimentation, and SEO (including HTML and technical SEO).
                </li>
                <li>
                  Familar web analytics tools (e.g., Google Analytics, Google Search Console, Semrush, Ahrefs, Hotjar)
                </li>
                <li>
                  Previous A/B testing experience preferably
                </li>
                <li>
                  Strategic thinking capabilities with an action-oriented mindset
                </li>
                <li>
                  Fluency in English
                </li>
              </StyledUl>
            </Desc>
            <H3>Preferred qualifications</H3>
            <Desc>
              <StyledUl>
                <li>Good knowledge about distributed systems and algorithms is a must.</li>
                <li>Having the willingness to take leadership on the projects assigned to you and take the challenge to do what it takes to make done and good</li>
                <li>Have the courage to challenge people on every aspect in order to take the quality extra miles.
                  We don’t care from which background you’re, we are not looking for people who follow order but for people who move further, ambitious, creative, high-powered and willing to learn and explore.</li>
              </StyledUl>
            </Desc>
            <H3>Additional Information:</H3>
            <Desc>
              <StyledUl>
                <li>Contract Type: Internship (6 month(s)).</li>
                <li>Start Date: May 2022.</li>
                <li>Location: Paris, France.</li>
                <li>Education Level: Last-Year of studies level.</li>
                <li>Experience: 6 months </li>
              </StyledUl>
            </Desc>
            <StyledLink
              href="mailto:careers@peculium.io?subject=SEO specialist (Apprenticeship)"
            >
              Apply now
            </StyledLink>
          </Job>
          <Job title="Senior Back-End Engineer">
            <H3>Your mission:</H3>
            <Desc>
              <StyledUl>
                <li>
                  Ensure development of the infrastructure line of  our products (Mobile Application, Web Application).
                </li>
                <li>
                  Participate in writing and design the next generation of our applications.
                </li>
                <li>
                  Interact with the business and Product unit to translate a vision to specifications.
                </li>
                <li>
                  Spread best class practices within the team.
                </li>
                <li>
                  Work in tandem with the rest of the team to ensure building insanely great products.
                </li>
              </StyledUl>
            </Desc>
            <H3>About You: Minimum qualifications</H3>
            <Desc>
              <StyledUl>
                <li>
                  4-5 year of experience in any of the languages: Python, Go, Rust, Javascript.
                </li>
                <li>
                  Experience with Product Development cycles (Review Processes, Agile frameworks, CI/CD)
                </li>
                <li>
                  Experience with taking ownership of projects.
                </li>
                <li>
                  Experience with blockchain is a plus.
                </li>
                <li>
                  Familiarity with distributed systems.
                </li>
              </StyledUl>
            </Desc>
            <H3>Additional Information:</H3>
            <Desc>
              <StyledUl>
                <li>Start Date: ASAP</li>
                <li>Location: Paris, France , can be remote role for a candidate in EU.</li>
              </StyledUl>
            </Desc>
            <Desc>
              PECULIUM is committed to continuing to make diversity, equity, and inclusion part of everything we do—from how we build our products to how we build our workforce. Our team is represented by 13 nationalities.
            </Desc>
            <StyledLink
              href="mailto:careers@peculium.io?subject=Senior Back-End Engineer"
            >
              Apply now
            </StyledLink>
          </Job>
          <Job title="Senior Front-End Engineer">
            <H3>Your mission:</H3>
            <Desc>
              <StyledUl>
                <li>
                  Ensure development of our front line of products (Mobile Application, Web Application).
                </li>
                <li>
                  Participate in writing and design the next generation of our applications.
                </li>
                <li>
                  Interact with the business and UX unit to translate a vision to specifications.
                </li>
                <li>
                  Spread best class practices within the team.
                </li>
                <li>
                  Work in tandem with the rest of the team to ensure building insanely great products.
                </li>
                <li>
                  Create innovative UI elements, stunning JavaScript contraptions, and outstanding web development work.
                </li>
              </StyledUl>
            </Desc>
            <H3>About You: Minimum qualifications</H3>
            <Desc>
              <StyledUl>
                <li>
                  4-5 year of experience in React, Typescript and HTML5.
                </li>
                <li>
                  Experience with Product Development cycles (Review Processes, Agile frameworks, CI/CD)
                </li>
                <li>
                  Experience with taking ownership of projects.
                </li>
                <li>
                  Experience with blockchain is a plus.
                </li>
              </StyledUl>
            </Desc>
            <H3>Additional Information:</H3>
            <Desc>
              <StyledUl>
                <li>Start Date: ASAP</li>
                <li>Location: Paris, France , can be remote role for a candidate in EU.</li>
              </StyledUl>
            </Desc>
            <Desc>
              PECULIUM is committed to continuing to make diversity, equity, and inclusion part of everything we do—from how we build our products to how we build our workforce. Our team is represented by 13 nationalities.
            </Desc>
            <StyledLink
              href="mailto:careers@peculium.io?subject=Senior Front-End Engineer"
            >
              Apply now
            </StyledLink>
          </Job>
          <Job title="Customer Success Associate">
            <H3>About the Role:</H3>
            <Desc>
              We’re looking for a Customer Success Associate to join our team and take on responsibilities including:
            </Desc>
            <Desc>
              <StyledUl>
                <li>
                  Quickly build trust with clients through leading a best-in-class onboarding experience
                </li>
                <li>
                  Responsible for managing client communication and engagement during the first portion of the client journey
                </li>
                <li>
                  Drive revenue and customer engagement - drive our pipeline of leads into completed applications and convert applications into funded accounts
                </li>
                <li>
                  Actively engage with our customer base from point of application through underwriting and funding
                </li>
                <li>
                  Assess client satisfaction and extract key business needs/issues to inform future renewal and churn risk
                </li>
                <li>
                  Align with leadership regularly to discuss and optimize account onboarding strategy and share customer feedback across functions
                </li>
                <li>
                  Demonstrate a proactive mindset, constantly thinking ahead and anticipating client needs and/or concerns, while keeping their business goals top-of-mind
                </li>
                <li>
                  Attend crypto events to spread the word about Peculium and source potential customers
                </li>
              </StyledUl>
            </Desc>
            <H3>About You:</H3>
            <Desc>
              <StyledUl>
                <li>
                  1-2 years of industry experience in a client-facing role
                </li>
                <li>
                  An understanding of blockchain technology and crypto assets
                </li>
                <li>
                  Eagerness to learn and help Peculium scale
                </li>
                <li>
                  Experience with sales and/or conversion
                </li>
                <li>
                  The ability to network and build relationships
                </li>
                <li>
                  Excellent interpersonal and communication skills
                </li>
                <li>
                  Excited to work in an early-stage company and have a high impact on the future of the company’s growth
                </li>
              </StyledUl>
            </Desc>
            <Desc>
              This position provides a large opportunity for professional growth as we scale our organization. The individual who joins our team should expect to scale with our company and be ready to take on larger responsibilities over time. The position will report directly to our COO.
            </Desc>
            <StyledLink
              href="mailto:careers@peculium.io?subject=Customer Success Associate"
            >
              Apply now
            </StyledLink>
          </Job>
          <Job title="Community Manager">
            <H3>About the role:</H3>
            <Desc>
              PECULIUM is looking to hire a Community Manager to join their team. This is a full-time position that is 100% remote with no geographical restrictions.
            </Desc>
            <Desc>
              <h5>Strategy</h5>
              <StyledUl>
                <li>
                  Set up the right systems and processes to ensure successful community building and participation
                </li>
                <li>
                  Develop and implement community growth and management strategies that drive community engagement and increase PECULIUM brand awareness and user acquisition.
                </li>
                <li>
                  Ensure community strategy is aligned with broader marketing strategy, events and materials and help create a unified community narrative
                </li>
              </StyledUl>
              <h5>Operations</h5>
              <StyledUl>
                <li>
                  Conceptualize, categorize, visualize, edit and execute every piece of social content that goes out to the community.
                </li>
                <li>
                  Work with BNB chain community day-to-day across Twitter, Telegram and other channels to activate existing audience, drive new users
                </li>
                <li>
                  Create and manage social distribution plans and schedules that align with wider marketing and business efforts and the social media manager.
                </li>
                <li>
                  Define and track key performance indicators and use these to report on your strategies and campaigns to the rest of the team and management.
                </li>
                <li>
                  Put our community first, always. Make a positive impact in the hearts of our direct supporters and the larger community.
                </li>
                <li>
                  Develop our social customer care strategy in partnership with our support/customer success team.
                </li>
              </StyledUl>
              <h5>Collaboration</h5>
              <StyledUl>
                <li>
                  Create synergies with the Social Media team to initiate joint projects which feature a high integration of social.
                </li>
                <li>
                  Network with industry professionals and influencers through social media outreach and leverage these relationships to benefit our PR and marketing efforts.
                </li>
                <li>
                  Work with our product team to surface valuable customer feedback which can inform new product developments.
                </li>
                <li>
                  Collaborate with Marketing and Product development teams to create social ‘buzz’ regarding new product launches, events, and specific campaigns.
                </li>
              </StyledUl>
            </Desc>
            <H3>About You:</H3>
            <Desc>
              <StyledUl>
                <li>
                  3-5 years of work experience in community management and/or strategy in technology, finance and crypto.
                </li>
                <li>
                  Demonstrated self-starter, with the ability to manage large communities and make them successful
                </li>
                <li>
                  Deep understanding of and genuine excitement about the crypto community
                </li>
                <li>
                  Strong English communication skills, both written and verbal
                </li>
                <li>
                  Experience prioritizing tasks, managing deadlines, adapting with changing priorities, and balancing short-term needs with long-term strategic initiatives.
                </li>
                <li>
                  Proven ability to work in multidisciplinary teams with copywriters, designers, customer support, product managers, etc.
                </li>
                <li>
                  Ability to deal with negative sentiment diplomatically, and convert detractors to supporters.
                </li>
                <li>
                  Ability to use data to communicate and report how social goals, strategy and results, and how these relate to wider business aims.
                </li>
                <li>
                  Ability to work with visuals, simple gifs, PS assets, videos, etc.
                </li>
              </StyledUl>
            </Desc>
            <StyledLink
              href="mailto:careers@peculium.io?subject=Community Manager"
            >
              Apply now
            </StyledLink>
          </Job>
          <Job title="Product Marketing Manager">
            <H3>Job description</H3>
            <Desc>
              PECULIUM is looking for an experienced Product Marketing Manager to launch and grow our new investment
              products. As the newest member of the rapidly growing Marketing org at PECULIUM, you’ll help shape a
              world-class Product Marketing team while diving deep into customer insights, crafting product narratives,
              leading go-to-market efforts, and identifying new growth opportunities. You’ll work closely with partners
              across the company, in Product, Engineering, Design, Analysts, and others.
            </Desc>
            <Desc>
              This is an ideal opportunity for a senior product marketer looking to make a big impact, get their hands
              dirty and help build a team from the ground up. You’ll help us build and grow financial products that
              favor people, not institutions.
            </Desc>
            <H3>About the role</H3>
            <Desc>
              <StyledUl>
                <li>
                  Lead go-to-market planning and launches for new and existing products that drive full-funnel measurable results, rooted in data and insights
                </li>
                <li>
                  Partner with consumer, product, design, and Engineering teams to deeply understand audiences and uncover new opportunities
                </li>
                <li>
                  Develop positioning, audience, and channel strategies for new and existing products that drive measurable growth
                </li>
                <li>
                  Act as a key business leader and partner to product and management teams in defining roadmaps, market sizes, and consumer needs
                </li>
                <li>
                  Drive and maintain competitive reporting to keep internal stakeholders informed on key trends and shifts in our industry
                </li>
                <li>
                  Be a key liaison with external agencies and creative teams in bringing marketing briefs and strategies to life, ensuring consistent messaging across channels
                </li>
                <li>
                  Be a voracious consumer of culture and help highlight trends that can inform our creative strategy
                </li>
                <li>
                  Lead business performance recaps and findings with the executive leadership team
                </li>
                <li>
                  Partner closely with cross-functional leaders across performance marketing, product, design, engineering, and data science
                </li>
              </StyledUl>
            </Desc>
            <H3>Qualifications</H3>
            <Desc>
              <StyledUl>
                <li>
                  Bachelor's degree or higher
                </li>
                <li>
                  5+ years in consumer product marketing, with technology and/or startup experience
                </li>
                <li>
                  Passionate storyteller with strong communication, presentation, and writing skills
                </li>
                <li>
                  Experience taking new consumer products to market and crafting compelling messaging and/or briefs
                </li>
                <li>
                  Strategic thinker who equally values creativity and marketing effectiveness
                </li>
                <li>
                  Passion for solving hairy problems and driven by an audacious mission
                </li>
                <li>
                  Thrives as a self-starter in faced paced environments
                </li>
                <li>
                  Crypto knowledge/experience is a big bonus
                </li>
              </StyledUl>
            </Desc>
            <StyledLink
              href="mailto:careers@peculium.io?subject=Product Marketing Manager"
            >
              Apply now
            </StyledLink>
          </Job>
          <Job title="Partnerships Manager">
            <H3>About the Role:</H3>
            <Desc>
              We are looking for a Strategic Partnerships Manager to help drive the commercial success of PECULIUM's existing and new partnerships.  The Strategic Partnership Manager facilitates a strong sense of relationships between PECULIUM and external stakeholders while spotting and implementing business opportunities to drive the business forward.
            </Desc>
            <H3>Responsibilities:</H3>
            <Desc>
              <StyledUl>
                <li>
                  To act independently and take ownership of driving all types of sales efforts for the PECULIUM’s full suite of investments and asset management products and services across the desired products and asset classes, including client acquisition and retention, business development and lead generation, conversation opening, onboarding, post-sales client services, cross-sales, repurchase and strategic partnerships
                </li>
                <li>
                  To understand Client’s business needs and objectives by conducting thorough research, understanding Client’s buying motivators, decision criteria, investment propensity before pitching the Company’s competitive product/positioning to cater to Client’s requirements and win investment mandates
                </li>
                <li>
                  To develop new institutional prospects globally across the United States
                </li>
                <li>
                  To maintain existing portfolio of partners including but not limited to blockchain companies, crypto exchanges, and institutional investors
                </li>
                <li>
                  To keep track of client activities and stay updated with clients’ trading interest and risk appetite to devise tailored client engagement strategies and produce market intelligence report
                </li>
                <li>
                  Applies business acumen in planning by considering macro and micro factors, with a focus on Client’s needs
                </li>
                <li>
                  Develop sales pipelines to exceed sales target
                </li>
                <li>
                  To work closely with Investment teams and look out for new market trends, adhoc trading opportunities and to come up with investment and trading ideas
                </li>
                <li>
                  To generate marketing and PR content and other marketing efforts to constantly improve brand awareness in the region
                </li>
                <li>
                  Build a pipeline of internet startups (e.g. payment companies, mobile games etc.) than can benefit from the PECULIUM’s offering
                </li>
                <li>
                  Identity, research and establish partnerships with crypto actors that can contribute to the PECULIUM ecosystem
                </li>
                <li>
                  Work with the marketing team to help drive various engagement channels
                </li>
                <li>
                  Support business and developer team on various product and community projects
                </li>
                <li>
                  Organize live sessions to build a community
                </li>
              </StyledUl>
            </Desc>
            <H3>About you</H3>
            <Desc>
              <StyledUl>
                <li>
                  Master’s degree in Business, Finance, or STEM degree
                </li>
                <li>
                  1-3 years of experience in strategic partnerships, GTM growth campaigns, and driving B2B2C/B2C opportunities
                </li>
                <li>
                  Knowledge about the cryptocurrency industry: main players, market segments, etc.
                </li>
                <li>
                  Prior crypto and web3 experience is strongly preferred
                </li>
                <li>
                  Familiarity with and interest in capital markets.
                </li>
                <li>
                  Proven analytical skills and financial acumen
                </li>
                <li>
                  Outstanding communication and presentation skills
                </li>
              </StyledUl>
            </Desc>
            <StyledLink
              href="mailto:careers@peculium.io?subject=Partnerships Manager"
            >
              Apply now
            </StyledLink>
          </Job>
          <Job title="Graphic/Visual Designer">
            <br/>
            <Desc>
              Designers at PECULIUM are empowered to be innovative, creative and strategic because Design is critical to
              realizing our vision and succeeding as a business. They imagine unexpected ways to illustrate complicated
              financial concepts and engage ways to bring our brand promise to life with conceptual flair and impeccable
              execution.
            </Desc>

            <Desc>
              PECULIUM is looking for a talented Graphic Designer to create best-in-class social and marketing visuals
              and reinforce our amazing brand. This new role is central. You'll work closely with a variety of profiles
              within the core team: Product, Design, Content, Marketing, and Growth.
            </Desc>
            <H3>About the Role:</H3>
            <Desc>
              <StyledUl>
                <li>
                  A challenging role translating complex ideas into clear, concise content to make crypto investing more approachable and relatable.
                </li>
                <li>
                  Produce digital designs and social motion graphics to accompany all types of online marketing collateral.
                </li>
                <li>
                  Explore ideas to define & build a cohesive brand while maintaining design consistency between product & brand strategy.
                </li>
                <li>
                  Support product design squads by creating landing pages, logos, motion designs and illustrations for our new projects.
                </li>
                <li>
                  Participate in cross-team initiatives with core team members such as community building, employer branding, and events.
                </li>
              </StyledUl>
            </Desc>
            <H3>About you</H3>
            <Desc>
              <StyledUl>
                <li>
                  Bachelor's degree in creative arts, graphic design, and any other related course.
                </li>
                <li>
                  Minimum 3+ years of experience.
                </li>
                <li>
                  You have strong design skills and have already worked for creative brands.
                </li>
                <li>
                  Experience working in digital, print and non-traditional media, including collaborating with developers and doing press checks.
                </li>
                <li>
                  Proficiency with design tools such as Figma, Sketch, InVision, and similar.
                </li>
                <li>
                  You have big out-of-the-box ideas that translate into unique brand experiences that make a lasting impact.
                </li>
                <li>
                  A passion for branding and a collaborative spirit.
                </li>
                <li>
                  Experience with crypto and fin-tech.
                </li>
              </StyledUl>
            </Desc>
            <H3>Big Bonuses</H3>
            <Desc>
              <StyledUl>
                <li>
                  Illustration skills.
                </li>
                <li>
                  Some combination of motion graphics, illustration, photography/videography, and/or video editing skills a plus.
                </li>
                <li>
                  A portfolio featuring branding work.
                </li>
              </StyledUl>
            </Desc>
            <StyledLink
              href="mailto:careers@peculium.io?subject=Graphic/Visual Designer"
            >
              Apply now
            </StyledLink>
          </Job>
          <Job title="Senior Product Designer">
            <H3>About the Role:</H3>
            <Desc>
              PECULIUM is looking for a senior or staff product designer to help bring to life the future of investing, helping a new generation begin their journey of building long-term wealth.
            </Desc>

            <Desc>
              PECULIUM pioneered automated crypto investing by offering a proven track-record active strategy called BELIEVE. But that was only the beginning of a much broader vision that explores how we can give people substantially more control over how they invest while still making it effortless and confidence-inspiring.
            </Desc>
            <Desc>
              To help deliver on this vision, you’ll lead projects that introduce new types of investments to PECULIUM, help people navigate a growing universe of what they can invest in, and help people make smart investment decisions.
            </Desc>
            <Desc>
              Your work will be a key pillar in our strategy to acquire new clients and expand our share of wallet. You’ll work with a high-performing cross-functional team with deep expertise in Product, Engineering, Marketing, and Investments Research. Success in this role requires a healthy balance of excellence in interaction and visual craft as well as product vision.
            </Desc>
            <H3>Design at PECULIUM</H3>
            <Desc>
              Unlike many companies where designers are trained to make incremental changes and work through a chain of hierarchy to advocate for bold new ideas, designers at PECULIUM are expected to be innovative, creative and strategic, because design is critical to realizing our vision. Designers are expected to define problems, new opportunities and solutions that are deeply rooted in human psychology and behavior as well as a sound understanding of our technology and business, all in very tight collaboration with engineers, product managers, researchers and data scientists. In an environment where we’re making big bets, and innovation is encouraged, we embrace failure and care much more about the magnitude of success versus percentage of times we are successful. And unlike many organizations, design is an equal partner to engineering and product and plays an important role in the executive staff that collectively make all critical strategic decisions.
            </Desc>
            <Desc>
              This role has the option to be located in Paris, France or remote.
            </Desc>
            <H3>Responsibilities</H3>
            <Desc>
              <StyledUl>
                <li>
                  Lead design projects across the product development lifecycle—from initial exploration and definition through to shipping.
                </li>
                <li>
                  Partner with cross-functional leads to influence team vision, strategy, and roadmap.
                </li>
                <li>
                  Collaborate with broader design team to share feedback and elevate overall product quality.
                </li>
                <li>
                  Collaborate with research teams to identify new opportunities, build and test hypotheses and refine design decisions.
                </li>
                <li>
                  Mentor and support design team members.
                </li>
              </StyledUl>
            </Desc>
            <H3>About you</H3>
            <Desc>
              <StyledUl>
                <li>
                  3-5+ years of experience in a product design role in consumer tech.
                </li>
                <li>
                  Experience working across the product development lifecycle and successfully shipping products.
                </li>
                <li>
                  Experience turning complex problems into simple and engaging customer experiences for web and mobile.
                </li>
              </StyledUl>
            </Desc>
            <StyledLink
              href="mailto:careers@peculium.io?subject=Senior Product Designer"
            >
              Apply now
            </StyledLink>
          </Job>
          <Job title="Blockchain Developer">
            <H3>About the Role:</H3>
            <Desc>
              <StyledUl>
                <li>
                  Preparation of detailed design documents and testing strategies according to requirements.
                </li>
                <li>
                  Promoting technical experience sharing within the team.
                </li>
                <li>
                  Work with partners on blockchain integrations and solutions.
                </li>
                <li>
                  Support the community through the best communications and documentation.
                </li>
                <li>
                  Contribute to researching ways to decentralize the company and its products.
                </li>
                <li>
                  Collaborate with other teams to deliver new and innovative features that improve the user interactions with the Blockchain.
                </li>
              </StyledUl>
            </Desc>
            <H3>About you</H3>
            <Desc>
              <StyledUl>
                <li>
                  Backend / Software development background, i.e. 3 years experience minimum.
                </li>
                <li>
                  Past projects portfolio on Blockchain technologies: Existing track record of execution of open source projects on known blockchain platforms such as Ethereum and Bitcoin is a great plus.
                </li>
                <li>
                  Experience in Web3 technologies and smart contract development.
                </li>
                <li>
                  Eagerness to develop new skills and explore new problem spaces on a daily basis.
                </li>
                <li>
                  Proficiency in Solidity, Golang, Rust, C++, Java, NodeJS and other mainstream blockchain development languages.
                </li>
                <li>
                  A first development experience in innovative financial applications (on-chain).
                </li>
                <li>
                  Experience in developing Decentralized Applications (Dapps) in Solidity or similar.
                </li>
                <li>
                  Knowledge of L2 technologies such as Polygon, StarkNet, ZKSync and Arbitrum.
                </li>
                <li>
                  Experience integrating with CEXs/DEXs.
                </li>
                <li>
                  Familiarity with DAO.
                </li>
                <li>
                  Knowledge in the economic and financial models and token-based economies.
                </li>
                <li>
                  Solid Knowledge in the challenges and technologies of crypto-currencies.
                </li>
                <li>
                  Expertise in API automation is a strong plus.
                </li>
                <li>
                  Strong proficiency at troubleshooting Tech but also business-related issues is a big plus.
                </li>
              </StyledUl>
            </Desc>
            <StyledLink
              href="mailto:careers@peculium.io?subject=Blockchain Developer"
            >
              Apply now
            </StyledLink>
          </Job>
          <Job title="Sales Director">
            <H3>About the Role:</H3>
            <Desc>
              <StyledUl>
                <li>
                  Perform market research, mapping and sizing for the EU market
                </li>
                <li>
                  Scout and develop new business leads across the client, channel or partner spectrum.
                </li>
                <li>
                  Set, track and achieve/exceed sales, adoption and engagement targets, aligned with company objectives, over 4-monthly periods.
                </li>
                <li>
                  Accelerate customer adoption through education and engagement.
                </li>
                <li>
                  Be the trusted partner and the single point of contact for accounts and coordinate the activities that help them achieve and experience long-term success with the company's value propositions.
                </li>
                <li>
                  Contribute to the creation and leveraging of marketing assets.
                </li>
                <li>
                  Capture client feedback and transfer/explain it to the product team.
                </li>
                <li>
                  Monitor and analyse sales, adoption and engagement metrics.
                </li>
                <li>
                  Suggest actions to improve sales and engagement performance and identify opportunities for growth.
                </li>
              </StyledUl>
            </Desc>
            <H3>About you</H3>
            <Desc>
              <StyledUl>
                <li>
                  Bachelor's degree in creative arts, graphic design, and any other related course.
                </li>
                <li>
                  Minimum 3+ years of experience.
                </li>
                <li>
                  You have strong design skills and have already worked for creative brands.
                </li>
                <li>
                  Experience working in digital, print and non-traditional media, including collaborating with developers and doing press checks.
                </li>
                <li>
                  Proficiency with design tools such as Figma, Sketch, InVision, and similar.
                </li>
                <li>
                  You have big out-of-the-box ideas that translate into unique brand experiences that make a lasting impact.
                </li>
                <li>
                  A passion for branding and a collaborative spirit.
                </li>
                <li>
                  Experience with crypto and fin-tech.
                </li>
              </StyledUl>
            </Desc>
            <H3>As part of your role, your day-to-day activities will include:</H3>
            <Desc>
              <StyledUl>
                <li>
                  Build with the founder: the commercial strategy, our value proposition and the negotiation pitch.
                </li>
                <li>
                  Optimize our sales CRM to maintain well organized data and improve the team’s efficiency.
                </li>
                <li>
                  Recruit high sales performer.
                </li>
                <li>
                  Create an inspiring team environment with an open communication culture.
                </li>
                <li>
                  Set clear sales goals.
                </li>
                <li>
                  Oversee day-to-day sales operation.
                </li>
                <li>
                  Monitor sales team performance and report on metrics.
                </li>
                <li>
                  Experience with crypto and fin-tech.
                </li>
              </StyledUl>
            </Desc>
            <H3>Requirements</H3>
            <Desc>
              <StyledUl>
                <li>
                  Proven work experience as an inside sales manager or supervisor 3 years minimum.
                </li>
                <li>
                  Experience of working in Sales for an early stage, fast growth, technology startup, ideally in B2B, B2C Apps and/or Fintech.
                </li>
                <li>
                  Excellent sales experience in cold calling
                </li>
                <li>
                  In-depth knowledge of performance metrics
                </li>
                <li>
                  CRM (Hubspot, Intercom preferable) and Excel skills
                </li>
                <li>
                  Excellent communication and leadership skills
                </li>
                <li>
                  Organizational and time-management skills
                </li>
                <li>
                  Strong knowledge of Defi, and BD pipeline management
                </li>
                <li>
                  Experience creating and pitching presentations on powerpoint or google slides
                </li>
                <li>
                  An understanding of the economics and game theory underlying incentivised crypto mechanisms and markets
                </li>
                <li>
                  The hunger to always be driving deals to completion
                </li>
              </StyledUl>
            </Desc>
            <H3>Benefits</H3>
            <Desc>
              <StyledUl>
                <li>
                  Competitive stock options package as an early member of the team
                </li>
                <li>
                  5 to 10 days off in addition to the legal 25 days
                </li>
                <li>
                  Events and conference trips
                </li>
              </StyledUl>
            </Desc>
            <Desc>
              This role is location agnostic anywhere in the world, but we ask that you overlap working hours with GMT+1 Time Zone.
            </Desc>
            <Desc>
              We are a fully distributed team and have the tools and benefits to support you in your remote work environment.
            </Desc>
            <StyledLink
              href="mailto:careers@peculium.io?subject=Sales Director"
            >
              Apply now
            </StyledLink>
          </Job>
          <Job title="Sales Manager">
            <br/>
            <Desc>
              We’re looking for a proactive problem-solver who thrives in a fast-paced, diverse environment. The Sales Manager will be expected to be playing a pivotal role in driving growth. The right candidate will have exceptional learning abilities, adapt to fast-paced environments, possess uncompromising attention to detail, and have great communication skills.
            </Desc>
            <H3>As part of the team, your responsibilities will be to:</H3>
            <Desc>
              Research and build blockchain protocols, modules, technologies and algorithms.
              <StyledUl>
                <li>
                  Liaise between the sales, marketing, and management teams to ensure delivery of business development projects and pipeline.
                </li>
                <li>
                  Help manage inbound leads and coordinate sales calls, individually and in conjunction with other members of the business development team.
                </li>
                <li>
                  Generate proposals and other documentation to support the sales, on-boarding, and customer service process.
                </li>
                <li>
                  Maintain a deep knowledge of Peculium’s service offering and client base.
                </li>
                <li>
                  Conduct competitive market research to refine sales and marketing strategy.
                </li>
                <li>
                  Contribute to the development of marketing campaigns and materials for our different lines of business.
                </li>
                <li>
                  Leverage established relationships; develop new relationships with “crypto-literate” venture capital firms, hedge funds, and other institutional investors.
                </li>
                <li>
                  Gravitas to build relationships with C-level executives at established information technology companies and financial institutions.
                </li>
                <li>
                  Generate partnerships with exchanges, custodians, wallets, and other crypto-native institutions.
                </li>
                <li>
                  Ensure transparency in the sales process for the entire organization.
                </li>
                <li>
                  Own and manage the company’s use of CRM to ensure the system is up to date and that all relevant company stakeholders are utilizing it efficiently.
                </li>
                <li>
                  Lead sales funnel for each network we support.
                </li>
                <li>
                  Be the first touchpoint and ongoing relationship manager.
                </li>
                <li>
                  Track leads and ensure deals are being followed through on by each different department.
                </li>
                <li>
                  Get contracts signed.
                </li>
                <li>
                  Drive toward company-wide business goals.
                </li>
                <li>
                  Provide demos of the SAIEVE platform and address customer questions effectively.
                </li>
                <li>
                  Demystify our product for potential customers.
                </li>
                <li>
                  Work with the product team to identify and prioritize customer feedback and inform the product roadmap.
                </li>
              </StyledUl>
            </Desc>
            <StyledLink
              href="mailto:careers@peculium.io?subject=Sales Manager"
            >
              Apply now
            </StyledLink>
          </Job>
        </Container>
      </CTR>
    </div>
  );
}

const Container = styled.div`
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const H3 = styled.h3`
  font-size: 26px;
  line-height: 40px;
  color: #2100ba;
  font-weight: normal;
  margin: 15px 0 5px 0
`;
const Desc = styled.p`
  font-size: 16px;
  line-height: 26px;
  text-align: justify;
`;
const StyledUl = styled.ul`
  display: block;
  list-style-type: disc;
  padding-left: 30px
`;
const StyledLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0220bf;
  border-radius: 4px;
  padding: 8px 15px;
  border: none;
  width: fit-content;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #f9fafb;
  margin-top: 30px;
  float: right;

  &:hover {
    opacity: 0.7;
  }
`;

export default Jobs;
