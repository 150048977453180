import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';

const FirstPart: React.FC = () => {
  let [pcl, setPcl] = useState({
    current_price: { usd: -1 },
    market_cap_change_percentage_24h: 0,
    market_cap_rank: 0,
    market_cap: {
      usd: 0,
    },
    total_volume: {
      usd: 0,
    },

    high_24h: {
      usd: 0,
    },
    low_24h: {
      usd: 0,
    },
  });
  useEffect(() => {
    fetch('https://api.coingecko.com/api/v3/coins/peculium-2')
      .then((d) => d.json())
      .then(({ market_data }) => setPcl({ ...market_data }));
  }, []);
  return (
    <StyledContainer>
      <WrapperBox>
        <Title>PCL in numbers</Title>
        <BBox>
          <PriceBox>
            <Caption>
              <MarkBox>
                <svg
                  width="32"
                  height="28"
                  viewBox="0 0 32 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_307_4640)">
                    <path
                      d="M28.5814 8.19977C27.6013 11.2589 24.6023 14.417 19.6645 14.7415C19.4778 13.554 19.2895 12.3568 19.1039 11.1772C20.2475 10.7406 21.3522 10.4252 22.3633 9.90947C24.0713 9.03848 24.7731 7.59595 24.483 5.89667C24.258 4.5788 23.5376 3.82964 22.2358 3.78524C20.392 3.72319 18.545 3.7693 16.5682 3.7693C16.5682 10.986 16.5754 18.3547 16.5754 25.4074H16.4529C12.7588 25.4097 8.8784 25.6043 5.19526 25.3954C1.51705 25.1865 -0.479436 22.0737 0.0986541 18C0.453939 15.4957 1.65884 13.5727 3.83161 12.494C5.46898 11.681 7.2454 11.171 9.14445 10.4571C9.40174 11.989 9.60101 13.1788 9.80082 14.3663C8.70158 14.7722 7.62806 15.0659 6.64323 15.5538C4.8471 16.4441 4.05934 17.9983 4.38451 19.7277C4.5805 20.7712 5.1334 21.5978 6.17024 21.6501C8.18699 21.7515 10.2114 21.6797 12.3551 21.6797C12.3551 14.4443 12.3551 7.28057 12.3551 0.00640865C15.0578 0.00640865 17.6379 -0.0123774 20.2174 0.0143784C21.5444 0.028041 22.8861 0.00868525 24.194 0.203376C26.6919 0.575111 28.2493 2.08084 28.719 4.71372C28.9544 6.03288 28.9081 7.18006 28.5814 8.19977Z"
                      fill="#0220BF"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_307_4640"
                      x="0"
                      y="0"
                      width="31.0246"
                      height="27.6493"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="1.07869" dy="1.07869" />
                      <feGaussianBlur stdDeviation="0.539345" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_307_4640"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_307_4640"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </MarkBox>
              <Box>
                <TokenName>Peculium</TokenName>
                <TokenVal>PCL/USD</TokenVal>
              </Box>
            </Caption>
            <TokenPrice>${pcl.current_price.usd}</TokenPrice>
            <ProBox>
              (
              {pcl.market_cap_change_percentage_24h == null
                ? 0
                : pcl.market_cap_change_percentage_24h.toFixed(1)}
              %)
            </ProBox>
          </PriceBox>
          <Box display={'flex'} columnGap={'4px'}>
            <TitleBox>
              <TitleItem>Ranking</TitleItem>
              <TitleItem>Market Cap</TitleItem>
              <TitleItem>Volume (24)</TitleItem>
              <TitleItem>24h Low / 24h High</TitleItem>
            </TitleBox>
            <ValueBox>
              <ValueItem>#{pcl.market_cap_rank}</ValueItem>
              <ValueItem>${pcl.market_cap.usd}</ValueItem>
              <ValueItem>${pcl.total_volume.usd}</ValueItem>
              <ValueItem>
                ${pcl.low_24h.usd} / ${pcl.high_24h.usd}
              </ValueItem>
            </ValueBox>
          </Box>
        </BBox>
        <BackBox>
          <img src="/images/metrics/bg.png" alt="metricsbg" />
        </BackBox>
        <CoinsBox>
          <img src="/images/metrics/Coins.png" alt="Coins" />
        </CoinsBox>
      </WrapperBox>
    </StyledContainer>
  );
};

const CoinsBox = styled(Box)`
  position: absolute;
  right: -190px;
  top: -110px;
  @media (max-width: 1215px) {
    position: relative;
    top: 50px;
    right: 0px;
  }
`;

const BackBox = styled(Box)`
  position: absolute;
  top: 4px;
  left: 26px;
`;

const ValueItem = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  padding-left: 25px;
  line-height: 150%;
  color: #f9fafb;
`;

const ValueBox = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-row-gap: 15px;
`;

const TitleItem = styled.span`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #f9fafb;
`;

const TitleBox = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-row-gap: 15px;
`;

const ProBox = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #7d91ae;
`;

const PriceBox = styled(Box)``;

const TokenPrice = styled(Box)`
  margin-top: 15px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  color: #f9fafb;
`;

const TokenName = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #f9fafb;
`;

const TokenVal = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #7d91ae;
`;

const Caption = styled(Box)`
  display: flex;
  grid-column-gap: 10px;
`;

const MarkBox = styled(Box)`
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 10px; */
  width: 48px;
  height: 48px;
`;

const Title = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 150%;
  color: #f9fafb;
  border-bottom: 1px solid rgb(64, 90, 219);
  padding-bottom: 38px;
  width: fit-content;
`;

const WrapperBox = styled(Box)`
  position: relative;
  background: linear-gradient(304.2deg, #0420b6 -1.51%, #304ce3 100%);
  box-shadow: 0px 0px 54.3813px rgba(0, 23, 143, 0.2);
  border-radius: 10px;
  padding: 64px;
  width: 80%;
  @media (max-width: 800px) {
    left: 10%;
    padding: 10px;
    padding-left: 10%;
  }
`;

const StyledContainer = styled(Box)`
  padding-left: 10%;
  @media (max-width: 1215px) {
    padding-left: 0px;
  }
`;

const BBox = styled(Box)`
  display: flex;
  column-gap: 120px;
  margin-top: 40px;

  @media (max-width: 1215px) {
    flex-direction: column;
  }
`;

export default FirstPart;
