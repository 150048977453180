import {Box, Container, Grid} from '@mui/material';
import React from 'react';
import styled from 'styled-components';

function Portfolio() {
  return (
    <div
      style={{
        backgroundImage: 'url(./images/home/texture_bg.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        padding: '100px 0'
      }}
    >
      <Container maxWidth={'lg'}>
        <Grid container spacing={2}>
          <StyledGrid item md={2}/>
          <Grid item md={8}>
            <ContainerS>
              <h1 style={{ color: '#022B69', fontSize: '48px' , lineHeight: '72px', textAlign: 'center' }}>
                Crypto Portfolios <br/> made for <span
                  style={{
                    color: 'white',
                    paddingBottom: '3px',
                    fontSize: '48px',
                    borderRadius: '4px',
                    background: '#0220BF',
                  }}
                >
                  you
                </span>
              </h1>
              <p style={{ color: '#7D91AE', fontSize: '20px', textAlign: 'center' }}>
                we give you access to the investment opportunities that the 1% <br/>
                have been taking advantage of for years.
              </p>
              {/*<button
              style={{
                background: '#0220BF',
                fontWeight: '700',
                fontSize: '22px',
                color: 'white',
                border: 'none',
                padding: '10px',
                cursor: 'pointer',
                borderRadius: '5px',
              }}
            >
              Explore our strategies
            </button>*/}
            </ContainerS>
          </Grid>
          <StyledGrid item md={2}/>
        </Grid>
      </Container>
    </div>
  );
}


const ContainerS = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  align-items: center;
  position: relative;
  padding-top: 70px;
  padding-bottom: 50px;
  gap: 30px;
`;

const StyledGrid = styled(Grid)`
  @media (max-width: 899px) {
    display: none
  }
  img {
   display: block;
   margin-bottom: 20px
  }
`;

export default Portfolio;
