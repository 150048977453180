import React from 'react';
import SubHeader from './SubHeader';
import Main from './Main';
import { Container } from '@mui/material';
function index() {
  return (
    <>
      <SubHeader />
      <Main />
    </>
  );
}

export default index;
