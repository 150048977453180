import React from 'react';
import styled from 'styled-components';
import { Box, Container } from '@mui/material';

const PCLPart: React.FC = () => {
  return (
    <Container fixed maxWidth={'xl'}>
      <WrapperBox>
        <img
          style={{ position: 'absolute', left: '-80%', opacity: '.5' }}
          src="./images/home/sblue.png"
        />
        <TextPart>
          <Title>PCL is for anyone who knows That Money needs change</Title>
          <Desc>Change starts by giving you ownership of PCL.</Desc>
          <ButtonGroup>
            <a href="https://app.saieve.io/" target="_blank">
              <FuncButton active={true}>BUY PCL</FuncButton>
            </a>
            <a href="https://peculium.defiterm.io/" target="_blank">
              <FuncButton active={false}>FARM NOW</FuncButton>
            </a>
            <FuncButton active={false}>STAKE
              <SoonBox>SOON</SoonBox>
            </FuncButton>
          </ButtonGroup>
        </TextPart>
        <ImgBox>
          <img
            style={{ height: '543px' }}
            src="./images/home/PCLIntro.png"
            alt="PLCIntro"
          />
        </ImgBox>
      </WrapperBox>
    </Container>
  );
};

const StyledContainer = styled(Box)`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: end;

  margin-top: 115px;

  position: relative;
  @media (max-width: 1215px) {
    margin-top: 140px;
    height: fit-content;
    padding-left: 20px;
  }
`;

const WrapperBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 90px;
  padding-left: 25px;
  padding-top: 60px;
  @media (max-width: 1215px) {
    flex-wrap: wrap;
    gap: 2px;
    margin-top: 135px;
    margin-bottom: 135px;
    text-align: center;
  }
`;

const TextPart = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-row-gap: 30px;
  @media (max-width: 1215px) {
    height: fit-content;
    width: 100%;
    margin-bottom: 50vh;
  }
`;

const Title = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  color: #022b69;
`;

const Desc = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #7d91ae;
`;

const ButtonGroup = styled(Box)`
  display: flex;
  flex-direction: row;
  @media (max-width: 1215px) {
    flex-direction: column;
    gap: 15px;
    align-items: center;
  }
`;

const FuncButton = styled(Box)<{ active: boolean }>`
  width: 155px;
  height: 48px;
  background: ${({ active }) =>
    active ? 'linear-gradient(304.2deg, #0420B6 -1.51%, #304CE3 100%)' : ''};
  border: 1px solid #7d91ae;
  border-radius: 4px;
  color: ${({ active }) => (active ? '#F9FAFB' : '#7D91AE')};
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-right: 15px;

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &:hover {
    opacity: 0.8;
  }
`;

const ImgBox = styled(Box)`
  @media (max-width: 800px) {
    margin-top: -380px;
  }
`;
const SoonBox = styled.span`
  padding: 2px 5px;
  background: #a47ee6;
  /* padding: 2px 4px;
  background: linear-gradient(225deg, #0E1DB8 1.56%, #9071EB 97.92%); */
  border-radius: 4px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  position: absolute;
  top: -10px;
  right: -24px
`;
export default PCLPart;
