import React, { useState } from 'react';
import styled from 'styled-components';
import { Container as CTR, Snackbar } from '@mui/material';
import Toast from '../../components/Toast';

const WhereToBuy = () => {
  const [Alert, setAlert] = useState(0);
  let [Info, setInfo] = useState('');
  const AddtoMetaMask = async () => {
    const tokenAddress = '0x1dbDf52915875f749CBAEAaf515252455b623F6e';
    const tokenSymbol = 'PCL';
    const tokenDecimals = 10;
    const tokenImage =
      'https://s2.coinmarketcap.com/static/img/coins/64x64/17146.png';
    try {
      if (typeof window.ethereum == 'undefined') {
        throw { message: 'Metamask not found !' };
      }
      if (window.ethereum.chainId != '0x38') {
        throw { message: 'Please Change Metmask network to BSC !' };
      }
      await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
          },
        },
      });

      setAlert(1);
      setInfo('Thank you for adding PCL !');
    } catch (error) {
      setInfo(error.message);
      setAlert(2);
    }
    setTimeout(() => {
      setAlert(0);
    }, 3000);
  };
  return (
    <div>
      {Alert == 0 ? null : Alert == 1 ? (
        <Toast message={Info} type="success" />
      ) : (
        <Toast message={Info} type={'error'} />
      )}
      <CTR fixed maxWidth={'lg'}>
        <Container>
          <Col style={{ gap: '22px' }}>
            <h1 style={{ color: '#022B69', fontSize: '48px' }}>
              Good currency should{' '}
            </h1>
            <Row>
              <h1 style={{ color: '#022B69', fontSize: '48px' }}>be </h1>
              <Deco>easy</Deco>{' '}
              <h1 style={{ color: '#022B69', fontSize: '48px' }}> to find</h1>
            </Row>
            <p style={{ color: '#7D91AE', fontSize: '20px' }}>
              You can buy on the exchanges or in our Weath app.
              <br />
              And if you're buying more than $5,000 USD, we can help.
              <br />
              Email our OTC desk.
            </p>
            <ResponsiveRow>
              <button
                style={{
                  border: 'none',
                  backgroundColor: '#0220BF',
                  width: 'fit-content',
                  fontSize: '20px',
                  padding: '12px 16px',
                  borderRadius: '5px',
                  color: 'white',
                  fontWeight: '300',
                }}
                onClick={() =>
                  (window.location.href = 'mailto:otc@peculium.io')
                }
              >
                CONTACT OTC
              </button>
              <a
                style={{
                  color: '#0220BF',
                  marginLeft: '30px',
                  alignSelf: 'center',
                  fontSize: '20px',
                }}
                href="https://peculium.defiterm.io/"
                target="_blank"
              >
                Buy in the app →
              </a>
            </ResponsiveRow>
          </Col>
          <IMGCoins src="./images/coins/PCLcoins.png" />
        </Container>
        <Exchange>
          <Col>
            <h2 style={{ color: '#022B69', fontSize: '26px' }}>Exchanges</h2>
            <p
              style={{
                backgroundColor: '#A47EE6',
                padding: '8px',
                color: 'white',
                borderRadius: '3px',
                width: 'fit-content',
              }}
            >
              CEX
            </p>
            <p style={{ color: ' #7D91AE', textAlign: 'center' }}>
              Centralized exchanges where PCL is listed
            </p>
            <Row>
              <StyledCol>
                <img src="./images/community/bm.svg" />
                <p style={{ color: ' #7D91AE', textAlign: 'center' }}>
                  Market Pairs
                </p>

                <Row>
                  <img src="./images/coins/PCL.png" style={{ width: '32px' }} />
                  <a
                    href="https://www.bitmart.com/trade/en?layout=basic&symbol=PCL_USDT"
                    target="_blank"
                  >
                    <b>PCL</b>
                  </a>
                  /
                  <img src="./images/community/usdt.svg" />
                  <a
                    href="https://www.bitmart.com/trade/en?layout=basic&symbol=PCL_USDT"
                    target="_blank"
                  >
                    <b>USDT</b>
                  </a>
                </Row>
              </StyledCol>

              {/*END*/}

              <StyledDivB />

              <Col>
                <img src="./images/community/hb.svg" />
                <p style={{ color: ' #7D91AE', textAlign: 'center' }}>
                  Market Pairs
                </p>
                <Row>
                  <img src="./images/coins/PCL.png" style={{ width: '32px' }} />
                  <a
                    href="https://www.hotbit.io/exchange?symbol=PCL_BTC"
                    target="_blank"
                  >
                    <b>PCL</b>
                  </a>
                  /
                  <img src="./images/coins/BTCicon.png" />
                  <a
                    href="https://www.hotbit.io/exchange?symbol=PCL_BTC"
                    target="_blank"
                  >
                    <b>BTC</b>
                  </a>
                </Row>

                <Row>
                  <img src="./images/coins/PCL.png" style={{ width: '32px' }} />
                  <a
                    href="https://www.hotbit.io/exchange?symbol=PCL_ETH"
                    target="_blank"
                  >
                    <b>PCL</b>
                  </a>
                  /
                  <img src="./images/community/eth.svg" />
                  <a
                    href="https://www.hotbit.io/exchange?symbol=PCL_ETH"
                    target="_blank"
                  >
                    <b>ETH</b>
                  </a>
                </Row>
              </Col>
            </Row>
          </Col>

          {/*fdf*/}
          <StyledDivider />

          <Col>
            <h2 style={{ color: '#022B69', visibility: 'hidden' }}>Exchange</h2>
            <p
              style={{
                backgroundColor: '#A47EE6',
                padding: '8px',
                color: 'white',
                borderRadius: '3px',
                width: 'fit-content',
              }}
            >
              DEX
            </p>
            <p style={{ color: ' #7D91AE', textAlign: 'center' }}>
              Decentralized exchanges where PCL is listed
            </p>
            <Row>
              <Col style={{ position: 'relative', top: '20px' }}>
                <img src="./images/community/ps.svg" />

                <Col>
                  <p style={{ color: ' #7D91AE', textAlign: 'center' }}>
                    Market Pairs
                  </p>

                  <Row>
                    <img
                      src="./images/coins/PCL.png"
                      style={{ width: '32px' }}
                    />
                    <a
                      href="https://pancakeswap.finance/swap?outputCurrency=0x1dbdf52915875f749cbaeaaf515252455b623f6e&chain=mainnet"
                      target="_blank"
                    >
                      <b>PCL</b>
                    </a>
                    /
                    <img
                      src="./images/coins/busd.png"
                      style={{ width: '32px' }}
                    />
                    <a
                      href="https://pancakeswap.finance/swap?outputCurrency=0x1dbdf52915875f749cbaeaaf515252455b623f6e&chain=mainnet"
                      target="_blank"
                    >
                      <b>BUSD</b>
                    </a>
                  </Row>
                </Col>
                <p style={{ color: '#7D91AE' }}>
                  <a
                    href="https://peculium.defiterm.io/lm/active"
                    target="_blank"
                  >
                    Farming available
                  </a>
                </p>
              </Col>

              {/*END*/}
            </Row>
            <StyledButton
              style={{
                border: 'none',
                backgroundColor: '#0220BF',
                width: 'fit-content',
                fontSize: '18px',
                padding: '8px 16px',
                position: 'relative',
                top: '100px',
                right: '-40px',
                borderRadius: '5px',
                color: 'white',
                marginRight: '20px',
                alignSelf: 'flex-end',
                justifySelf: 'flex-end',
                fontWeight: '300',
                cursor: 'pointer',
              }}
              onClick={AddtoMetaMask}
            >
              Add PCL to 🦊
            </StyledButton>
          </Col>
        </Exchange>
      </CTR>
    </div>
  );
};

const Deco = styled.span`
  background: #0220bf;
  border-radius: 4px;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0px;
  font-size: 48px;

  font-weight: bold;
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 25px;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  gap: 10%;
  height: 100vh;
  @media (max-width: 1215px) {
    flex-direction: column;
    padding-top: 75px;
    height: auto;
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;

  gap: 20px;
  @media (max-width: 800px) {
    gap: 50px;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 10px;
  @media (max-width: 1215px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const StyledDivider = styled.div`
  width: 2px;
  align-self: center;
  height: 500px;
  background: #022b69;
  opacity: 0.1;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const ResponsiveRow = styled(Row)`
  @media (max-width: 800px) {
    margin-top: 60px;
    justify-content: center;
  }
`;

const StyledCol = styled(Col)`
  position: relative;
  top: -53px;
  @media (max-width: 800px) {
    position: relative;
    top: 0px;
  }
`;

const IMGCoins = styled.img`
  width: 40%;

  @media (max-width: 800px) {
    width: 90%;
    margin-top: -80px;
  }
`;

const StyledDivB = styled.div`
  width: 2px;
  align-self: center;
  height: 90%;
  background: #022b69;
  opacity: 0.1;
  @media (max-width: 800px) {
    display: none;
  }
`;
const StyledButton = styled.button`
  @media (max-width: 1200px) {
    top: 15px !important;
    right: 5px !important
  }
`;

const Exchange = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 25px;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  top: 80px;
  width: 100%;
  border-radius: 4px;
  z-index: 1;
  gap: 6%;
  background-color: white;
  color: #022b69;
  margin-bottom: 156px;
  @media (max-width: 800px) {
    margin-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
  }
`;
export default WhereToBuy;
