import React from 'react';
import Main from './Main';
import SubHeader from './SubHeader';
function index() {
  return (
    <>
      <SubHeader />
      <Main />
    </>
  );
}

export default index;
