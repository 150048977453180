import React from 'react';
import styled from 'styled-components';
import { Container } from '@mui/material';

export default function () {
  return (
    <FlexContainer>
      <IMG
        style={{
          position: 'absolute',
          left: '0px',

          top: '50%',
        }}
        src="./images/pcltoken/LCoin.svg"
      />

      <IMG
        style={{
          alignSelf: 'flex-start',
          position: 'absolute',
          top: '-100px',
          right: '0px',
        }}
        src="./images/pcltoken/RCoin.svg"
      />
      <Container>
        <img
          style={{ position: 'relative', left: '10%' }}
          src="./images/pcltoken/Levels.svg"
        />
      </Container>
    </FlexContainer>
  );
}

const FlexContainer = styled.div`
  background: linear-gradient(90deg, #0220bf 0%, #5e76f7 100%);
  display: flex;
  direction: row;
  justify-content: center;
  position: relative;
`;

const IMG = styled.img`
  height: 10vw;
`;
