import React, { useState } from 'react';
import styled from 'styled-components';
import { Backdrop, Box, Modal, Container, Grid } from '@mui/material';

const IntroPart: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <StyledContainer>
      <Modal open={showModal} BackdropComponent={BackdropEx}>
        <Container fixed maxWidth={'lg'}>
          <ModalContainer>
            <Box position={'relative'}>
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <img src="./images/caption.svg" alt="caption" />
                <CloseButton
                  marginRight={'40px'}
                  onClick={() => setShowModal(false)}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 5L5 15"
                      stroke="#303030"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5 5L15 15"
                      stroke="#303030"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </CloseButton>
              </Box>
              <ModalBody>
                <FirstCom>
                  SAIΞVE <Imp>Earn</Imp> offer is a diversified suite of
                  financial products that you can use{' '}
                  <BoldPart>to increase your crypto holdings.</BoldPart> It lets
                  investors put their passive crypto assets to work and earn a{' '}
                  <BoldPart>return</BoldPart> at competitive yields.
                </FirstCom>
                <SecondCom>
                  The difference between a regular crypto savings accounts and
                  this one is that SAIEVE is capable of so much more! <br />
                  SAIΞVE Earn is an awesome way to increase your crypto holdings
                  while you’re sound asleep, knowing that your funds are doing
                  all the work for you.
                </SecondCom>
                <ThirdCom>
                  With SAIΞVE <TImp> Earn</TImp>, you can start saving,
                  investing to earn passive income on stablecoins and top 10
                  coins.
                </ThirdCom>
              </ModalBody>
              <DecoImg>
                <img src="./images/deco.svg" alt="deco" />
              </DecoImg>
            </Box>
          </ModalContainer>
        </Container>
      </Modal>
      <Container fixed maxWidth={'lg'}>
        <Grid container spacing={2}>
          <Grid item md={5}>
            <LeftBox zIndex={1}>
              <TitleBox>
                SAIΞVE <span>Earn</span>
              </TitleBox>
              <DesBox>Don't just HOLD your crypto. Earn it.</DesBox>
              <QuestionGroup>
                <QuestionItem>
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="32" height="32" rx="16" fill="#A47EE6" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M25.264 9.60222C25.9857 10.3004 26.0048 11.4514 25.3066 12.1732L15.2761 22.5419C14.2757 23.5761 12.6176 23.5761 11.6171 22.5419L6.69304 17.4518C5.99486 16.73 6.01395 15.579 6.73567 14.8808C7.45739 14.1826 8.60844 14.2017 9.30661 14.9234L13.4466 19.2031L22.693 9.64485C23.3912 8.92313 24.5423 8.90404 25.264 9.60222Z"
                      fill="white"
                    />
                  </svg>
                  Is the APY on your crypto savings account not exciting enough?
                </QuestionItem>
                <QuestionItem>
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="32" height="32" rx="16" fill="#A47EE6" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M25.264 9.60222C25.9857 10.3004 26.0048 11.4514 25.3066 12.1732L15.2761 22.5419C14.2757 23.5761 12.6176 23.5761 11.6171 22.5419L6.69304 17.4518C5.99486 16.73 6.01395 15.579 6.73567 14.8808C7.45739 14.1826 8.60844 14.2017 9.30661 14.9234L13.4466 19.2031L22.693 9.64485C23.3912 8.92313 24.5423 8.90404 25.264 9.60222Z"
                      fill="white"
                    />
                  </svg>
                  Are your crypto holdings sitting on an exchange untraded?
                </QuestionItem>
                <QuestionItem>
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="32" height="32" rx="16" fill="#A47EE6" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M25.264 9.60222C25.9857 10.3004 26.0048 11.4514 25.3066 12.1732L15.2761 22.5419C14.2757 23.5761 12.6176 23.5761 11.6171 22.5419L6.69304 17.4518C5.99486 16.73 6.01395 15.579 6.73567 14.8808C7.45739 14.1826 8.60844 14.2017 9.30661 14.9234L13.4466 19.2031L22.693 9.64485C23.3912 8.92313 24.5423 8.90404 25.264 9.60222Z"
                      fill="white"
                    />
                  </svg>
                  Are you not interested in trading but still looking to increase your
                  crypto holdings?
                </QuestionItem>
              </QuestionGroup>
              <a href="https://app.saieve.io/" target="_blank">
                <StartEarnButton>START EARNING</StartEarnButton>
              </a>
              <EarnQue onClick={() => {
                setShowModal(true);
                console.log('clicked...')
              }}>
                What is SAIΞVE Earn?
                <ArrowButton>
                  {'>'}
                  {/* <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 1.5L7.5 7.5L1.5 13.5" stroke="#F9FAFB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg> */}
                </ArrowButton>
              </EarnQue>
            </LeftBox>
          </Grid>
          <Grid item md={7}>
            <img src="./images/humans.png" alt="humans" />
          </Grid>
        </Grid>
      </Container>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  background-image: url(./images/keyBlack.png), linear-gradient(304.2deg, #0420b6 -1.51%, #304ce3 100%);
  background-position: left bottom;
  background-repeat: no-repeat; 
  height: 100vh;

  padding-left: 60px;
  padding-top: 120px;
  display: flex;
  align-items: center;
  gap: 51px;
  position: relative;

  @media (max-width: 1215px) {
    /* padding-top: 200px;
    padding-left: 0; */
    flex-direction: column-reverse;
    height: fit-content;
    padding-left: 15px;
    flex-wrap: wrap;
  }
  /*  */
`;

const LeftBox = styled(Box)`
  max-width: 487px;
`;

const TitleBox = styled(Box)`
  /* padding-top: 186px; */
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  color: #f9fafb;

  span {
    background: #a47ee6;
    border-radius: 4px;
    padding: 10px 15px;
  }
`;

const DesBox = styled(Box)`
  margin-top: 5px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: #f9fafb;
`;

const QuestionGroup = styled(Box)`
  margin-top: 15px;
  display: flex;
  justify-content: center;
  grid-row-gap: 15px;
  flex-direction: column;
  /* max-width: 487px; */
`;

const QuestionItem = styled(Box)`
  display: flex;
  grid-column-gap: 20px;
  align-items: flex-start;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #f9fafb;

  svg {
    min-width: 32px;
  }
`;

const StartEarnButton = styled(Box)`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #a47ee6;
  border-radius: 6px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #f9fafb;
  width: 187px;
  height: 51px;

  &:hover {
    opacity: 0.7;
  }

  cursor: pointer;
`;

const EarnQue = styled(Box)`
  margin-top: 30px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #f9fafb;

  display: flex;
  align-items: center;
  grid-column-gap: 10px;
`;

const TransBoard = styled(Box)`
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0.1;
`;

const ModalContainer = styled(Box)`
  position: fixed;
  left: 50vw;
  top: 50vh;
  transform: translate(-50%, -50%);
  width: 90vw;
  height: 90vh;
  background: #f9fafb;
  border-radius: 10px;
  outline: none;
  overflow: scroll;

  @media (max-width: 800px) {
    overflow: scroll;
  }
`;

const BackdropEx = styled(Backdrop)``;

const CloseButton = styled(Box)`
  cursor: pointer;
  transition: all 0.2s;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 0.7;
    background: grey;
    border-radius: 100%;
  }
`;

const ModalBody = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-row-gap: 30px;
  align-items: center;
  padding: 10px 72px 80px 72px;
`;

const FirstCom = styled.span`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #303030;
  padding: 0px 30px;
`;

const Imp = styled.span`
  background: linear-gradient(304.2deg, #0420b6 -1.51%, #304ce3 100%);
  border-radius: 4px;
  width: fit-content;
  padding-left: 15px;
  padding-right: 15px;
  height: 35px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #f9fafb;
`;

const BoldPart = styled.span`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #022b69;
`;

const SecondCom = styled(Box)`
  background: linear-gradient(304.2deg, #0420b6 -1.51%, #304ce3 100%);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 29px 33px 29px 23px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #f9fafb;
  @media (max-width: 800px) {
    padding: 10px;
  }
`;

const ThirdCom = styled.span`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: #022b69;

  padding: 0px 30px;
`;

const TImp = styled.span`
  background: linear-gradient(304.2deg, #0420b6 -1.51%, #304ce3 100%);
  border-radius: 4px;
  width: fit-content;
  height: 39px;
  display: inline-flex;
  color: #f9fafb;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
`;

const DecoImg = styled(Box)`
  position: absolute;
  left: -28px;
  top: 449px;
`;

const ArrowButton = styled(Box)`
  cursor: pointer;
  &:hover {
    transform: scale(1.5);
  }
`;

export default IntroPart;
