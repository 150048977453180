import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Layout from './layouts';
import Homepage from './pages/Homepage';
import Investing from './pages/Investing';
import Metrics from './pages/Metrics';
import About from './pages/Aboutus';
import Products from './pages/Products';
import PCLToken from './pages/PCLToken';
import Philosophy from './pages/philosophy';
//import Community from './pages/Community';
import Hodl from './pages/Holders';
import Technology from './pages/Technology';
import Angels from './pages/Angels';
import Team from './pages/Team';
import Careers from './pages/Careers';
import WhereToBuy from './pages/WhereToBuy/index';
import Referral from './pages/Referral';
import Roadmap from './pages/Roadmap';
import ScrollToTop from './ScrollToTop';


function App() {
  return (
    <Router>
      <Switch>
        <Layout>
          <ScrollToTop />
          <Route exact path="/products">
            <Products />
          </Route>
          <Route exact path="/invest">
            <Investing />
          </Route>
          <Route exact path="/pcl">
            <PCLToken />
          </Route>
          <Route exact path="/metrics">
            <Metrics />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>

          <Route exact path="/philosophy">
            <Philosophy />
          </Route>

          <Route exact path="/technology">
            <Technology />
          </Route>

          <Route exact path="/wheretobuy">
            <WhereToBuy />
          </Route>
          {/*<Route exact path="/community">
            <Community />
          </Route>*/}
          <Route exact path="/angels">
            <Angels />
          </Route>
          <Route exact path="/hodlers">
            <Hodl />
          </Route>
          <Route exact path="/team">
            <Team />
          </Route>
          <Route exact path="/careers">
            <Careers />
          </Route>
          <Route exact path="/referral">
            <Referral />
          </Route>
          <Route exact path="/roadmap">
            <Roadmap />
          </Route>
          {/* fallback route */}
          <Route exact path="/">
            <Homepage />
          </Route>
        </Layout>
      </Switch>
    </Router>
  );
}

export default App;
