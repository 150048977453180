import React from 'react';
import styled from 'styled-components';
import { Box, Container } from '@mui/material';

const ThirdPart: React.FC = () => {
  return (
    <StyledContainer>
      <Container fixed maxWidth={'xl'}>
        <StyledBox
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <ImgBox>
            <img src="./images/home/charts.png" height="590px" alt="charts" />

            <Notif src="./images/home/notif1.svg" />
            <img
              style={{
                position: 'absolute',
                right: '60%',
                bottom: '0px',
              }}
              src="./images/home/reds.png"
            />
          </ImgBox>
          <TextBox>
            <Title>Crypto Investing Solved</Title>
            <CheckGroup>
              <CheckItem>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="32" height="32" rx="16" fill="#0220BF" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M25.264 9.60222C25.9857 10.3004 26.0048 11.4514 25.3066 12.1732L15.2761 22.5419C14.2757 23.5761 12.6176 23.5761 11.6171 22.5419L6.69304 17.4518C5.99486 16.73 6.01395 15.579 6.73567 14.8808C7.45739 14.1826 8.60844 14.2017 9.30661 14.9234L13.4466 19.2031L22.693 9.64485C23.3912 8.92313 24.5423 8.90404 25.264 9.60222Z"
                    fill="white"
                  />
                </svg>
                <ItemText>Professionally managed portfolios</ItemText>
              </CheckItem>
              <CheckItem>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="32" height="32" rx="16" fill="#0220BF" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M25.264 9.60222C25.9857 10.3004 26.0048 11.4514 25.3066 12.1732L15.2761 22.5419C14.2757 23.5761 12.6176 23.5761 11.6171 22.5419L6.69304 17.4518C5.99486 16.73 6.01395 15.579 6.73567 14.8808C7.45739 14.1826 8.60844 14.2017 9.30661 14.9234L13.4466 19.2031L22.693 9.64485C23.3912 8.92313 24.5423 8.90404 25.264 9.60222Z"
                    fill="white"
                  />
                </svg>
                <ItemText>Institutional-grade investment strategies</ItemText>
              </CheckItem>
              <CheckItem>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="32" height="32" rx="16" fill="#0220BF" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M25.264 9.60222C25.9857 10.3004 26.0048 11.4514 25.3066 12.1732L15.2761 22.5419C14.2757 23.5761 12.6176 23.5761 11.6171 22.5419L6.69304 17.4518C5.99486 16.73 6.01395 15.579 6.73567 14.8808C7.45739 14.1826 8.60844 14.2017 9.30661 14.9234L13.4466 19.2031L22.693 9.64485C23.3912 8.92313 24.5423 8.90404 25.264 9.60222Z"
                    fill="white"
                  />
                </svg>
                <ItemText>Diversified exposure to crypto</ItemText>
              </CheckItem>
              <CheckItem>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="32" height="32" rx="16" fill="#0220BF" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M25.264 9.60222C25.9857 10.3004 26.0048 11.4514 25.3066 12.1732L15.2761 22.5419C14.2757 23.5761 12.6176 23.5761 11.6171 22.5419L6.69304 17.4518C5.99486 16.73 6.01395 15.579 6.73567 14.8808C7.45739 14.1826 8.60844 14.2017 9.30661 14.9234L13.4466 19.2031L22.693 9.64485C23.3912 8.92313 24.5423 8.90404 25.264 9.60222Z"
                    fill="white"
                  />
                </svg>
                <ItemText>Access our smart tools and insights</ItemText>
              </CheckItem>
            </CheckGroup>
          </TextBox>
        </StyledBox>
      </Container>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  padding: 150px 120px 0px 120px;
  margin-bottom: 35px;
  height: 100vh;

  @media (max-width: 1215px) {
    padding: 0px;
    height: fit-content;
  }
`;

const ImgBox = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0px;
  @media (max-width: 800px) {
    margin-top: -60px;
    margin-bottom: 0px;
  }
`;

const TextBox = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #7d91ae;
  @media (max-width: 800px) {
    margin-top: 135px;
    margin-bottom: 135px;
  }
`;

const Title = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  color: #022b69;
  @media (max-width: 800px) {
    text-align: center;
  }
`;

const Notif = styled.img`
  position: absolute;
  right: 50%;
  bottom: 0px;
  @media (max-width: 1215px) {
    right: 0px;
  }
`;

const CheckGroup = styled(Box)`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 25px;
`;

const CheckItem = styled(Box)`
  display: flex;
  grid-column-gap: 10px;
  align-items: center;
  width: 100%;
`;

const StyledBox = styled(Box)`
  @media (max-width: 1215px) {
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

const ItemText = styled.span`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #7d91ae;
`;

export default ThirdPart;
