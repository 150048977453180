import React from 'react';
import styled from 'styled-components';
import { Box, Container } from '@mui/material';

const MissionPart: React.FC = () => {
  return (
    <StyledContainer>
      <BBox>
        <Title>Our mission</Title>
        <br />
        <Title2>
          <span>Give customers the best chance of investment</span>
          <span>success and reduce inequality across the world.</span>
        </Title2>
        <Desc>
          We believe cryptocurrency will become an asset class of its own in the
          future. We're an fintech company making wealth creation accessible to
          everyone since 2017.
        </Desc>
      </BBox>

      <CEOQuoteBox>
        <QuoteBox>
          <QuoteSpan>
            "We have created an inclusive investment platform by combining the
            skills of our blockchain engineers, machine learning experts, former
            investment bankers, entrepreneurs, and designers."
          </QuoteSpan>
          <Box
            display={'flex'}
            paddingLeft={'20px'}
            paddingBottom={'20px'}
            justifyContent={'space-between'}
            alignItems={'flex-end'}
          >
            <svg
              width="61"
              height="34"
              viewBox="0 0 61 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="2.95162" cy="3.50045" r="2.95162" fill="#F9FAFB" />
              <circle cx="16.7251" cy="3.50045" r="2.95162" fill="#F9FAFB" />
              <circle cx="30.5005" cy="3.50045" r="2.95162" fill="#F9FAFB" />
              <circle cx="44.2739" cy="3.50045" r="2.95162" fill="#F9FAFB" />
              <circle cx="58.0493" cy="3.50045" r="2.95162" fill="#F9FAFB" />
              <circle cx="2.95162" cy="17.2739" r="2.95162" fill="#F9FAFB" />
              <circle cx="16.7251" cy="17.2739" r="2.95162" fill="#F9FAFB" />
              <circle cx="30.5005" cy="17.2739" r="2.95162" fill="#F9FAFB" />
              <circle cx="44.2739" cy="17.2739" r="2.95162" fill="#F9FAFB" />
              <circle cx="58.0493" cy="17.2739" r="2.95162" fill="#F9FAFB" />
              <circle cx="2.95162" cy="31.0483" r="2.95162" fill="#F9FAFB" />
              <circle cx="16.7251" cy="31.0483" r="2.95162" fill="#F9FAFB" />
              <circle cx="30.5005" cy="31.0483" r="2.95162" fill="#F9FAFB" />
              <circle cx="44.2739" cy="31.0483" r="2.95162" fill="#F9FAFB" />
              <circle cx="58.0493" cy="31.0483" r="2.95162" fill="#F9FAFB" />
            </svg>
            <QuoteWriter>Rachid OUKHAI CEO</QuoteWriter>
          </Box>
        </QuoteBox>
        <CeoImgBox>
          <CEOimg src="./images/home/CEO1.jpeg" alt="ceo" />
        </CeoImgBox>
      </CEOQuoteBox>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 100vh;
  @media (max-width: 1215px) {
    padding: 0px;
    height: fit-content;
    margin-top: 135px;
  }
  @media (max-width: 800px) {
    gap: 135px;
  }
`;

const Title = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 22px;
  text-align: center;
  color: #0220bf;
`;

const Title2 = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 150%;
  text-align: center;
  color: #022b69;
`;

const Desc = styled(Box)`
  padding-right: 0px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  margin-top: 40px;
  color: #7d91ae;
`;

const CEOimg = styled.img`
  height: 268px;
  width: 268px;
  left: 666px;
  top: -9px;
  border-radius: 200px;
  object-fit: contain;
  border: 15px solid #001cb3;
  @media (max-width: 1215px) {
    max-width: 300px;
  }
`;

const CEOQuoteBox = styled(Box)`
  display: flex;
  flex-direction: row;
  background: linear-gradient(304.2deg, #001cb3 -1.51%, #324ee6 100%);
  box-shadow: 0px 0px 54.3813px rgba(0, 23, 143, 0.2);
  border-radius: 10px;
  padding-right: 70px;
  padding-top: 40px;

  justify-self: flex-end;

  padding-bottom: 40px;
  justify-content: space-between;
  height: 250px;
  margin-top: 80px;
  width: 800px;
  align-items: center;
  position: relative;
  z-index: 0;
  @media (max-width: 1215px) {
    flex-direction: column-reverse;
    align-items: center;
    height: fit-content;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 100vw;
    margin-top: 0px;
  }
`;

const QuoteBox = styled(Box)`
  flex: 1;
`;

const BBox = styled(Box)`
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1215px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    margin-left: 0px;
  }
`;

const QuoteSpan = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #f9fafb;
  padding-left: 42px;
  padding-top: 30px;
  @media (max-width: 1215px) {
    justify-self: flex-start;
  }
`;

const QuoteWriter = styled(Box)`
  margin-top: 37px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  text-align: right;
  color: #f9fafb;

  display: flex;
  justify-content: flex-end;

  @media (max-width: 800px) {
    text-align: center;
  }
`;

const CeoImgBox = styled(Box)`
  width: 20%;
  position: relative;
  right: -30px;
  z-index: 2;
  @media (max-width: 800px) {
    left: -25%;
  }
`;

export default MissionPart;
